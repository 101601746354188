// CUSTOM
import { SECRET_NETWORK } from "./helpers";
import { SN_BUTT_SWBTC_FARM_ADDRESS } from "./constants";

$(async function () {
  if ($("#secret-network-pools").length) {
    SECRET_NETWORK.poolsPage = {
      nonIncentivizedCheckedAtLeastOnce: false,
      init: async () => {
        SECRET_NETWORK.poolsPage.addListeners();
        await SECRET_NETWORK.getAndSetCryptocurrencies();
        await SECRET_NETWORK.poolsPage.getAndSetPools();
        document.activateKeplr();
        SECRET_NETWORK.poolsPage.pools.forEach((pool) => {
          if (
            pool.smart_contract.address ==
            "secret1725s6smzds6h89djq9yqrtlqfepnxruc3m4fku"
          ) {
            SECRET_NETWORK.poolsPage.updatePoolInterface(pool, false, true);
          }
        });
      },
      addListeners: function () {
        $(document).on("keplr_connected", async () => {
          $(".balance-container").removeClass("d-none");
          $(".claim-button").removeClass("d-none");
          $(".deposit-withdraw-forms-container").removeClass("d-none");
          while (!SECRET_NETWORK.poolsPage.pools) {
            await document.delay(1_000);
          }
          SECRET_NETWORK.poolsPage.updateUserInterface(
            false,
            true,
            ".non-incentivized"
          );
        });

        $('.balance-container a[href="#"]')
          .not(".user-balance-container")
          .on("click", function (event) {
            event.preventDefault();
            let dataInputSelector = $(event.target)
              .closest(".balance-container")
              .attr("data-input-selector-to-click-fill-to");
            let balance = $(event.target).text();
            $(dataInputSelector).val(balance);
          });

        $(".bi-arrow-repeat").on("click", function (event) {
          event.preventDefault();
          let poolAddress = $(event.currentTarget).closest(".card")[0][
            "dataset"
          ]["poolAddress"];
          SECRET_NETWORK.poolsPage.pools.forEach((pool) => {
            if (pool.smart_contract.address == poolAddress) {
              SECRET_NETWORK.poolsPage.updatePoolInterface(pool, false);
            }
          });
        });

        $("#farm-checkbox").on("change", function () {
          if (this.checked) {
            $(".farm").removeClass("d-none");
          } else {
            $(".farm").addClass("d-none");
          }
        });
        $("#profit-distributor-checkbox").on("change", function () {
          if (this.checked) {
            if (!SECRET_NETWORK.poolsPage.nonIncentivizedCheckedAtLeastOnce) {
              SECRET_NETWORK.poolsPage.updateUserInterface(
                true,
                false,
                ".non-incentivized"
              );
              if (SECRET_NETWORK.walletAddress) {
                SECRET_NETWORK.poolsPage.updateUserInterface(
                  false,
                  true,
                  ".non-incentivized"
                );
              }
            }
            SECRET_NETWORK.poolsPage.nonIncentivizedCheckedAtLeastOnce = true;
            $(".profit-distributor").removeClass("d-none");
          } else {
            $(".profit-distributor").addClass("d-none");
          }
        });
        $("#yield-optimizer-checkbox").on("change", function () {
          if (this.checked) {
            if (!SECRET_NETWORK.poolsPage.nonIncentivizedCheckedAtLeastOnce) {
              SECRET_NETWORK.poolsPage.updateUserInterface(
                true,
                false,
                ".non-incentivized"
              );
              if (SECRET_NETWORK.walletAddress) {
                SECRET_NETWORK.poolsPage.updateUserInterface(
                  false,
                  true,
                  ".non-incentivized"
                );
              }
            }
            SECRET_NETWORK.poolsPage.nonIncentivizedCheckedAtLeastOnce = true;
            $(".yield-optimizer").removeClass("d-none");
          } else {
            $(".yield-optimizer").addClass("d-none");
          }
        });

        $("#incentivized-checkbox").on("change", function () {
          if (this.checked) {
            $(".incentivized").removeClass("d-none");
          } else {
            $(".incentivized").addClass("d-none");
          }
        });
        $("#non-incentivized-checkbox").on("change", function () {
          if (this.checked) {
            $(".non-incentivized").removeClass("d-none");
          } else {
            $(".non-incentivized").addClass("d-none");
          }
        });

        $(".claim-button").on("click", async (e) => {
          e.preventDefault();
          let poolAddress = $(e.currentTarget)
            .closest(".card")
            .attr("data-pool-address");
          if (poolAddress != "secret1725s6smzds6h89djq9yqrtlqfepnxruc3m4fku") {
            let claimButtonSelector = `.card[data-pool-address=${poolAddress}] .claim-button`;
            document.disableButton(claimButtonSelector);
            try {
              await document.connectKeplrWallet(false);
              if (SECRET_NETWORK.walletAddress) {
                let $button = $(claimButtonSelector);
                let pool;
                SECRET_NETWORK.poolsPage.pools.forEach((p) => {
                  if (p.smart_contract.address == poolAddress) {
                    pool = p;
                  }
                });

                let handleMsg = {
                  send: {
                    amount: "0",
                    recipient: poolAddress,
                    msg: pool.deposit_message,
                  },
                };
                let params = {
                  sender: SECRET_NETWORK.walletAddress,
                  contract_address: pool.deposit_token.smart_contract.address,
                  code_hash: pool.deposit_token.smart_contract.data_hash,
                  msg: handleMsg,
                };
                await SECRET_NETWORK.executeContract(params, 350_000);
                document.showAlertSuccess("Claim successful", true);
                $button.find(".ready").text("0");
              }
            } catch (err) {
              document.showAlertDanger(err);
            } finally {
              document.enableButton(claimButtonSelector);
            }
          }
        });
      },
      getAndSetPools: async () => {
        try {
          let result = await $.ajax({
            data: "enabled=true&categories=farm,profit_distributor,yield_optimizer&protocol=btn_group&include_parent_pool=true",
            type: "GET",
            url: "/pools",
          });
          SECRET_NETWORK.poolsPage.pools = result;
          SECRET_NETWORK.poolsPage.pools.forEach((pool) => {
            pool.cryptocurrency_pools.forEach((cp) => {
              if (cp.cryptocurrency_role == "deposit") {
                pool.deposit_token =
                  SECRET_NETWORK.cryptocurrencies[cp.cryptocurrency_id];
              } else if (cp.cryptocurrency_role == "reward") {
                pool.reward_token =
                  SECRET_NETWORK.cryptocurrencies[cp.cryptocurrency_id];
              }
            });
            let depositFormName = pool.smart_contract.address + "DepositForm";
            if ($(`[name=${depositFormName}]`).length) {
              let depositButtonSelector = `[name=${depositFormName}] button[type=submit]`;
              document[depositFormName].onsubmit = async (e) => {
                e.preventDefault();
                document.disableButton(depositButtonSelector);
                try {
                  await document.connectKeplrWallet(false);
                  if (SECRET_NETWORK.walletAddress) {
                    let amount = document[depositFormName].amount.value;
                    amount = document.formatHumanizedNumberForSmartContract(
                      amount,
                      pool.deposit_token.decimals
                    );
                    let handleMsg = {
                      send: {
                        amount: amount,
                        recipient: pool.smart_contract.address,
                        msg: pool.deposit_message,
                      },
                    };
                    let params = {
                      sender: SECRET_NETWORK.walletAddress,
                      contract_address:
                        pool.deposit_token.smart_contract.address,
                      code_hash: pool.deposit_token.smart_contract.data_hash,
                      msg: handleMsg,
                    };
                    await SECRET_NETWORK.executeContract(params, 2_000_000);
                    document.showAlertSuccess("Deposit successful", true);
                    document[depositFormName].amount.value = "";
                    SECRET_NETWORK.poolsPage.updatePoolInterface(pool, true);
                  }
                } catch (err) {
                  document.showAlertDanger(err);
                } finally {
                  document.enableButton(depositButtonSelector);
                  if (
                    pool.category == "yield_optimizer" &&
                    pool.reward_token == SECRET_NETWORK.cryptocurrencies[372]
                  ) {
                    SECRET_NETWORK.poolsPage.pools.forEach((poolTwo) => {
                      if (
                        poolTwo.category == "profit_distributor" &&
                        poolTwo.reward_token == pool.reward_token
                      ) {
                        SECRET_NETWORK.poolsPage.updateRewards(poolTwo);
                      }
                    });
                  }
                }
              };
            }

            let withdrawFormName = pool.smart_contract.address + "WithdrawForm";
            let withdrawButtonSelector = $(
              `[name=${withdrawFormName}] button[type=submit]`
            );
            if ($(`[name=${withdrawFormName}]`).length) {
              document[withdrawFormName].onsubmit = async (e) => {
                e.preventDefault();
                document.disableButton(withdrawButtonSelector);
                try {
                  await document.connectKeplrWallet(false);
                  if (SECRET_NETWORK.walletAddress) {
                    let amount = document[withdrawFormName].amount.value;
                    amount = document.formatHumanizedNumberForSmartContract(
                      amount,
                      pool.deposit_token.decimals
                    );
                    let handleMsg;
                    if (
                      pool.smart_contract.address == SN_BUTT_SWBTC_FARM_ADDRESS
                    ) {
                      handleMsg = { withdraw: { shares_amount: amount } };
                    } else if (
                      pool.smart_contract.address ==
                        "secret1ccgl5ys39zprnw2jq8g3eq00jd83temmqversz" ||
                      pool.smart_contract.address ==
                        "secret1wuxwnfrkdnysww5nq4v807rj3ksrdv3j5eenv2" ||
                      pool.smart_contract.address ==
                        "secret1sxmznzev9vcnw8yenjddgtfucpu7ymw6emkzan"
                    ) {
                      handleMsg = { withdraw: { amount: amount } };
                    } else if (
                      pool.smart_contract.address ==
                      "secret18pqz6x67ty6wnjlrcultfcdc5trz2xy5tjglcx"
                    ) {
                      handleMsg = {
                        redeem: { amount },
                      };
                    } else {
                      handleMsg = {
                        withdraw: { incentivized_token_amount: amount },
                      };
                    }
                    let params = {
                      sender: SECRET_NETWORK.walletAddress,
                      contract_address: pool.smart_contract.address,
                      code_hash: pool.smart_contract.data_hash,
                      msg: handleMsg,
                    };
                    await SECRET_NETWORK.executeContract(params, 2_000_000);
                    document.showAlertSuccess("Withdraw successful", true);
                    document[withdrawFormName].amount.value = "";
                    SECRET_NETWORK.poolsPage.updatePoolInterface(pool, true);
                  }
                } catch (err) {
                  document.showAlertDanger(err);
                } finally {
                  document.enableButton(withdrawButtonSelector);
                  if (
                    pool.category == "yield_optimizer" &&
                    pool.reward_token == SECRET_NETWORK.cryptocurrencies[372]
                  ) {
                    SECRET_NETWORK.poolsPage.pools.forEach((poolTwo) => {
                      if (
                        poolTwo.category == "profit_distributor" &&
                        poolTwo.reward_token == pool.reward_token
                      ) {
                        SECRET_NETWORK.poolsPage.updateRewards(poolTwo);
                      }
                    });
                  }
                }
              };
            }
          });
        } catch (err) {
          document.showAlertDanger(err);
        }
      },
      updatePoolInterface: async (
        pool,
        afterTransaction,
        poolDetailsOnly = false,
        userDetailsOnly = false,
        height = undefined
      ) => {
        let poolIncentivized = $(
          `.card[data-pool-address=${pool.smart_contract.address}]`
        )
          .closest(".block")[0]
          .classList.contains("incentivized");
        if (poolDetailsOnly) {
          if (pool.category == "yield_optimizer") {
            SECRET_NETWORK.poolsPage.updateRewards(
              pool,
              afterTransaction,
              height
            );
          }
        } else if (userDetailsOnly) {
          if (poolIncentivized) {
            SECRET_NETWORK.updateWalletBalance(pool.deposit_token.id);
          }
          SECRET_NETWORK.poolsPage.updateUserWithdrawable(pool);
          if (pool.category != "yield_optimizer") {
            SECRET_NETWORK.poolsPage.updateRewards(
              pool,
              afterTransaction,
              height
            );
          }
        } else {
          if (poolIncentivized) {
            SECRET_NETWORK.updateWalletBalance(pool.deposit_token.id);
          }
          SECRET_NETWORK.poolsPage.updateUserWithdrawable(pool);
          SECRET_NETWORK.poolsPage.updateRewards(
            pool,
            afterTransaction,
            height
          );
        }
      },
      updateRewards: async (
        pool,
        afterTransaction = false,
        height = undefined
      ) => {
        if (pool.category == "yield_optimizer") {
          let $rewardsToProcess = $(
            '[data-pool-address="' + pool.smart_contract.address + '"]'
          ).find(".rewards-to-process");
          if (afterTransaction) {
            $rewardsToProcess.text("0");
          } else {
            $rewardsToProcess.text("Loading...");
            if (!height) {
              height = await SECRET_NETWORK.getBlockHeight();
            }
            let queryParams = {
              contract_address: pool.parent_pool.smart_contract.address,
              code_hash: pool.parent_pool.smart_contract.data_hash,
              query: {
                rewards: {
                  address: pool.smart_contract.address,
                  height: height,
                  key: "DoTheRightThing.",
                },
              },
            };
            let response = await SECRET_NETWORK.queryContractSmart(queryParams);
            $rewardsToProcess.text(
              document.humanizeStringNumberFromSmartContract(
                response["rewards"]["rewards"],
                pool.reward_token.decimals
              )
            );
          }
        } else {
          if (SECRET_NETWORK.walletAddress) {
            let $claimButton = $(
              '[data-pool-address="' + pool.smart_contract.address + '"]'
            ).find(".claim-button");
            try {
              if (afterTransaction) {
                $claimButton.find(".ready").text("0");
              } else {
                $claimButton.find(".loading").removeClass("d-none");
                $claimButton.find(".ready").addClass("d-none");
                if (
                  pool.smart_contract.address ==
                    "secret1ccgl5ys39zprnw2jq8g3eq00jd83temmqversz" ||
                  pool.smart_contract.address ==
                    "secret1wuxwnfrkdnysww5nq4v807rj3ksrdv3j5eenv2" ||
                  pool.smart_contract.address ==
                    "secret1sxmznzev9vcnw8yenjddgtfucpu7ymw6emkzan"
                ) {
                  let queryParams = {
                    contract_address: pool.smart_contract.address,
                    code_hash: pool.smart_contract.data_hash,
                    query: {
                      claimable_profit: {
                        user_address: SECRET_NETWORK.walletAddress,
                      },
                    },
                  };
                  let response = await SECRET_NETWORK.queryContractSmart(
                    queryParams
                  );
                  $claimButton
                    .find(".ready")
                    .text(
                      document.humanizeStringNumberFromSmartContract(
                        response["claimable_profit"]["amount"],
                        pool.reward_token.decimals
                      )
                    );
                } else {
                  if (
                    pool.smart_contract.address ==
                    "secret1725s6smzds6h89djq9yqrtlqfepnxruc3m4fku"
                  ) {
                    height = 15_064_182;
                  } else if (!height) {
                    height = await SECRET_NETWORK.getBlockHeight();
                  }
                  let queryMessage;
                  if (
                    pool.smart_contract.address ==
                    "secret18pqz6x67ty6wnjlrcultfcdc5trz2xy5tjglcx"
                  ) {
                    let key = await window.keplr.getSecret20ViewingKey(
                      SECRET_NETWORK.chainId(),
                      pool.smart_contract.address
                    );
                    queryMessage = {
                      rewards: {
                        address: SECRET_NETWORK.walletAddress,
                        height,
                        key,
                      },
                    };
                  } else {
                    queryMessage = {
                      pending_buttcoin: {
                        address: SECRET_NETWORK.walletAddress,
                        height: height,
                      },
                    };
                  }
                  let queryParams = {
                    contract_address: pool.smart_contract.address,
                    code_hash: pool.smart_contract.data_hash,
                    query: queryMessage,
                  };
                  let response = await SECRET_NETWORK.queryContractSmart(
                    queryParams
                  );
                  let rewards;
                  if (
                    pool.smart_contract.address ==
                    "secret18pqz6x67ty6wnjlrcultfcdc5trz2xy5tjglcx"
                  ) {
                    rewards = response["rewards"]["rewards"];
                  } else {
                    rewards = response["pending_buttcoin"]["amount"];
                  }
                  $claimButton
                    .find(".ready")
                    .text(
                      document.humanizeStringNumberFromSmartContract(rewards, 6)
                    );
                }
              }
            } catch (err) {
              if (
                err.message.includes(
                  '{"not_found":{"kind":"cw_profit_distributor::state::User"}}'
                ) ||
                err.message.includes(
                  '{"not_found":{"kind":"cw_profit_distributor_b::state::User"}}'
                )
              ) {
                $poolClaimable.text("n/a");
              }
            } finally {
              $claimButton.find(".loading").addClass("d-none");
              $claimButton.find(".ready").removeClass("d-none");
            }
          }
        }
      },
      updateUserInterface: async (
        poolDetailsOnly = false,
        userDetailsOnly = false,
        poolContainerClass = ".incentivized"
      ) => {
        let height = await SECRET_NETWORK.getBlockHeight();
        for (const [_index, pool] of SECRET_NETWORK.poolsPage.pools.entries()) {
          let $poolCard = $(
            `[data-pool-address="${pool.smart_contract.address}"]`
          );
          if ($poolCard.length) {
            if ($poolCard.closest(poolContainerClass).length) {
              SECRET_NETWORK.poolsPage.updatePoolInterface(
                pool,
                false,
                poolDetailsOnly,
                userDetailsOnly,
                height
              );
            }
          }
        }
      },
      updateUserWithdrawable: async (pool) => {
        let $balanceContainer = $(
          `.balance-container[data-input-selector-to-click-fill-to="form[name=${pool.smart_contract.address}WithdrawForm] input[name=amount]"]`
        );
        let $userShares = $balanceContainer.find(".balance");
        let $userSharesReady = $balanceContainer.find(".ready");
        let $userSharesLoading = $balanceContainer.find(".loading");

        try {
          $userSharesLoading.removeClass("d-none");
          $userSharesReady.addClass("d-none");
          let userResponse;
          let withdrawable = BigNumber("0");
          if (
            pool.smart_contract.address ==
              "secret1ccgl5ys39zprnw2jq8g3eq00jd83temmqversz" ||
            pool.smart_contract.address ==
              "secret1wuxwnfrkdnysww5nq4v807rj3ksrdv3j5eenv2" ||
            pool.smart_contract.address ==
              "secret1sxmznzev9vcnw8yenjddgtfucpu7ymw6emkzan"
          ) {
            let queryParams = {
              contract_address: pool.smart_contract.address,
              code_hash: pool.smart_contract.data_hash,
              query: {
                user: { user_address: SECRET_NETWORK.walletAddress },
              },
            };
            userResponse = await SECRET_NETWORK.queryContractSmart(queryParams);
            withdrawable = BigNumber(userResponse["user"]["shares"]);
          } else if (
            pool.smart_contract.address ==
            "secret18pqz6x67ty6wnjlrcultfcdc5trz2xy5tjglcx"
          ) {
            let height = await SECRET_NETWORK.getBlockHeight();
            let key = await window.keplr.getSecret20ViewingKey(
              SECRET_NETWORK.chainId(),
              pool.smart_contract.address
            );
            let balance = await SECRET_NETWORK.tokens.balance(
              SECRET_NETWORK.walletAddress,
              key,
              pool.smart_contract.address,
              pool.smart_contract.data_hash,
              height
            );
            withdrawable = BigNumber(balance);
          } else {
            let queryParams = {
              contract_address: pool.smart_contract.address,
              code_hash: pool.smart_contract.data_hash,
              query: {
                user_info: { address: SECRET_NETWORK.walletAddress },
              },
            };
            userResponse = await SECRET_NETWORK.queryContractSmart(queryParams);
            withdrawable = BigNumber(userResponse["user_info"]["shares"]);
            if (pool.smart_contract.address != SN_BUTT_SWBTC_FARM_ADDRESS) {
              // Factor in rewards when you get the chance
              let queryParams = {
                contract_address: pool.smart_contract.address,
                code_hash: pool.smart_contract.data_hash,
                query: { pool: {} },
              };
              let poolResponse = await SECRET_NETWORK.queryContractSmart(
                queryParams
              );
              let incentivizedTokenTotal = BigNumber(
                poolResponse["pool"]["incentivized_token_total"]
              );
              if (
                BigNumber(poolResponse["pool"]["shares_total"]).isGreaterThan(0)
              ) {
                withdrawable = withdrawable
                  .multipliedBy(incentivizedTokenTotal)
                  .dividedBy(BigNumber(poolResponse["pool"]["shares_total"]));
              } else {
                withdrawable = BigNumber("0");
              }
            }
          }
          if (withdrawable.isGreaterThan(0)) {
            withdrawable = withdrawable
              .shiftedBy(-1 * pool.deposit_token.decimals)
              .decimalPlaces(pool.deposit_token.decimals);
          }
          let price = BigNumber(
            $("[data-pool-address=" + pool.smart_contract.address + "]")[0][
              "dataset"
            ]["depositablePrice"]
          );
          $userShares.text(withdrawable.toFormat());
          $userShares
            .siblings(".balance-as-usd")
            .text(`($${withdrawable.multipliedBy(price).toFormat(2)})`);
        } catch (err) {
          $userShares.text("n/a");
          if (
            !err.message.includes(
              '{"not_found":{"kind":"cw_profit_distributor::state::User"}}'
            )
          ) {
            document.showAlertDanger(err);
          }
        } finally {
          $userSharesLoading.addClass("d-none");
          $userSharesReady.removeClass("d-none");
        }
      },
    };

    SECRET_NETWORK.poolsPage.init();
  }
});
