// CUSTOM
import { SN_BUTT_ADDRESS, SN_BUTT_DATA_HASH } from "./constants";
import { SECRET_NETWORK } from "./helpers";

$(document).ready(function () {
  if ($("#secret-network-block-locker").length) {
    this.blockLockerContractAddress =
      "secret1dww8fs5xlj6es5vwyp7ccgm4xclkmhxajxfaqa";
    this.blockLockerContractDataHash =
      "9EF097E81EAB5AEC8594F8860AE47ED859FB6363C836F63BF02F01BE58343D36";

    // === LISTENERS ===
    $(document).on("keplr_connected", async () => {
      document.blockLockerViewWhenLockedForm.walletAddress.value =
        SECRET_NETWORK.walletAddress;
    });

    // === FUNCTIONS ===
    this.displayResult = function (result) {
      let userLocker;
      if (result.user_locker) {
        userLocker = result.user_locker;
        if (userLocker.locked) {
          return document.showAlertDanger(
            "Either locker is locked, or the address/passphrase combo is wrong."
          );
        }
      } else {
        userLocker = result.get_user_locker.user_locker;
      }
      // Display results
      $("#content-formatted").html(userLocker.content);
      $("#result-value").text(document.prettyPrintJSON(userLocker));
      $("#result-container").removeClass("d-none");
      $("html, body").animate(
        {
          scrollTop: $("#result-container").offset().top,
        },
        2000
      );
    };

    document.blockLockerCreateOrUpdateForm.onsubmit = async (e) => {
      e.preventDefault();
      $("#result-container").addClass("d-none");
      document.disableButton(e.submitter);
      try {
        await document.connectKeplrWallet();
        if (SECRET_NETWORK.walletAddress) {
          let content = undefined;
          let passphrase = undefined;
          let whitelistedAddresses = undefined;
          if (document.blockLockerCreateOrUpdateForm.content.value.length > 0) {
            content = document.blockLockerCreateOrUpdateForm.content.value;
          }
          if (
            document.blockLockerCreateOrUpdateForm.passphrase.value.length > 0
          ) {
            passphrase =
              document.blockLockerCreateOrUpdateForm.passphrase.value;
          }
          if (
            document.blockLockerCreateOrUpdateForm.whitelistedAddress1.value
              .length > 0 ||
            document.blockLockerCreateOrUpdateForm.whitelistedAddress2.value
              .length > 0 ||
            document.blockLockerCreateOrUpdateForm.whitelistedAddress3.value
              .length > 0
          ) {
            let address;
            whitelistedAddresses = [];
            if (
              document.blockLockerCreateOrUpdateForm.whitelistedAddress1.value
                .length
            ) {
              address = await SECRET_NETWORK.addressValid(
                document.blockLockerCreateOrUpdateForm.whitelistedAddress1
                  .value,
                "production",
                "Recovery address 1 is incorrect."
              );
              whitelistedAddresses.push(address);
            }
            if (
              document.blockLockerCreateOrUpdateForm.whitelistedAddress2.value
                .length
            ) {
              address = await SECRET_NETWORK.addressValid(
                document.blockLockerCreateOrUpdateForm.whitelistedAddress2
                  .value,
                "production",
                "Recovery address 2 is incorrect."
              );
              whitelistedAddresses.push(address);
            }
            if (
              document.blockLockerCreateOrUpdateForm.whitelistedAddress3.value
                .length
            ) {
              address = await SECRET_NETWORK.addressValid(
                document.blockLockerCreateOrUpdateForm.whitelistedAddress3
                  .value,
                "production",
                "Recovery address 3 is incorrect."
              );
              whitelistedAddresses.push(address);
            }
            if (
              whitelistedAddresses.includes(
                SECRET_NETWORK.walletAddress.toLowerCase()
              )
            ) {
              throw "Recovery address must be different to wallet address.";
            }
          }
          let handleMsg = {
            send: {
              amount: "1000000",
              recipient: this.blockLockerContractAddress,
              msg: Buffer.from(
                JSON.stringify({
                  create_or_update_locker: {
                    content: content,
                    passphrase: passphrase,
                    whitelisted_addresses: whitelistedAddresses,
                  },
                })
              ).toString("base64"),
            },
          };
          let params = {
            sender: SECRET_NETWORK.walletAddress,
            contract_address: SN_BUTT_ADDRESS,
            code_hash: SN_BUTT_DATA_HASH,
            msg: handleMsg,
          };
          await SECRET_NETWORK.executeContract(params, 100_000);
          document.showAlertSuccess("Locker updated.");
          $(e.target)[0].reset();
        }
      } catch (err) {
        document.showAlertDanger(err);
      } finally {
        document.enableButton(e.submitter);
      }
    };

    document.blockLockerUnlockForm.onsubmit = async (e) => {
      e.preventDefault();
      $("#result-container").addClass("d-none");
      document.disableButton(e.submitter);
      try {
        await document.connectKeplrWallet();
        if (SECRET_NETWORK.walletAddress) {
          let resultText =
            "If the locker exists and you're allowed to unlock it, it will be unlocked.";
          confirm(
            "Are you sure you want to unlock? Once unlocked, the current contents of the locker can be accessed with only the passphrase forever."
          );
          let handleMsg = {
            send: {
              amount: "1000000",
              recipient: this.blockLockerContractAddress,
              msg: Buffer.from(
                JSON.stringify({
                  unlock_locker: {
                    address: document.blockLockerUnlockForm.walletAddress.value,
                  },
                })
              ).toString("base64"),
            },
          };
          let params = {
            sender: SECRET_NETWORK.walletAddress,
            contract_address: SN_BUTT_ADDRESS,
            code_hash: SN_BUTT_DATA_HASH,
            msg: handleMsg,
          };
          await SECRET_NETWORK.executeContract(params, 100_000);
          document.showAlertSuccess(resultText);
          $(e.target)[0].reset();
        }
      } catch (err) {
        document.showAlertDanger(err);
      } finally {
        document.enableButton(e.submitter);
      }
    };

    document.blockLockerViewWhenLockedForm.onsubmit = async (e) => {
      e.preventDefault();
      $("#result-container").addClass("d-none");
      document.disableButton(e.submitter);
      try {
        await document.connectKeplrWallet();
        if (SECRET_NETWORK.walletAddress) {
          let handleMsg = { get_user_locker: {} };
          let params = {
            sender: SECRET_NETWORK.walletAddress,
            contract_address: this.blockLockerContractAddress,
            code_hash: this.blockLockerContractDataHash,
            msg: handleMsg,
          };
          let result = await SECRET_NETWORK.executeContract(params, 100_000);
          let resultText = "";
          let firstCurlyBracketReached = false;
          result.data[0].forEach(function (x) {
            if (firstCurlyBracketReached || String.fromCharCode(x) == "{") {
              firstCurlyBracketReached = true;
              resultText += String.fromCharCode(x);
            }
          });
          result = JSON.parse(resultText);
          this.displayResult(result);
        }
      } catch (err) {
        document.showAlertDanger(err);
      } finally {
        document.enableButton(e.submitter);
      }
    };

    document.blockLockerViewWhenUnlockedForm.onsubmit = async (e) => {
      e.preventDefault();
      $("#result-container").addClass("d-none");
      document.disableButton(e.submitter);
      try {
        let handleMsg = {
          user_locker: {
            address:
              document.blockLockerViewWhenUnlockedForm.walletAddress.value,
            passphrase:
              document.blockLockerViewWhenUnlockedForm.passphrase.value,
          },
        };
        let queryParams = {
          contract_address: this.blockLockerContractAddress,
          code_hash: this.blockLockerContractDataHash,
          query: handleMsg,
        };
        let result = await SECRET_NETWORK.queryContractSmart(queryParams);
        this.displayResult(result);
      } catch (err) {
        document.showAlertDanger(err);
      } finally {
        document.enableButton(e.submitter);
      }
    };

    document.activateKeplr();
  }
});
