import { ALEPH_ZERO } from "./helpers";
import { POLKADOTJS } from "./../polkadotjs";

export const ALEPH_ZERO_TOKENS = {
  psp22: {
    allowance: async (
      tokenAddress,
      owner,
      spender,
      abiUrl = "https://res.cloudinary.com/hv5cxagki/raw/upload/v1696758943/abis/aleph_zero/az_button_bwigia.json"
    ) => {
      let contract = await ALEPH_ZERO.contracts.getContract(tokenAddress);
      if (!contract) {
        contract = await ALEPH_ZERO.contracts.setContract(tokenAddress, abiUrl);
      }
      let api = await ALEPH_ZERO.api();
      let account = ALEPH_ZERO.account;
      let response = await POLKADOTJS.contractQuery(
        api,
        account.address,
        contract,
        "psp22::allowance",
        undefined,
        [owner, spender]
      );
      ALEPH_ZERO.processResponse(response, tokenAddress);
      let allowance = BigNumber(response.output.asOk.toBigInt());
      if (!ALEPH_ZERO.cryptocurrencies[tokenAddress]) {
        ALEPH_ZERO.cryptocurrencies[tokenAddress] = {};
      }
      ALEPH_ZERO.cryptocurrencies[tokenAddress][ALEPH_ZERO.account.address] = {
        allowance,
      };
      return allowance;
    },
    balanceOf: async (
      tokenAddress,
      owner,
      abiUrl = "https://res.cloudinary.com/hv5cxagki/raw/upload/v1696758943/abis/aleph_zero/az_button_bwigia.json"
    ) => {
      let contract = await ALEPH_ZERO.contracts.getContract(tokenAddress);
      if (!contract) {
        contract = await ALEPH_ZERO.contracts.setContract(tokenAddress, abiUrl);
      }
      let api = await ALEPH_ZERO.api();
      let response = await POLKADOTJS.contractQuery(
        api,
        owner,
        contract,
        "psp22::balanceOf",
        undefined,
        [owner]
      );
      ALEPH_ZERO.processResponse(response, tokenAddress);
      return BigNumber(response.output.asOk.toBigInt());
    },
    deposit: async (
      amount,
      tokenAddress = "5CtuFVgEUz13SFPVY6s2cZrnLDEkxQXc19aXrNARwEBeCXgg",
      abiUrl = "https://link.storjshare.io/s/juldos5d7qtuwqx2itvdhgtgp3vq/smart-contract-hub-production/2cab0puytjru1380vlv6r6ztns21.json?download=1",
      callback = undefined
    ) => {
      let contract = await ALEPH_ZERO.contracts.getContract(tokenAddress);
      if (!contract) {
        contract = await ALEPH_ZERO.contracts.setContract(tokenAddress, abiUrl);
      }
      let api = await ALEPH_ZERO.api();
      api.setSigner(POLKADOTJS.adapter.signer);
      let account = ALEPH_ZERO.account;
      let response = await POLKADOTJS.contractTx(
        api,
        account.address,
        contract,
        "wrappedAZERO::deposit",
        { value: amount },
        [],
        callback
      );
      ALEPH_ZERO.processResponse(response, tokenAddress);
    },
    increaseAllowance: async (
      tokenAddress,
      spender,
      amount,
      abiUrl = "https://link.storjshare.io/s/juldos5d7qtuwqx2itvdhgtgp3vq/smart-contract-hub-production/2cab0puytjru1380vlv6r6ztns21.json?download=1",
      options = {},
      callback = undefined
    ) => {
      let contract = await ALEPH_ZERO.contracts.getContract(tokenAddress);
      if (!contract) {
        contract = await ALEPH_ZERO.contracts.setContract(tokenAddress, abiUrl);
      }
      let api = await ALEPH_ZERO.api();
      api.setSigner(POLKADOTJS.adapter.signer);
      let account = ALEPH_ZERO.account;
      let response = await POLKADOTJS.contractTx(
        api,
        account.address,
        contract,
        "psp22::increase_allowance",
        options,
        [spender, amount.toFormat({ groupSeparator: "" })],
        callback
      );
      ALEPH_ZERO.processResponse(response, tokenAddress);
    },
    // What is the contract and abiUrl combo is wrong
    tokenDecimals: async (
      address,
      abiUrl = "https://res.cloudinary.com/hv5cxagki/raw/upload/v1696932021/abis/aleph_zero/az_button_xywglt.json"
    ) => {
      if (ALEPH_ZERO.cryptocurrencies[address]) {
        return ALEPH_ZERO.cryptocurrencies[address].decimals;
      }
      let contract = await ALEPH_ZERO.contracts.getContract(address);
      if (!contract) {
        contract = await ALEPH_ZERO.contracts.setContract(address, abiUrl);
      }
      let api = await ALEPH_ZERO.api();
      let response = await POLKADOTJS.contractQuery(
        api,
        ALEPH_ZERO.b3,
        contract,
        "psp22Metadata::tokenDecimals"
      );
      ALEPH_ZERO.processResponse(response, address);
      ALEPH_ZERO.cryptocurrencies[address] = {
        decimals: Number(response.output.asOk.toHuman()),
      };
      return ALEPH_ZERO.cryptocurrencies[address].decimals;
    },
    withdraw: async (
      amount,
      tokenAddress = "5CtuFVgEUz13SFPVY6s2cZrnLDEkxQXc19aXrNARwEBeCXgg",
      abiUrl = "https://link.storjshare.io/s/juldos5d7qtuwqx2itvdhgtgp3vq/smart-contract-hub-production/2cab0puytjru1380vlv6r6ztns21.json?download=1",
      callback = undefined
    ) => {
      let contract = await ALEPH_ZERO.contracts.getContract(tokenAddress);
      if (!contract) {
        contract = await ALEPH_ZERO.contracts.setContract(tokenAddress, abiUrl);
      }
      let api = await ALEPH_ZERO.api();
      api.setSigner(POLKADOTJS.adapter.signer);
      let account = ALEPH_ZERO.account;
      let response = await POLKADOTJS.contractTx(
        api,
        account.address,
        contract,
        "wrappedAZERO::withdraw",
        {},
        [amount],
        callback
      );
      ALEPH_ZERO.processResponse(response, tokenAddress);
    },
  },
};
