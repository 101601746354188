import {
  stringToCoin,
  stringToCoins,
  MsgExecuteContract,
  SecretNetworkClient,
} from "secretjs";
window.MsgExecuteContract = MsgExecuteContract;
window.stringToCoin = stringToCoin;
window.stringToCoins = stringToCoins;
window.SecretNetworkClient = SecretNetworkClient;
