// CUSTOM
import { HELPERS } from "./../../application";
import {
  SN_BUTT_ADDRESS,
  SN_BUTT_DATA_HASH,
  SN_MOUNT_DOOM_ADDRESS,
  SN_MOUNT_DOOM_DATA_HASH,
} from "./constants";
import { SECRET_NETWORK } from "./helpers";
import { TRANSACTIONS_TABLE } from "./../application/_transactions_table";

$(document).ready(function () {
  if ($("#secret-network-mount-doom").length) {
    SECRET_NETWORK.mountDoom = {
      $nftsContainer: $("#nfts-container"),
      $nftsResults: $("#nfts-results"),
      queryCount: 0,
      tokenAddress: SN_BUTT_ADDRESS,
      tokenDataHash: SN_BUTT_DATA_HASH,
      nftAddress: "secret1xx4fp7qgkyxdk9elrzd8v5p7gj28lvxprwl9lw",
      nftDataHash:
        "481A283CA0D7988CFFB6987A35D1F455012A231E0D43C49F1EC191E8B89B7681",
      transactionsTableId: "#transactions-table",
      init: () => {
        // === LIST ===
        HELPERS.initTokenLists(["nft-list", "token-list"]);

        // === LISTENERS ===
        HELPERS.listeners.listenForTokenSelect(SECRET_NETWORK.mountDoom);
        $('a[href="#tab-nft"]').on("click", function (e) {
          e.preventDefault();
          $("#sacrifice-count").removeClass("d-none");
          $("#nfts-results").removeClass("d-none");
          $("#fungible-results").addClass("d-none");
        });
        $('a[href="#tab-snip-20"]').on("click", function (e) {
          e.preventDefault();
          $("#sacrifice-count").addClass("d-none");
          $("#nfts-results").addClass("d-none");
          $("#fungible-results").removeClass("d-none");
        });
        $('a[href="#tab-add"]').on("click", function (e) {
          e.preventDefault();
          $("#sacrifice-count").addClass("d-none");
          $("#nfts-results").addClass("d-none");
          $("#fungible-results").addClass("d-none");
        });

        // === TRANSACTIONS TABLE ===
        TRANSACTIONS_TABLE.init(SECRET_NETWORK.mountDoom.transactionsTableId);
      },
      processNftTxs: async (
        txs,
        address,
        codeHash = undefined,
        currentQueryCount
      ) => {
        let nftsContainerHtml = "";
        $("#sacrifice-count").text(`${txs.length} sent to Mount Doom.`);
        for (const value of txs) {
          nftsContainerHtml += `<div class="col-sm-6 col-md-4 col-lg-6 col-xl-4 col-xxl-3 mb-4" id="${address}-${value.token_id}"><div class="card mb-0">`;
          nftsContainerHtml +=
            '<div class="card-body"><img class="w-100" src=""></div>';
          nftsContainerHtml +=
            '<div class="card-footer py-5"><h5 class="mb-0"></h5></div></div></div>';
          SECRET_NETWORK.mountDoom.$nftsResults.html(nftsContainerHtml);
        }
        SECRET_NETWORK.mountDoom.$nftsContainer.removeClass("d-none");
        for (const value of txs) {
          if (currentQueryCount == SECRET_NETWORK.mountDoom.queryCount) {
            await (async () => {
              try {
                let params = {
                  nft_info: {
                    token_id: value["token_id"],
                  },
                };
                let queryParams = {
                  code_hash: codeHash,
                  contract_address: address,
                  query: params,
                };
                let nftInfoResponse = await SECRET_NETWORK.queryContractSmart(
                  queryParams
                );
                let imageUrl;
                let nftName;
                let svg;
                if (nftInfoResponse["nft_info"]["token_uri"]) {
                  let result = await $.ajax({
                    url: nftInfoResponse["nft_info"]["token_uri"],
                    type: "GET",
                  });
                  imageUrl = result["image"];
                  nftName = result["name"];
                } else if (nftInfoResponse["nft_info"]["extension"]["image"]) {
                  imageUrl = nftInfoResponse["nft_info"]["extension"]["image"];
                  nftName = nftInfoResponse["nft_info"]["extension"]["name"];
                } else if (
                  nftInfoResponse["nft_info"]["extension"]["image_data"]
                ) {
                  svg = nftInfoResponse["nft_info"]["extension"]["image_data"];
                  nftName = nftInfoResponse["nft_info"]["extension"]["name"];
                } else {
                  imageUrl =
                    nftInfoResponse["nft_info"]["extension"]["media"][0]["url"];
                  nftName = nftInfoResponse["nft_info"]["extension"]["name"];
                }
                if (currentQueryCount == SECRET_NETWORK.mountDoom.queryCount) {
                  if (imageUrl) {
                    $(`#${address}-${value.token_id} .card-body img`).prop(
                      "src",
                      imageUrl
                    );
                  } else {
                    $(`#${address}-${value.token_id} .card-body`).html(svg);
                  }
                  if (!nftName) {
                    nftName = value["token_id"];
                  }
                  $(`#${address}-${value.token_id} .card-footer h5`).text(
                    nftName
                  );
                }
              } catch (err) {
                document.showAlertDanger(
                  "Error retrieving all images. Please try again."
                );
              }
            })();
          }
        }
      },
      updateAfterTokenSelect: async (event) => {
        let address =
          event["currentTarget"]["dataset"]["cryptocurrencyAddress"];
        let dataHash =
          event["currentTarget"]["dataset"]["cryptocurrencyDataHash"];
        let cloudinaryPublicId =
          event["currentTarget"]["dataset"]["cryptocurrencyCloudinaryPublicId"];
        let symbol = event["currentTarget"]["dataset"]["cryptocurrencySymbol"];
        if (!cloudinaryPublicId) {
          cloudinaryPublicId = "external-content.duckduckgo-1_memqe7";
        }
        let $otherFieldItem;
        if ($("[href='#tab-snip-20']").attr("aria-selected") == "true") {
          SECRET_NETWORK.mountDoom.tokenAddress = address;
          SECRET_NETWORK.mountDoom.tokenDataHash = dataHash;
          document.setTokenButton(
            "#fungibleTokenAddress",
            symbol,
            cloudinaryPublicId
          );
          $otherFieldItem = $(
            "form[name='mountDoomFungibleTokenQueryForm']"
          ).find(".other-field-item");
          if (symbol == "OTHER") {
            $otherFieldItem.removeClass("d-none");
          } else {
            $otherFieldItem.addClass("d-none");
            $(document.mountDoomFungibleTokenQueryForm).trigger("submit");
          }
        } else {
          SECRET_NETWORK.mountDoom.nftAddress = address;
          SECRET_NETWORK.mountDoom.nftDataHash = dataHash;
          document.setTokenButton(
            "#nonFungibleTokenAddress",
            event["currentTarget"]["dataset"]["cryptocurrencyName"],
            cloudinaryPublicId
          );
          $otherFieldItem = $(
            "form[name='mountDoomNonFungibleTokenQueryForm']"
          ).find(".other-field-item");
          if (symbol == "OTHER") {
            $otherFieldItem.removeClass("d-none");
          } else {
            $otherFieldItem.addClass("d-none");
            $(document.mountDoomNonFungibleTokenQueryForm).trigger("submit");
          }
        }
      },
    };

    // === FUNCTIONS ===
    document.mountDoomFungibleTokenQueryForm.onsubmit = async (e) => {
      e.preventDefault();
      SECRET_NETWORK.mountDoom.queryCount += 1;
      $("#fungible-results-inner-container").addClass("d-none");
      document.disableButton(e.submitter);
      let tokenAddress = SECRET_NETWORK.mountDoom.tokenAddress;
      if (!tokenAddress) {
        tokenAddress =
          document.mountDoomFungibleTokenQueryForm.otherTokenAddress.value;
      }
      let tokenDataHash = SECRET_NETWORK.mountDoom.tokenDataHash;
      try {
        // Reset transactions table and balance
        TRANSACTIONS_TABLE.reset(SECRET_NETWORK.mountDoom.transactionsTableId);
        // Get the token info
        let tokenInfo = await SECRET_NETWORK.tokens.info(
          tokenAddress,
          tokenDataHash
        );
        tokenDecimals = tokenInfo.decimals;
        tokenSymbol = tokenInfo.symbol;
        // Get the transfers
        let txs = await SECRET_NETWORK.tokens.transfers(
          SN_MOUNT_DOOM_ADDRESS,
          "DoTheRightThing.",
          0,
          1_000,
          tokenAddress,
          tokenDataHash
        );

        // Format the txs for the table
        await SECRET_NETWORK.transactions.getSmartContractsInTxs(
          SN_MOUNT_DOOM_ADDRESS,
          txs
        );
        let transactionsForTable = SECRET_NETWORK.transactions.forTable(
          txs,
          tokenDecimals,
          SN_MOUNT_DOOM_ADDRESS
        );

        // Get the balance for the token
        let balance = await SECRET_NETWORK.tokens.balance(
          SN_MOUNT_DOOM_ADDRESS,
          "DoTheRightThing.",
          tokenAddress,
          tokenDataHash,
          undefined,
          tokenDecimals,
          true
        );
        // Display results
        $("#fungible-results-inner-container").removeClass("d-none");
        TRANSACTIONS_TABLE.render(
          SECRET_NETWORK.mountDoom.transactionsTableId,
          balance,
          tokenSymbol,
          transactionsForTable
        );
      } catch (err) {
        if (err.message && err.message.includes("Wrong viewing key")) {
          document.showAlertWarning(
            'Viewing key not set: Please set on the "Set viewing key" tab.',
            false
          );
        } else {
          document.showAlertDanger(err);
        }
      } finally {
        document.enableButton(e.submitter);
      }
    };

    document.mountDoomNonFungibleTokenQueryForm.onsubmit = async (e) => {
      e.preventDefault();
      let currentQueryCount = (SECRET_NETWORK.mountDoom.queryCount += 1);
      $("#sacrifice-count").text("");
      SECRET_NETWORK.mountDoom.$nftsResults.html("");
      SECRET_NETWORK.mountDoom.$nftsContainer.addClass("d-none");
      document.disableButton(e.submitter);

      let address = SECRET_NETWORK.mountDoom.nftAddress;
      if (!SECRET_NETWORK.mountDoom.nftAddress) {
        address =
          document.mountDoomNonFungibleTokenQueryForm.otherTokenAddress.value;
      }
      try {
        // Get the transactions for that token
        let params = {
          transaction_history: {
            address: SN_MOUNT_DOOM_ADDRESS,
            viewing_key: "DoTheRightThing.",
            page: 0,
            page_size: 1_000,
          },
        };
        let queryParams = {
          code_hash: SECRET_NETWORK.mountDoom.nftDataHash,
          contract_address: address,
          query: params,
        };
        let transactions_response = await SECRET_NETWORK.queryContractSmart(
          queryParams
        );
        if (transactions_response["viewing_key_error"]) {
          throw transactions_response["viewing_key_error"]["msg"];
        }
        let txs = transactions_response["transaction_history"]["txs"];
        if (currentQueryCount == SECRET_NETWORK.mountDoom.queryCount) {
          SECRET_NETWORK.mountDoom.processNftTxs(
            txs.reverse(),
            address,
            SECRET_NETWORK.mountDoom.nftDataHash,
            currentQueryCount
          );
        }
      } catch (err) {
        if (err.message && err.message.includes("Wrong viewing key")) {
          document.showAlertWarning(
            'Viewing key not set: Please set on the "Set viewing key" tab.',
            false
          );
        } else {
          document.showAlertDanger(err);
        }
      } finally {
        document.enableButton(e.submitter);
      }
    };

    document.mountDoomSetViewingKeyForm.onsubmit = async (e) => {
      e.preventDefault();
      document.disableButton(e.submitter);

      try {
        await document.connectKeplrWallet();
        if (SECRET_NETWORK.walletAddress) {
          let tokenAddress =
            document.mountDoomSetViewingKeyForm.tokenAddress.value;
          let client = await SECRET_NETWORK.client();
          let codeHashResponse =
            await client.query.compute.codeHashByContractAddress({
              contract_address: tokenAddress,
            });
          let msg = {
            set_viewing_key_for_snip20: {
              address: tokenAddress,
              contract_hash: codeHashResponse.code_hash,
            },
          };
          let params = {
            sender: SECRET_NETWORK.walletAddress,
            contract_address: SN_MOUNT_DOOM_ADDRESS,
            code_hash: SN_MOUNT_DOOM_DATA_HASH,
            msg: msg,
          };
          await SECRET_NETWORK.executeContract(params, 100_000);
          document.showAlertSuccess('Viewing key "DoTheRightThing." set.');
        }
      } catch (err) {
        if (err.message.includes("decoding bech32 failed:")) {
          err = "Invalid address.";
        }
        document.showAlertDanger(err);
      } finally {
        document.enableButton(e.submitter);
      }
    };

    document.activateKeplr();
    SECRET_NETWORK.mountDoom.init();
  }
});
