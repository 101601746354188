// CUSTOM
import { ALEPH_ZERO } from "../helpers";
import { POLKADOTJS } from "../../polkadotjs";

$(function () {
  if ($("#aleph-zero-desuntralised").length) {
    ALEPH_ZERO.lightSwitch = {
      animationTime: 5_000,
      config: undefined,
      contractAddress: "5EYhHaAifC7gvRtRQ23dobgHwxpqj2iEoxkvERdm1SVuHsQa",
      datatable: $("#records-table").DataTable({
        columns: [
          {
            data: "id",
            title: "#",
          },
          {
            data: "caller",
            title: "User",
            fnCreatedCell: function (nTd, sData, _oData, _iRow, _iCol) {
              let html = ALEPH_ZERO.linkToExplorer(sData);
              $(nTd).html(`<a href="${html}" target="_blank">${sData}</a>`);
            },
          },
          {
            data: "on",
            title: "Action",
            fnCreatedCell: function (nTd, sData, _oData, _iRow, _iCol) {
              let text;
              if (sData) {
                text = "On";
              } else {
                text = "Off";
              }
              $(nTd).text(text);
            },
          },
          {
            data: "blockHeight",
            title: "Block",
            fnCreatedCell: function (nTd, sData, _oData, _iRow, _iCol) {
              let html = ALEPH_ZERO.linkToExplorer(
                document.formatHumanizedNumberForSmartContract(sData, 0),
                "block"
              );
              $(nTd).html(`<a href="${html}" target="_blank">${sData}</a>`);
            },
          },
        ],
        ordering: false,
        paging: false,
        bInfo: false,
      }),
      init: async () => {
        $("#kt_app_content_container").addClass("h-100");
        await ALEPH_ZERO.activatePolkadotJsExtension();
        await ALEPH_ZERO.lightSwitch.getConfig();
        // === LISTENERS ===
        $("#light-switch-link").on("click", function (e) {
          e.preventDefault();
          ALEPH_ZERO.lightSwitch.flip();
        });
        await ALEPH_ZERO.lightSwitch.recordsIndex(0, 50);
      },
      flip: async () => {
        try {
          while (!ALEPH_ZERO.lightSwitch.config) {
            await document.delay(500);
          }
          // User will think it's on
          let localOnStatus = ALEPH_ZERO.lightSwitch.config.on;
          // Update the config
          await ALEPH_ZERO.lightSwitch.getConfig();
          if (localOnStatus == ALEPH_ZERO.lightSwitch.config.on) {
            const contract = await ALEPH_ZERO.contracts.getContract(
              ALEPH_ZERO.lightSwitch.contractAddress
            );
            let api = await ALEPH_ZERO.api();
            let account = ALEPH_ZERO.account;
            api.setSigner(POLKADOTJS.adapter.signer);
            if (localOnStatus) {
              await POLKADOTJS.contractTx(
                api,
                account.address,
                contract,
                "turnOff"
              );
            } else {
              let value = new POLKADOTJS.BN(
                document.formatHumanizedNumberForSmartContract(
                  ALEPH_ZERO.lightSwitch.config.onFee,
                  0
                )
              );
              await POLKADOTJS.contractTx(
                api,
                account.address,
                contract,
                "turnOn",
                { value }
              );
            }
            ALEPH_ZERO.lightSwitch.getConfig();
            ALEPH_ZERO.lightSwitch.recordsIndex(0, 50);
          }
        } catch (err) {
          if (
            [
              "InsufficientTimePassed",
              "LightAlreadyOff",
              "LightAlreadyOn",
            ].includes(err.errorMessage)
          ) {
            document.showAlertInfo(err.errorMessage);
            ALEPH_ZERO.lightSwitch.getConfig();
          } else {
            document.showAlertDanger(err, true);
          }
        }
      },
      getConfig: async () => {
        let localOnStatus;
        if (ALEPH_ZERO.lightSwitch.config) {
          localOnStatus = ALEPH_ZERO.lightSwitch.config.on;
        }
        let api = await ALEPH_ZERO.api();
        let walletAddress = "5Ct6QWhVw19sycue4km33E2eHxmgSdAW4jUGzGfdBDKxvL5L";
        const contract = await ALEPH_ZERO.contracts.getContract(
          ALEPH_ZERO.lightSwitch.contractAddress
        );
        const { output } = await POLKADOTJS.contractQuery(
          api,
          walletAddress,
          contract,
          "config"
        );
        ALEPH_ZERO.lightSwitch.config = output.toHuman().Ok;
        $("#on-fee").text(
          `${POLKADOTJS.humanizeStringNumberFromSmartContract(
            ALEPH_ZERO.lightSwitch.config.onFee,
            12
          )} AZERO`
        );
        $("#off-payment").text(
          `${POLKADOTJS.humanizeStringNumberFromSmartContract(
            ALEPH_ZERO.lightSwitch.config.offPayment,
            12
          )} AZERO`
        );
        if (localOnStatus != ALEPH_ZERO.lightSwitch.config.on) {
          if (ALEPH_ZERO.lightSwitch.config.on) {
            $("#light-switch-on").removeClass("d-none");
            $("#light-switch-off").addClass("d-none");
            ALEPH_ZERO.lightSwitch.resetSun();
            $("#sun-container").animate({ height: 200 }, 1_000);
            await document.delay(1_000);
            $("#sun").animate(
              { top: 0, left: 0 },
              ALEPH_ZERO.lightSwitch.animationTime
            );
          } else {
            $("#light-switch-off").removeClass("d-none");
            $("#light-switch-on").addClass("d-none");
            $("#sun").animate(
              {
                top: ($(window).height() * 3) / 4,
                left: ($(window).width() * 3) / 4,
              },
              ALEPH_ZERO.lightSwitch.animationTime
            );
            await document.delay(ALEPH_ZERO.lightSwitch.animationTime);
            $("#sun-container").animate({ height: 0 }, 1_000);
            // Hide sun and put in neutral position to not affect view height
            $("#sun").addClass("d-none");
            $("#sun").css("top", 0);
            $("#sun").css("left", 0);
          }
        }
        ALEPH_ZERO.lightSwitch.setCanTurnOffIn();
      },
      recordsIndex: async (page, size) => {
        let api = await ALEPH_ZERO.api();
        let walletAddress = "5Ct6QWhVw19sycue4km33E2eHxmgSdAW4jUGzGfdBDKxvL5L";
        const contract = await ALEPH_ZERO.contracts.getContract(
          ALEPH_ZERO.lightSwitch.contractAddress
        );
        const { output } = await POLKADOTJS.contractQuery(
          api,
          walletAddress,
          contract,
          "records",
          {},
          [page, size]
        );
        ALEPH_ZERO.lightSwitch.datatable.clear();
        ALEPH_ZERO.lightSwitch.datatable.rows.add(output.toHuman().Ok.values);
        ALEPH_ZERO.lightSwitch.datatable.columns.adjust().draw();
      },
      resetSun: () => {
        $("#sun").removeClass("d-none");
        $("#sun").css("top", ($(window).height() * 3) / 4);
        $("#sun").css("left", ($(window).width() * -1 * 3) / 4);
      },
      setCanTurnOffIn: async () => {
        let $canTurnOffIn = $("#can-turn-off-in");
        if (ALEPH_ZERO.lightSwitch.config.on) {
          let currentTime = new Date();
          let text;
          let onTime = Number(
            document.formatHumanizedNumberForSmartContract(
              ALEPH_ZERO.lightSwitch.config.onTime,
              0
            )
          );
          let minimumOnTimeInMs = Number(
            document.formatHumanizedNumberForSmartContract(
              ALEPH_ZERO.lightSwitch.config.minimumOnTimeInMs,
              0
            )
          );
          let offTime = onTime + minimumOnTimeInMs;
          let secondsUntilOffTime = parseInt((offTime - currentTime) / 1_000);
          if (secondsUntilOffTime > 0) {
            text = `in ${secondsUntilOffTime} secs`;
            $canTurnOffIn.find("span").removeClass("text-success");
            $canTurnOffIn.find("span").addClass("text-danger");
          } else {
            text = "now";
            $canTurnOffIn.find("span").removeClass("text-danger");
            $canTurnOffIn.find("span").addClass("text-success");
          }
          $canTurnOffIn.find("span").text(text);
          $canTurnOffIn.removeClass("d-none");
          if (secondsUntilOffTime > 0) {
            await document.delay(1_000);
            ALEPH_ZERO.lightSwitch.setCanTurnOffIn();
          }
        } else {
          $canTurnOffIn.addClass("d-none");
        }
      },
    };

    ALEPH_ZERO.lightSwitch.init();
  }
});
