// CUSTOM
import {
  SN_ADDRESS_ALIAS_ADDRESS,
  SN_ADDRESS_ALIAS_DATA_HASH,
  SN_BUTT_ADDRESS,
  SN_BUTT_DATA_HASH,
} from "./constants";
import { SECRET_NETWORK } from "./helpers";

$(document).ready(function () {
  if ($("#secret-network-password-manager").length) {
    $("#set-key-button").on("click", function (e) {
      document.querySelectorAll("a[href^='#tab-2-1-2']")[0].click();
    });

    this.passwordManagerContractAddress =
      "secret1x56ls7efhdy8axktua0gzuc7muvgwnr98gh54j";
    this.passwordManagerContractDataHash =
      "F466CF15F3186F1816D4D6A4BCEE6998E512179E5DBA9F2922DCCA7640381217";
    this.chosenPosition;
    this.authentications = [];
    this.authenticationsFormatted = [];

    // datatable
    this.datatable = $("#authentications-table").DataTable({
      columns: [
        { data: "position", title: "#" },
        { data: "label", title: "label" },
        { data: "username", title: "username" },
        { data: "password", title: "password" },
        { data: "notes", title: "notes" },
        {
          data: null,
          className: "dt-center editor-edit",
          defaultContent:
            '<a href="#" class="me-3 view-link">View</a><a href="#" class="edit-link">Edit</a>',
          orderable: false,
          width: "91px",
        },
      ],
      dom: '<"top"i>frtp',
      ordering: false,
      paging: false,
      rowId: function (a) {
        return "position_" + a["position"];
      },
    });

    // listeners
    $("a[href^='#tab-index']").on(
      "click",
      function () {
        this.datatable.clear();
        if (this.authentications.length) {
          $("#results-container").removeClass("d-none");
        }
      }.bind(this)
    );

    $("a[href^='#tab-edit']").on(
      "click",
      function () {
        $("#results-container").addClass("d-none");
        if (this.authentications[this.chosenPosition].revealed) {
          $(".reveal-button").addClass("d-none");
        } else {
          if (this.addressOwnsAuthentication(this.searchedAddress)) {
            $(".reveal-button").removeClass("d-none");
          } else {
            $(".reveal-button").addClass("d-none");
          }
        }
      }.bind(this)
    );

    $("a[href^='#tab-new']").on(
      "click",
      function () {
        $("#results-container").addClass("d-none");
      }.bind(this)
    );

    $("a[href^='#tab-2-1-2']").on(
      "click",
      function () {
        $("#results-container").addClass("d-none");
      }.bind(this)
    );

    $("a[href^='#tab-show']").on(
      "click",
      function () {
        $("#results-container").addClass("d-none");
        this.setShowTableData();
        if (this.authentications[this.chosenPosition].revealed) {
          $(".reveal-button").addClass("d-none");
        } else {
          if (this.addressOwnsAuthentication(this.searchedAddress)) {
            $(".reveal-button").removeClass("d-none");
            $("#edit-button").removeClass("d-none");
          } else {
            $(".reveal-button").addClass("d-none");
            $("#edit-button").addClass("d-none");
          }
        }
      }.bind(this)
    );

    $("#edit-button").on(
      "click",
      function () {
        document.querySelectorAll("a[href^='#tab-edit']")[0].click();
        this.setPasswordManagerUpdateForm();
      }.bind(this)
    );

    $(".reveal-button").on(
      "click",
      function () {
        let $button = $(".reveal-button");
        let $loading = $button.children(".loading");
        let $ready = $button.children(".ready");
        $button.prop("disabled", true);
        $loading.removeClass("d-none");
        $ready.addClass("d-none");
        (async () => {
          try {
            await document.connectKeplrWallet();
            if (SECRET_NETWORK.walletAddress) {
              let params = {
                sender: SECRET_NETWORK.walletAddress,
                contract_address: this.passwordManagerContractAddress,
                code_hash: this.passwordManagerContractDataHash,
                msg: { show: { position: Number(this.chosenPosition) } },
              };
              let response = await SECRET_NETWORK.executeContract(
                params,
                400_000
              );
              document.response = response;
              let resultText = "";
              let firstCurlyBracketReached = false;
              response.data[0].forEach(function (x) {
                if (firstCurlyBracketReached || String.fromCharCode(x) == "{") {
                  firstCurlyBracketReached = true;
                  resultText += String.fromCharCode(x);
                }
              });
              let authentication =
                JSON.parse(resultText)["show"]["authentication"];
              authentication["revealed"] = true;
              this.chosenPosition = authentication["position"];
              this.authentications[this.chosenPosition] = authentication;
              this.authenticationsFormatted[this.chosenPosition] =
                authentication;
              this.setPasswordManagerUpdateForm();
              this.setShowTableData();
              $button.addClass("d-none");
              document.showAlertSuccess("Revealed");
            }
          } catch (err) {
            document.showAlertDanger(err);
          } finally {
            $button.prop("disabled", false);
            $loading.addClass("d-none");
            $ready.removeClass("d-none");
          }
        })();
      }.bind(this)
    );

    // === FORMS ===
    document.passwordManagerCreateForm.onsubmit = async (e) => {
      e.preventDefault();
      document.disableButton(e.submitter);
      let label = document.passwordManagerCreateForm.label.value;
      let username = document.passwordManagerCreateForm.username.value;
      let password = document.passwordManagerCreateForm.password.value;
      let notes = document.passwordManagerCreateForm.notes.value;
      try {
        await document.connectKeplrWallet();
        if (SECRET_NETWORK.walletAddress) {
          let handleMsg = {
            send: {
              amount: "1000000",
              recipient: this.passwordManagerContractAddress,
              msg: Buffer.from(
                JSON.stringify({
                  create: {
                    label: label,
                    username: username,
                    password: password,
                    notes: notes,
                  },
                })
              ).toString("base64"),
            },
          };
          let params = {
            sender: SECRET_NETWORK.walletAddress,
            contract_address: SN_BUTT_ADDRESS,
            code_hash: SN_BUTT_DATA_HASH,
            msg: handleMsg,
          };
          let response = await SECRET_NETWORK.executeContract(params, 400_000);
          let newAuthentication = {
            revealed: true,
          };
          _.each(response.arrayLog, function (kV) {
            if (kV["key"] == "id") {
              newAuthentication["id"] = kV["value"];
            }
            if (kV["key"] == "position") {
              newAuthentication["position"] = Number(kV["value"]);
            }
            if (kV["key"] == "label") {
              newAuthentication["label"] = kV["value"];
            }
            if (kV["key"] == "username") {
              newAuthentication["username"] = kV["value"];
            }
            if (kV["key"] == "password") {
              newAuthentication["password"] = kV["value"];
            }
            if (kV["key"] == "notes") {
              newAuthentication["notes"] = kV["value"];
            }
          });
          this.chosenPosition = newAuthentication["position"];
          this.authentications[newAuthentication["position"]] =
            newAuthentication;
          this.authenticationsFormatted[newAuthentication["position"]] =
            newAuthentication;
          document.querySelectorAll("a[href^='#tab-show']")[0].click();
          document.showAlertSuccess("Authentication created.");
          document.passwordManagerCreateForm.label.value = "";
          document.passwordManagerCreateForm.username.value = "";
          document.passwordManagerCreateForm.password.value = "";
          document.passwordManagerCreateForm.notes.value = "";
        }
      } catch (err) {
        document.showAlertDanger(err);
      } finally {
        document.enableButton(e.submitter);
      }
    };

    document.passwordManagerSearchForm.onsubmit = async (e) => {
      e.preventDefault();
      this.datatable.clear();
      $("#results-container").addClass("d-none");
      document.disableButton(e.submitter);
      try {
        let queryParams;
        let searchParams;
        let searchType = document.passwordManagerSearchForm.searchType.value;
        this.searchedAddress =
          document.passwordManagerSearchForm.searchValue.value.toLowerCase();
        if (searchType == "alias") {
          searchParams = {
            search: {
              search_type: searchType,
              search_value: this.searchedAddress,
            },
          };
          queryParams = {
            contract_address: SN_ADDRESS_ALIAS_ADDRESS,
            code_hash: SN_ADDRESS_ALIAS_DATA_HASH,
            query: searchParams,
          };
          let result = await SECRET_NETWORK.queryContractSmart(queryParams);
          this.searchedAddress = result["attributes"]["address"];
        }
        let viewingKey = document.passwordManagerSearchForm.viewingKey.value;
        queryParams = {
          contract_address: this.passwordManagerContractAddress,
          code_hash: this.passwordManagerContractDataHash,
          query: { hints: { address: this.searchedAddress, key: viewingKey } },
        };
        let response = await SECRET_NETWORK.queryContractSmart(queryParams);
        if (response["viewing_key_error"]) {
          throw response["viewing_key_error"]["msg"];
        }
        this.authentications = _.cloneDeep(response["hints"]["hints"]);
        this.authenticationsFormatted = _.map(
          response["hints"]["hints"],
          function (authentication) {
            authentication["username"] += "...";
            authentication["password"] += "...";
            authentication["notes"] += "...";
            return authentication;
          }
        );
        this.refreshTable(this.searchedAddress, this.authenticationsFormatted);
        $("#results-container").removeClass("d-none");
      } catch (err) {
        document.showAlertDanger(err);
      } finally {
        document.enableButton(e.submitter);
      }
    };

    document.passwordManagerUpdateForm.onsubmit = async (e) => {
      e.preventDefault();
      document.disableButton(e.submitter);
      let position = Number(document.passwordManagerUpdateForm.position.value);
      let id = document.passwordManagerUpdateForm.id.value;
      let label = document.passwordManagerUpdateForm.label.value;
      let username = document.passwordManagerUpdateForm.username.value;
      let password = document.passwordManagerUpdateForm.password.value;
      let notes = document.passwordManagerUpdateForm.notes.value;
      try {
        await document.connectKeplrWallet();
        if (SECRET_NETWORK.walletAddress) {
          let handleMsg = {
            update_authentication: {
              position: position,
              id: id,
              label: label,
              username: username,
              password: password,
              notes: notes,
            },
          };
          let params = {
            sender: SECRET_NETWORK.walletAddress,
            contract_address: this.passwordManagerContractAddress,
            code_hash: this.passwordManagerContractDataHash,
            msg: handleMsg,
          };
          let response = await SECRET_NETWORK.executeContract(params, 400_000);
          let resultText = "";
          let firstCurlyBracketReached = false;
          response.data[0].forEach(function (x) {
            if (firstCurlyBracketReached || String.fromCharCode(x) == "{") {
              firstCurlyBracketReached = true;
              resultText += String.fromCharCode(x);
            }
          });
          let authentication =
            JSON.parse(resultText)["update_authentication"]["authentication"];
          authentication["revealed"] = true;
          this.chosenPosition = authentication["position"];
          this.authentications[this.chosenPosition] = authentication;
          this.authenticationsFormatted[this.chosenPosition] = authentication;
          this.setPasswordManagerUpdateForm();
          document.querySelectorAll("a[href^='#tab-show']")[0].click();
          document.showAlertSuccess("Update successful.");
        }
      } catch (err) {
        document.showAlertDanger(err);
      } finally {
        document.enableButton(e.submitter);
      }
    };

    document.setViewingKeyForm.onsubmit = async (e) => {
      e.preventDefault();
      document.disableButton(e.submitter);
      try {
        await document.connectKeplrWallet();
        if (SECRET_NETWORK.walletAddress) {
          let viewingKey = document.setViewingKeyForm.viewingKey.value;
          let padding =
            Math.random()
              .toString()
              .substr(
                2,
                Math.floor(
                  Math.random() * (10 + Math.floor(Math.random() * 10))
                )
              ) +
            Math.random()
              .toString()
              .substr(
                2,
                Math.floor(
                  Math.random() * (10 + Math.floor(Math.random() * 10))
                )
              );
          let handleMsg = {
            set_viewing_key: { key: viewingKey, padding: padding },
          };
          let params = {
            sender: SECRET_NETWORK.walletAddress,
            contract_address: this.passwordManagerContractAddress,
            code_hash: this.passwordManagerContractDataHash,
            msg: handleMsg,
          };
          await SECRET_NETWORK.executeContract(params, 400_000);
          document.showAlertSuccess("Viewing key set.");
        }
      } catch (err) {
        document.showAlertDanger(err);
      } finally {
        document.enableButton(e.submitter);
      }
    };

    this.setPasswordManagerUpdateForm = function () {
      document.passwordManagerUpdateForm.position.value = this.chosenPosition;
      document.passwordManagerUpdateForm.id.value =
        this.authentications[this.chosenPosition]["id"];
      document.passwordManagerUpdateForm.label.value =
        this.authentications[this.chosenPosition]["label"];
      document.passwordManagerUpdateForm.username.value =
        this.authentications[this.chosenPosition]["username"];
      document.passwordManagerUpdateForm.password.value =
        this.authentications[this.chosenPosition]["password"];
      document.passwordManagerUpdateForm.notes.value =
        this.authentications[this.chosenPosition]["notes"];
    };

    this.setShowTableData = function () {
      $("#position-table-data").text(this.chosenPosition);
      $("#table-title").text("Authentication #" + this.chosenPosition);
      $("#label-table-data").text(
        this.authenticationsFormatted[this.chosenPosition]["label"]
      );
      $("#username-table-data").text(
        this.authenticationsFormatted[this.chosenPosition]["username"]
      );
      $("#password-table-data").text(
        this.authenticationsFormatted[this.chosenPosition]["password"]
      );
      $("#notes-table-data").text(
        this.authenticationsFormatted[this.chosenPosition]["notes"]
      );
    };

    this.refreshTable = function (address, data) {
      this.datatable.clear();
      this.datatable.rows.add(data);
      this.datatable.draw();
      $(".edit-link").on(
        "click",
        function (e) {
          e.preventDefault();
          this.chosenPosition =
            e.currentTarget.parentNode.parentNode.id.split("_")[1];
          document.querySelectorAll("a[href^='#tab-edit']")[0].click();
          this.setPasswordManagerUpdateForm();
        }.bind(this)
      );

      $(".view-link").on(
        "click",
        function (e) {
          e.preventDefault();
          this.chosenPosition =
            e.currentTarget.parentNode.parentNode.id.split("_")[1];
          document.querySelectorAll("a[href^='#tab-show']")[0].click();
        }.bind(this)
      );
      if (this.addressOwnsAuthentication(address)) {
        $(".edit-link").removeClass("d-none");
      } else {
        $(".edit-link").addClass("d-none");
      }
    };

    this.addressOwnsAuthentication = function (address) {
      return address.length > 5 && address == SECRET_NETWORK.walletAddress;
    };

    document.activateKeplr();
  }
});
