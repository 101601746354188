import { SupportedChainId, resolveAddressToDomain } from "@azns/resolver-core";
import { ALEPH_ZERO_TOKENS } from "./tokens";
import { HELPERS } from "../../application";
import { POLKADOTJS } from "./../polkadotjs";

export const ALEPH_ZERO = {
  account: undefined,
  accounts: undefined,
  apisLocal: undefined,
  apisStaging: undefined,
  apisProduction: undefined,
  b3: "5HimuS19MhHX9EggD9oZzx297qt3UxEdkcc5NWAianPAQwHG",
  migrationPoolByCryptoId: {},
  tradePairs: {},
  wrapPaths: {},
  contracts: {
    getContractsFromBackend: async (
      addresses = undefined,
      blockchain_id = 185
    ) => {
      let data = `blockchain_id=${blockchain_id}`;
      if (addresses) {
        data += `&addresses=${addresses}`;
      }
      let contracts = await $.ajax({
        url: `/smart_contracts?${data}`,
      });
      contracts.forEach(function (smartContract) {
        ALEPH_ZERO.contractsByAddressFromBackend[smartContract.address] =
          smartContract;
      });
    },
    getContract: async (address) => {
      if (!ALEPH_ZERO.contractsByAddress[address]) {
        if (!POLKADOTJS.validateAddress(address)) {
          throw "Address invalid.";
        }

        if (!ALEPH_ZERO.contractsByAddressFromBackend[address]) {
          await ALEPH_ZERO.contracts.getContractsFromBackend(address);
        }
        let contractFromBackend =
          ALEPH_ZERO.contractsByAddressFromBackend[address];
        if (contractFromBackend && contractFromBackend.abi_url) {
          await ALEPH_ZERO.contracts.setContract(
            address,
            contractFromBackend.abi_url
          );
        }
      }
      return ALEPH_ZERO.contractsByAddress[address];
    },
    setContract: async (address, abiUrl, environment = "production") => {
      let api = await ALEPH_ZERO.api(environment);
      let metadata = await $.ajax({
        url: abiUrl,
      });
      ALEPH_ZERO.contractsByAddress[address] = new POLKADOTJS.ContractPromise(
        api,
        metadata,
        address
      );
      return ALEPH_ZERO.contractsByAddress[address];
    },
    azeroIdRouter: {
      domains: [],
      primaryDomain: undefined,
      address: (environment = "production") => {
        if (environment == "production") {
          return "5FfRtDtpS3Vcr7BTChjPiQNrcAKu3VLv4E1NGF6ng6j3ZopJ";
        } else {
          return "5HXjj3xhtRMqRYCRaXTDcVPz3Mez2XBruyujw6UEkvn8PCiA";
        }
      },
      getContract: async (environment = "production") => {
        let address = ALEPH_ZERO.contracts.azeroIdRouter.address(environment);
        if (!ALEPH_ZERO.contractsByAddress[address]) {
          await ALEPH_ZERO.contracts.setContract(
            address,
            "https://link.storjshare.io/s/juldos5d7qtuwqx2itvdhgtgp3vq/smart-contract-hub-production/jt86lapudzdtrjxbz0ljdyi66jp9.json?download=1",
            environment
          );
        }
        return ALEPH_ZERO.contractsByAddress[address];
      },
      getAndSetDomains: async (environment = "production") => {
        try {
          ALEPH_ZERO.contracts.azeroIdRouter.domains = [];
          ALEPH_ZERO.contracts.azeroIdRouter.primaryDomain = undefined;
          let address = ALEPH_ZERO.account.address;
          let chainId;
          if (environment == "staging") {
            chainId = SupportedChainId.AlephZeroTestnet;
          } else {
            chainId = SupportedChainId.AlephZero;
          }
          let response = await resolveAddressToDomain(address, {
            chainId,
          });
          if (response.error) {
            throw response.error.message;
          }

          ALEPH_ZERO.contracts.azeroIdRouter.domains =
            response.allPrimaryDomains;
          ALEPH_ZERO.contracts.azeroIdRouter.primaryDomain =
            response.primaryDomain;
        } catch (err) {
          document.showAlertDanger(err);
        }
      },
    },
    common: {
      pair: {
        contract: async (address) => {
          let contract = await ALEPH_ZERO.contracts.getContract(address);
          if (!contract) {
            contract = await ALEPH_ZERO.contracts.setContract(
              address,
              "https://link.storjshare.io/s/juldos5d7qtuwqx2itvdhgtgp3vq/smart-contract-hub-production/yf0lsdpcw8hz8pt3ugdyd8uih6f3.json?download=1"
            );
          }
          return contract;
        },
        // reserve_0, reserve_1, block_timestamp_last
        getReserves: async (address) => {
          let contract = await ALEPH_ZERO.contracts.common.pair.contract(
            address
          );
          let api = await ALEPH_ZERO.api();
          let response = await POLKADOTJS.contractQuery(
            api,
            ALEPH_ZERO.b3,
            contract,
            "pair::getReserves",
            undefined,
            undefined
          );
          ALEPH_ZERO.processResponse(response, address);
          return [
            BigNumber(
              document.formatHumanizedNumberForSmartContract(
                response.output.toHuman().Ok[0],
                0
              )
            ),
            BigNumber(
              document.formatHumanizedNumberForSmartContract(
                response.output.toHuman().Ok[1],
                0
              )
            ),
          ];
        },
      },
      router: {
        address: "5DRnWewtFkLtuKT6pD7QVto4fXSEjoGvX6pccjVpdCpaz2EV",
        contract: async () => {
          let contract = await ALEPH_ZERO.contracts.getContract(
            ALEPH_ZERO.contracts.common.router.address
          );
          if (!contract) {
            contract = await ALEPH_ZERO.contracts.setContract(
              ALEPH_ZERO.contracts.common.router.address,
              "https://link.storjshare.io/s/juldos5d7qtuwqx2itvdhgtgp3vq/smart-contract-hub-production/r0zzr21oaorf5zzcdjns3e08cd7i.json?download=1"
            );
          }
          return contract;
        },
        swapExactNativeForTokens: async (
          amount,
          minimumAcceptableAmount,
          path,
          callback = undefined
        ) => {
          let contract = await ALEPH_ZERO.contracts.common.router.contract();
          let api = await ALEPH_ZERO.api();
          api.setSigner(POLKADOTJS.adapter.signer);
          let deadline = new Date().getTime() + 60_000;
          let response = await POLKADOTJS.contractTx(
            api,
            ALEPH_ZERO.account.address,
            contract,
            "router::swapExactNativeForTokens",
            { value: amount },
            [
              minimumAcceptableAmount,
              path,
              ALEPH_ZERO.account.address,
              deadline,
            ],
            callback
          );
          ALEPH_ZERO.processResponse(
            response,
            ALEPH_ZERO.contracts.common.router.address
          );
          return response;
        },
        swapExactTokensForTokens: async (
          amount,
          minimumAcceptableAmount,
          path,
          callback = undefined
        ) => {
          let contract = await ALEPH_ZERO.contracts.common.router.contract();
          let api = await ALEPH_ZERO.api();
          api.setSigner(POLKADOTJS.adapter.signer);
          let deadline = new Date().getTime() + 60_000;
          let response = await POLKADOTJS.contractTx(
            api,
            ALEPH_ZERO.account.address,
            contract,
            "router::swapExactTokensForTokens",
            {},
            [
              amount,
              minimumAcceptableAmount,
              path,
              ALEPH_ZERO.account.address,
              deadline,
            ],
            callback
          );
          ALEPH_ZERO.processResponse(
            response,
            ALEPH_ZERO.contracts.common.router.address
          );
          return response;
        },
        swapExactTokensForNative: async (
          amount,
          minimumAcceptableAmount,
          path,
          callback = undefined
        ) => {
          let contract = await ALEPH_ZERO.contracts.common.router.contract();
          let api = await ALEPH_ZERO.api();
          api.setSigner(POLKADOTJS.adapter.signer);
          let deadline = new Date().getTime() + 60_000;
          let response = await POLKADOTJS.contractTx(
            api,
            ALEPH_ZERO.account.address,
            contract,
            "router::swapExactTokensForNative",
            {},
            [
              amount,
              minimumAcceptableAmount,
              path,
              ALEPH_ZERO.account.address,
              deadline,
            ],
            callback
          );
          ALEPH_ZERO.processResponse(
            response,
            ALEPH_ZERO.contracts.common.router.address
          );
          return response;
        },
      },
    },
    smartContractHub: {
      address: (environment = "production") => {
        if (environment == "production") {
          return "5DHxiJXTEtgf4yuSMw8mhnwjTn11ME77wwBSU6rVjg8bszKQ";
        } else {
          return "5DnHpTfNHYQE7YB6PV3D7DPL8gVSfqUd6mwCKzuYgHgqPtS3";
        }
      },
      getContract: async (environment = "production") => {
        let address =
          ALEPH_ZERO.contracts.smartContractHub.address(environment);
        if (!ALEPH_ZERO.contractsByAddress[address]) {
          await ALEPH_ZERO.contracts.setContract(
            address,
            "https://link.storjshare.io/s/juldos5d7qtuwqx2itvdhgtgp3vq/smart-contract-hub-production/6pi4croy3dm9xdrouzle07nffyxz.json?download=1",
            environment
          );
        }
        return ALEPH_ZERO.contractsByAddress[address];
      },
    },
  },
  contractsByAddress: {},
  contractsByAddressFromBackend: {},
  cryptocurrencies: {},
  subsquid: {
    url: "https://squid.subsquid.io/smart-contract-hub/graphql",
    groupUsers: async () => {
      let response = await $.ajax({
        type: "post",
        url: ALEPH_ZERO.subsquid.url,
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify({
          query: `query MyQuery($role_in: [String!] = ["Member", "Admin", "SuperAdmin"]) { groupUsers(where: {accountId_eq: "${ALEPH_ZERO.account.address}", role_in: $role_in}) { accountId id role group { enabled id name } }}`,
        }),
      });
      return response.data.groupUsers;
    },
    height: async () => {
      try {
        let response = await $.ajax({
          type: "post",
          url: ALEPH_ZERO.subsquid.url,
          contentType: "application/json; charset=utf-8",
          data: JSON.stringify({
            query: `query MyQuery {
              squidStatus {
                height
              }
            }`,
          }),
        });
        return response.data.squidStatus.height;
      } catch (err) {
        document.showAlertDanger(err);
      }
    },
    queryData: (search, searchBy, status) => {
      let queryFilterParams;
      let statusQuery = "";
      if (status == "enabled") {
        statusQuery = ", enabled_eq: true";
      } else if (status == "disabled") {
        statusQuery = ", enabled_eq: false";
      }
      if (search.length) {
        switch (searchBy) {
          case "id":
            queryFilterParams = `where: {id_eq: "${search}"${statusQuery}}`;
            break;
          case "project/address":
            queryFilterParams = `where: {address_containsInsensitive: "${search}"${statusQuery}, OR: {projectName_containsInsensitive: "${search}"${statusQuery}}}`;
            break;
          case "addedBy":
            queryFilterParams = `where: {caller_containsInsensitive: "${search}"${statusQuery}}`;
            break;
          case "groupName":
            queryFilterParams = `where: {group: {name_containsInsensitive: "${search}"${statusQuery}}`;
            break;
          // azeroid
          default:
            queryFilterParams = `where: {azeroId_containsInsensitive: "${search}"${statusQuery}}`;
        }
      } else {
        queryFilterParams = `limit: 100, orderBy: createdAt_DESC, where:{${statusQuery}}`;
      }
      let query = `query MyQuery {
        smartContracts(${queryFilterParams}) {
          abiUrl
          address
          auditUrl
          azeroId
          caller
          chain
          contractUrl
          enabled
          github
          id
          projectName
          wasmUrl
          projectWebsite
          createdAt
          group {
            id
            name
          }
        }
      }`;
      return JSON.stringify({
        query,
      });
    },
    waitForSync: async (response) => {
      let attempt = 1;
      let height = response.result.blockNumber.toNumber();
      let syncing = true;
      while (syncing) {
        await document.delay(3_000);
        let squidHeight = await ALEPH_ZERO.subsquid.height();
        if (squidHeight >= height) {
          syncing = false;
        }
        attempt += 1;
        if (attempt == 20) {
          syncing = true;
          document.showAlertInfo(
            "Subsquid is out of sync. Transaction was successful but results will not appear in search until sync is fixed."
          );
        }
      }
    },
  },
  activatePolkadotJsExtension: async () => {
    let response = await POLKADOTJS.activatePolkadotjsExtension();
    ALEPH_ZERO.accounts = response.accounts;
    // Set account
    // There's three options here
    if (ALEPH_ZERO.accounts.length) {
      POLKADOTJS.listenForAccountSelect(ALEPH_ZERO);
      // 1. User has previously selected account and that info is stored in cookies
      if (
        HELPERS.cookies.get("polkadot_account_name") &&
        HELPERS.cookies.get("polkadot_extension")
      ) {
        ALEPH_ZERO.accounts.forEach(function (account) {
          if (
            account.name == HELPERS.cookies.get("polkadot_account_name") &&
            POLKADOTJS.adapter.selectedWallet.name ==
              HELPERS.cookies.get("polkadot_extension")
          ) {
            ALEPH_ZERO.account = account;
            ALEPH_ZERO.updateAfterAccountSet();
          }
        });
      }
      if (!ALEPH_ZERO.account) {
        // 2. User has one account: Auto-select that account
        if (ALEPH_ZERO.accounts.length == 1) {
          ALEPH_ZERO.account = ALEPH_ZERO.accounts[0];
          ALEPH_ZERO.updateAfterAccountSet();
          // 3. User has multiple accounts: Show modal to select account
        } else {
          $("#polkadot-account-list").modal("show");
          document.enableButton(".polkadot-connect-button");
        }
      }
    }
  },
  // AKA API
  api: async (environment = "production") => {
    let apis;
    let httpUrls = await ALEPH_ZERO.httpUrls(environment);
    switch (environment) {
      case "staging":
        if (!ALEPH_ZERO.apisStaging) {
          ALEPH_ZERO.apisStaging = [];
          for (const url of httpUrls) {
            let wsProvider = new POLKADOTJS.WsProvider(url);
            let c = await POLKADOTJS.ApiPromise.create({
              provider: wsProvider,
            });
            ALEPH_ZERO.apisStaging.push(c);
          }
        }
        apis = ALEPH_ZERO.apisStaging;
        break;
      case "local":
        if (!ALEPH_ZERO.apisLocal) {
          ALEPH_ZERO.apisLocal = [];
          let c = await POLKADOTJS.ApiPromise.create({
            provider: "ws://127.0.0.1:9944",
          });
          ALEPH_ZERO.apisLocal.push(c);
        }
        apis = ALEPH_ZERO.apisLocal;
        break;
      default:
        if (!ALEPH_ZERO.apisProduction) {
          ALEPH_ZERO.apisProduction = [];
          for (const url of httpUrls) {
            let wsProvider = new POLKADOTJS.WsProvider(url);
            let c = await POLKADOTJS.ApiPromise.create({
              provider: wsProvider,
            });
            ALEPH_ZERO.apisProduction.push(c);
          }
        }
        while (ALEPH_ZERO.apisProduction.length == 0) {
          await document.delay(1_000);
        }
        apis = ALEPH_ZERO.apisProduction;
        break;
    }
    return _.sample(apis);
  },
  getAndSetCryptocurrencies: async (
    data = "blockchain_id=185&official=true&nft=false&include_attachments=true",
    storeBySmartContractAddress = true
  ) => {
    try {
      let result = await $.ajax({
        data,
        type: "GET",
        url: `/cryptocurrencies?${data}`,
      });
      result.forEach((cryptocurrency) => {
        ALEPH_ZERO.cryptocurrencies[cryptocurrency.id] = cryptocurrency;
        if (storeBySmartContractAddress && cryptocurrency.smart_contract) {
          ALEPH_ZERO.cryptocurrencies[cryptocurrency.smart_contract.address] =
            cryptocurrency;
        }
      });
    } catch (err) {
      document.showAlertDanger(err);
    }
  },
  getAndSetTradePairsAndWrapPaths: async (
    data = "blockchain_id=185&enabled=true&categories=trade_pair,wrap,migration"
  ) => {
    try {
      let result = await $.ajax({
        data,
        type: "GET",
        url: "/pools",
      });
      result.forEach(async (pool) => {
        ALEPH_ZERO.tradePairs[pool.id] = pool;
        ALEPH_ZERO.tradePairs[pool.smart_contract.address] = pool;
        while (Object.keys(ALEPH_ZERO.cryptocurrencies).length == 0) {
          await document.delay(1_000);
        }
        if (pool.category == "wrap") {
          let nativeId;
          let tokenId;
          pool["cryptocurrency_pools"].forEach((cp) => {
            if (
              ALEPH_ZERO.cryptocurrencies[cp.cryptocurrency_id][
                "smart_contract"
              ] == undefined
            ) {
              nativeId = cp["cryptocurrency_id"];
            } else {
              tokenId = cp["cryptocurrency_id"];
            }
          });
          // Handle multiple wrap to tokens
          if (ALEPH_ZERO.wrapPaths[nativeId]) {
            ALEPH_ZERO.wrapPaths[nativeId].push(tokenId);
          } else {
            ALEPH_ZERO.wrapPaths[nativeId] = [tokenId];
          }
          if (ALEPH_ZERO.wrapPaths[tokenId]) {
            ALEPH_ZERO.wrapPaths[tokenId].push(nativeId);
          } else {
            ALEPH_ZERO.wrapPaths[tokenId] = [nativeId];
          }
        } else if (pool.category == "migration") {
          // Handling one way for shade protocol right now
          let fromId;
          let toId;
          pool["cryptocurrency_pools"].forEach((cp) => {
            if (cp.cryptocurrency_role == "deposit") {
              fromId = cp.cryptocurrency_id;
              ALEPH_ZERO.migrationPoolByCryptoId[cp.cryptocurrency_id] = pool;
            } else {
              toId = cp.cryptocurrency_id;
            }
          });
          ALEPH_ZERO.wrapPaths[fromId] = [toId];
        }
      });
    } catch (err) {
      document.showAlertDanger(err);
    }
  },
  getBlockHeight: async () => {
    try {
      let api = await ALEPH_ZERO.api();
      return api.query.system.number();
    } catch (err) {
      document.showAlertDanger(err);
    }
  },
  httpUrls: async (environment = "production") => {
    let urls = ["wss://ws.azero.dev"];
    if (environment == "staging") {
      urls = ["wss://ws.test.azero.dev"];
    }
    return urls;
  },
  linkToExplorer: (identifier, type = "account") => {
    let link;
    link = `https://alephzero.subscan.io/${type}/${identifier}`;
    return link;
  },
  processResponse: (response, address) => {
    if (response.result.isErr) {
      if (response.result.asErr.toJSON().module.error == "0x06000000") {
        ALEPH_ZERO.contractsByAddress[address] = undefined;
        throw "Contract not found.";
      }
    }
  },
  setUsdTextFromAmount: function (humanizedAmount, cryptocurrencyId, selector) {
    let usdText = "";
    if (Number(humanizedAmount)) {
      usdText = document.amountToCurrency(
        humanizedAmount,
        ALEPH_ZERO.cryptocurrencies[cryptocurrencyId].price
      );
    }
    $(selector).text(usdText);
  },
  setUserBlockExplorerLinks: () => {
    $("#polkadot-user-menu-toggle .block-explorers").removeClass("d-none");
    let html = "";
    [["Subscan", "https://alephzero.subscan.io/account/"]].forEach(function (
      blockExplorerDetails
    ) {
      html += `<div class="menu-item px-3"><a class="menu-link px-5" href="${
        blockExplorerDetails[1] + ALEPH_ZERO.account.address
      }" target="_blank">${blockExplorerDetails[0]}</a></div>`;
    });
    $("#polkadot-user-menu-toggle .block-explorers .menu-sub").html(html);
  },
  subscan: async (path, data = undefined) => {
    if (data) {
      data = JSON.stringify(data);
    }
    try {
      let response = await $.ajax({
        type: "post",
        url: `/subscan/${path}`,
        data,
      });
      return response;
    } catch (err) {
      document.showAlertDanger(err);
    }
  },
  updateAfterAccountSelect: (event) => {
    let setNewAccount = false;
    let newAddress = event.currentTarget.dataset.accountAddress;
    if (ALEPH_ZERO.account) {
      if (ALEPH_ZERO.account.address != newAddress) {
        setNewAccount = true;
      }
    } else {
      setNewAccount = true;
    }
    if (setNewAccount) {
      ALEPH_ZERO.accounts.forEach(function (account) {
        if (account.address == newAddress) {
          ALEPH_ZERO.account = account;
          ALEPH_ZERO.updateAfterAccountSet();
        }
      });
    }
  },
  updateAfterAccountSet: () => {
    $("#polkadot-user-menu-toggle").removeClass("d-none");
    $("#polkadot-user-menu-toggle .wallet-address").text(
      ALEPH_ZERO.account.address
    );
    HELPERS.copyToClipboard("polkadot-user-account-menu-wallet-address");
    document.cookie = `polkadot_account_name=${ALEPH_ZERO.account.name};`;
    document.cookie = `polkadot_extension=${POLKADOTJS.adapter.selectedWallet.name};`;
    $(".polkadot-connect-button").addClass("d-none");
    document.enableButton(".polkadot-connect-button");
    ALEPH_ZERO.setUserBlockExplorerLinks();
    HELPERS.setUserAccountMenuToggle(
      "#polkadot-user-menu-toggle",
      ALEPH_ZERO.account.address,
      ALEPH_ZERO.account.name,
      POLKADOTJS.adapter.selectedWallet.image.default
    );
    $(document).trigger("aleph_zero_account_selected", {});
  },
  updateWalletBalance: async (cryptocurrencyId) => {
    let cryptocurrency = ALEPH_ZERO.cryptocurrencies[cryptocurrencyId];
    if (cryptocurrency == undefined) {
      return;
    }

    let $userBalanceContainers = $(
      `.user-balance-container[data-cryptocurrency-id=${cryptocurrencyId}][data-blockchain-id=185]`
    );
    document.disableButton($userBalanceContainers);
    // Set wallet balance to empty
    $userBalanceContainers.find(".balance").text("");
    // Hide balance view button
    $(
      `.balance-view-button[data-cryptocurrency-id=${cryptocurrencyId}][data-blockchain-id=185]`
    ).addClass("d-none");

    let api = await ALEPH_ZERO.api();
    let balance;
    let blockHeight;
    try {
      if (ALEPH_ZERO.account) {
        blockHeight = await ALEPH_ZERO.getBlockHeight();
        if (
          document.blockchainWalletBalance[185][cryptocurrencyId] == undefined
        ) {
          document.blockchainWalletBalance[185][cryptocurrencyId] = {};
        }
        // If there's no blockheight for that blockchain and cryptocurrency, search that balance
        if (
          document.blockchainWalletBalance[185][cryptocurrencyId].blockHeight ==
            undefined ||
          blockHeight >
            document.blockchainWalletBalance[185][cryptocurrencyId].blockHeight
        ) {
          document.blockchainWalletBalance[185][cryptocurrencyId].balance =
            undefined;
          document.blockchainWalletBalance[185][cryptocurrencyId].blockHeight =
            blockHeight;
          if (cryptocurrency.smart_contract) {
            balance = await ALEPH_ZERO_TOKENS.psp22.balanceOf(
              cryptocurrency.smart_contract.address,
              ALEPH_ZERO.account.address,
              cryptocurrency.smart_contract.abi_url
            );
          } else {
            const { freeBalance } = await POLKADOTJS.getBalance(
              api,
              ALEPH_ZERO.account.address
            );
            balance = freeBalance.toNumber();
          }
          if (
            blockHeight ==
            document.blockchainWalletBalance[185][cryptocurrencyId].blockHeight
          ) {
            document.blockchainWalletBalance[185][cryptocurrencyId].balance =
              balance;
          }
        } else {
          while (
            document.blockchainWalletBalance[185][cryptocurrencyId].balance ==
            undefined
          ) {
            await document.delay(1_000);
          }
          balance =
            document.blockchainWalletBalance[185][cryptocurrencyId].balance;
        }
        if (
          blockHeight ==
          document.blockchainWalletBalance[185][cryptocurrencyId].blockHeight
        ) {
          // Balance to display if AZERO and data attribute says to show the smaller amount
          let azeroBalanceAdjustedFormatted;
          if (cryptocurrency.symbol == "AZERO") {
            let azeroBalanceAdjusted = balance - 500_000_000_000;
            if (azeroBalanceAdjusted < 0) {
              azeroBalanceAdjusted = 0;
            }
            azeroBalanceAdjustedFormatted =
              document.humanizeStringNumberFromSmartContract(
                azeroBalanceAdjusted,
                cryptocurrency.decimals
              );
          }

          // Set balance
          let balanceFormatted = document.humanizeStringNumberFromSmartContract(
            balance,
            cryptocurrency.decimals
          );
          $userBalanceContainers = $(
            `.user-balance-container[data-cryptocurrency-id=${cryptocurrencyId}][data-blockchain-id=185]`
          );
          // Set inputToClickFillTo click listeners
          let bf;
          $userBalanceContainers.each(function (_k, v) {
            let $v = $(v);
            let inputToClickFillTo = $v.attr(
              "data-input-selector-to-click-fill-to"
            );
            let reduceAmountIfAzero = $v.attr("data-reduce-amount-if-azero");
            if (
              cryptocurrency.symbol == "AZERO" &&
              reduceAmountIfAzero == "true"
            ) {
              bf = azeroBalanceAdjustedFormatted;
            } else {
              bf = balanceFormatted;
            }
            // Set wallet balances
            $userBalanceContainers.find(".balance").text(bf);
            // Set wallet balances
            if (inputToClickFillTo) {
              $v.find(".balance").off("click");
              $v.find(".balance").attr("href", "#");
              $v.find(".balance").on("click", function (e) {
                e.preventDefault();
                $(inputToClickFillTo)
                  .val(bf.replace(/,/g, ""))
                  .trigger("input");
              });
            }
          });
        }
      }
    } catch (err) {
      if (
        blockHeight ==
        document.blockchainWalletBalance[185][cryptocurrencyId].blockHeight
      ) {
        // Show balance view button
        $(
          `.balance-view-button[data-cryptocurrency-id=${cryptocurrencyId}][data-blockchain-id=185]`
        ).removeClass("d-none");
      }
    } finally {
      if (
        blockHeight ==
        document.blockchainWalletBalance[185][cryptocurrencyId].blockHeight
      ) {
        document.enableButton($userBalanceContainers);
      }
    }
  },
};
