export const TRANSACTIONS_TABLE = {
  tables: {},
  init: (id) => {
    TRANSACTIONS_TABLE.tables[id] = $(id).DataTable({
      order: [[0, "desc"]],
      paging: false,
      columns: [
        {
          data: "id",
          title: "#",
          fnCreatedCell: function (nTd, sData, oData, _iRow) {
            let html = "";
            if (oData["date"]) {
              let options = {
                year: "numeric",
                month: "short",
                day: "numeric",
              };
              let dateAsString = oData["date"].toLocaleDateString(
                undefined,
                options
              );
              html += `${dateAsString}<br>`;
            }
            html += `#${sData}`;
            $(nTd).html(html);
          },
        },
        {
          data: "descriptionHtml",
          title: "Description",
          fnCreatedCell: function (nTd, sData, _oData, _iRow) {
            $(nTd).html(sData);
          },
        },
        {
          data: "amount",
          title: "Amount",
        },
      ],
      scrollX: true,
    });
  },
  render: (id, balance, tokenSymbol, txs) => {
    let $transactionsTable = TRANSACTIONS_TABLE.tables[id];
    // Display balance
    $("#balance").text(`${balance} ${tokenSymbol}`);
    // Add txs to table
    $transactionsTable.rows.add(txs);
    // Draw table
    $transactionsTable.draw();
    $transactionsTable.columns.adjust();
    // Add token symbol next to amount header
    if (tokenSymbol) {
      $($("th")[2]).text(`Amount (${tokenSymbol})`);
    }
  },
  reset: (id) => {
    TRANSACTIONS_TABLE.tables[id].clear();
    $("#balance").text("");
    $($("th")[2]).text("Amount");
  },
};
