// CUSTOM
import { HELPERS } from "../../../application";
import { ALEPH_ZERO } from "../helpers";
import { ALEPH_ZERO_TOKENS } from "../tokens";
import { POLKADOTJS } from "../../polkadotjs";

const SWAP_CHART_ID = "swap-candle-chart";
const SWAP_CHART_CONTAINER_SELECTOR = "#swap-candle-chart-container";
const FROM_AMOUNT_INPUT_SELECTOR =
  'form[name="alephZeroButtonSwapForm"] input[name="fromAmount"]';
const FROM_BALANCE_CONTAINER_SELECTOR = "#from-balance-container";
const FROM_TOKEN_BUTTON_SELECTOR = "#from-token-button";
const FROM_USD_PRICE_SELECTOR = "#from-usd-price";
const MIN_ACCEPTABLE_AMOUNT_USD_PRICE_SELECTOR =
  "#min-acceptable-amount-usd-price";
const SLIPPAGE_TOLERANCE_INPUT_SELECTOR = "#slippage-tolerance";
const SUBMIT_BUTTON_SELECTOR =
  'form[name="alephZeroButtonSwapForm"] button[type="submit"]';
const TO_AMOUNT_INPUT_SELECTOR =
  'form[name="alephZeroButtonSwapForm"] input[name="estimateAmount"]';
const TO_BALANCE_CONTAINER_SELECTOR = "#to-balance-container";
const TO_TOKEN_BUTTON_SELECTOR = "#to-token-button";
const TO_USD_PRICE_SELECTOR = "#to-usd-price";

export const ALEPH_ZERO_BUTTON_SWAP = {
  fromId: undefined,
  toId: undefined,
  minimumBtnBalance: 20_000_000_000,
  minimumDibsBalance: 4_375_685_250_000_000,
  tokenModalFor: undefined,
  init: async () => {
    ALEPH_ZERO_BUTTON_SWAP.fromId = $(FROM_TOKEN_BUTTON_SELECTOR).data(
      "initialId"
    );
    ALEPH_ZERO_BUTTON_SWAP.toId = $(TO_TOKEN_BUTTON_SELECTOR).data("initialId");
    ALEPH_ZERO_BUTTON_SWAP.addListeners();

    ALEPH_ZERO.activatePolkadotJsExtension();

    ALEPH_ZERO_BUTTON_SWAP.refreshCryptocurrencyPriceChart(
      ALEPH_ZERO_BUTTON_SWAP.toId,
      ALEPH_ZERO_BUTTON_SWAP.fromId
    );
    ALEPH_ZERO.getAndSetCryptocurrencies(
      "blockchain_id=185&nft=false&include_attachments=true",
      true
    );
    ALEPH_ZERO.getAndSetTradePairsAndWrapPaths();

    // Wait for both
    while (
      Object.keys(ALEPH_ZERO.cryptocurrencies).length == 0 ||
      Object.keys(ALEPH_ZERO.tradePairs).length == 0
    ) {
      await document.delay(1_000);
    }
    $(".loading-container").addClass("d-none");
    $(".after-loaded-contents").removeClass("d-none");

    // === LIST ===
    HELPERS.initTokenLists(["hacker-list"]);

    if (Number($(FROM_AMOUNT_INPUT_SELECTOR).val() > 0)) {
      $(FROM_AMOUNT_INPUT_SELECTOR).trigger("input");
    }

    // Refresh every 30 minutes so that the price gets updated every 30 minutes
    setInterval(function () {
      ALEPH_ZERO.getAndSetCryptocurrencies(
        "blockchain_id=185&nft=false&include_attachments=true",
        true
      );
      ALEPH_ZERO.getAndSetTradePairsAndWrapPaths();
    }, 60_000 * 30);
  },
  addListeners: () => {
    $("#buy-btn-button").on("click", async (event) => {
      event.preventDefault();
      let contractAddress = "5GM6vi9rDcLS48ZGUPJLocPUuzwyAqfQPEdhk297WU5yAzw6";
      try {
        document.disableButton("#buy-btn-button");
        let contract = await ALEPH_ZERO.contracts.getContract(contractAddress);
        let api = await ALEPH_ZERO.api();
        api.setSigner(POLKADOTJS.adapter.signer);
        let response = await POLKADOTJS.contractTx(
          api,
          ALEPH_ZERO.account.address,
          contract,
          "buy",
          { value: "50000000000000" },
          []
        );
        ALEPH_ZERO.processResponse(response, contractAddress);
        document.showAlertSuccess("Success");
      } catch (err) {
        document.showAlertDanger(err);
      } finally {
        document.enableButton("#buy-btn-button");
      }
    });

    $(".flip-token").on("click", function (event) {
      event.preventDefault();
      ALEPH_ZERO_BUTTON_SWAP.changeCryptocurrencies(
        ALEPH_ZERO_BUTTON_SWAP.toId,
        ALEPH_ZERO_BUTTON_SWAP.fromId
      );
    });

    $(FROM_AMOUNT_INPUT_SELECTOR).on("input", async () => {
      let amount = BigNumber($(FROM_AMOUNT_INPUT_SELECTOR).val());
      let cryptoDecimals =
        ALEPH_ZERO.cryptocurrencies[ALEPH_ZERO_BUTTON_SWAP.fromId].decimals;
      if (amount.decimalPlaces() > cryptoDecimals) {
        $(FROM_AMOUNT_INPUT_SELECTOR).val(amount.dp(cryptoDecimals));
      }
      await ALEPH_ZERO_BUTTON_SWAP.aggregator.getEstimate(false);
    });

    $(TO_AMOUNT_INPUT_SELECTOR).on("input", async () => {
      let amount = BigNumber($(TO_AMOUNT_INPUT_SELECTOR).val());
      let cryptoDecimals =
        ALEPH_ZERO.cryptocurrencies[ALEPH_ZERO_BUTTON_SWAP.toId].decimals;
      if (amount.decimalPlaces() > cryptoDecimals) {
        $(TO_AMOUNT_INPUT_SELECTOR).val(
          amount.dp(cryptoDecimals, BigNumber.ROUND_DOWN)
        );
      }
      await ALEPH_ZERO_BUTTON_SWAP.aggregator.getEstimate(true);
    });

    $.each(
      [FROM_TOKEN_BUTTON_SELECTOR, TO_TOKEN_BUTTON_SELECTOR],
      function (_index, value) {
        $(value).on("click", function (e) {
          if (e.target && e.target.href && e.target.href == "#") {
            e.preventDefault();
          }
          setTimeout(function () {
            $("#input-text-2").trigger("focus");
          }, 500);
          ALEPH_ZERO_BUTTON_SWAP.tokenModalFor = value;
        });
      }
    );

    $(SLIPPAGE_TOLERANCE_INPUT_SELECTOR).on("input", () => {
      let estimateAmount = BigNumber(
        document.alephZeroButtonSwapForm.estimateAmount.value
      );
      if (estimateAmount.isPositive()) {
        let toCryptocurrencyDecimals =
          ALEPH_ZERO.cryptocurrencies[ALEPH_ZERO_BUTTON_SWAP.toId].decimals;
        let slippage = BigNumber(
          document.alephZeroButtonSwapForm.slippageTolerance.value
        )
          .times(estimateAmount)
          .dividedBy(100);
        let minAmount = estimateAmount
          .minus(slippage)
          .dp(toCryptocurrencyDecimals, BigNumber.ROUND_DOWN);
        document.alephZeroButtonSwapForm.minAmount.value = minAmount;
        ALEPH_ZERO.setUsdTextFromAmount(
          minAmount,
          ALEPH_ZERO_BUTTON_SWAP.toId,
          MIN_ACCEPTABLE_AMOUNT_USD_PRICE_SELECTOR
        );
      }
    });

    $(document).on("aleph_zero_account_selected", async (_evt) => {
      $(".balance-container").removeClass("d-none");
      while (!ALEPH_ZERO.cryptocurrencies[ALEPH_ZERO_BUTTON_SWAP.fromId]) {
        await document.delay(1_000);
      }
      ALEPH_ZERO.updateWalletBalance(ALEPH_ZERO_BUTTON_SWAP.fromId);
      ALEPH_ZERO.updateWalletBalance(ALEPH_ZERO_BUTTON_SWAP.toId);
    });

    HELPERS.listeners.listenForTokenSelect(ALEPH_ZERO_BUTTON_SWAP);

    document.alephZeroButtonSwapForm.onsubmit = async (e) => {
      e.preventDefault();
      let fromId = ALEPH_ZERO_BUTTON_SWAP.fromId;
      let toId = ALEPH_ZERO_BUTTON_SWAP.toId;
      let selectedSwapPath = ALEPH_ZERO_BUTTON_SWAP.aggregator.selectedSwapPath;
      if (
        !ALEPH_ZERO_BUTTON_SWAP.aggregator.oneForOneSwap(fromId, toId) &&
        !selectedSwapPath
      ) {
        return;
      }

      ALEPH_ZERO_BUTTON_SWAP.aggregator.processingTransaction = true;
      let fromCryptocurrency = ALEPH_ZERO.cryptocurrencies[fromId];
      let toCryptocurrency = ALEPH_ZERO.cryptocurrencies[toId];
      let fromAmount = document.alephZeroButtonSwapForm.fromAmount.value;
      let estimateAmount =
        document.alephZeroButtonSwapForm.estimateAmount.value;
      let minAmount = document.alephZeroButtonSwapForm.minAmount.value;
      let toAmount;
      fromAmount = document.formatHumanizedNumberForSmartContract(
        fromAmount,
        fromCryptocurrency.decimals
      );
      estimateAmount = document.formatHumanizedNumberForSmartContract(
        estimateAmount,
        toCryptocurrency.decimals
      );
      minAmount = document.formatHumanizedNumberForSmartContract(
        minAmount,
        toCryptocurrency.decimals
      );
      document.disableButton(SUBMIT_BUTTON_SELECTOR);
      $(SUBMIT_BUTTON_SELECTOR).find(".loading-status").text(`Swapping...`);
      try {
        if (Number(estimateAmount) <= 0) {
          throw "Estimate amount must be greater than 0. Please try again.";
        }
        if (ALEPH_ZERO.account) {
          // Wrap or unwrap
          if (!selectedSwapPath) {
            toAmount = estimateAmount;
            // unwrap
            if (fromCryptocurrency.smart_contract) {
              await ALEPH_ZERO_TOKENS.psp22.withdraw(
                fromAmount,
                fromCryptocurrency.smart_contract.address
              );
              // wrap
            } else {
              await ALEPH_ZERO_TOKENS.psp22.deposit(
                fromAmount,
                toCryptocurrency.smart_contract.address
              );
            }
            // router::swapExactNativeForTokens
          } else if (!fromCryptocurrency.smart_contract_id) {
            let response =
              await ALEPH_ZERO.contracts.common.router.swapExactNativeForTokens(
                fromAmount,
                minAmount,
                selectedSwapPath.crypto_address_path_as_array
              );
            let swapPathsAmounts = JSON.parse(response.decodedOutput).Ok;
            toAmount = swapPathsAmounts[swapPathsAmounts.length - 1];
          } else {
            // Check allowance
            let allowance = await ALEPH_ZERO_TOKENS.psp22.allowance(
              fromCryptocurrency.smart_contract.address,
              ALEPH_ZERO.account.address,
              ALEPH_ZERO.contracts.common.router.address
            );
            if (BigNumber(fromAmount).gt(allowance)) {
              $(SUBMIT_BUTTON_SELECTOR)
                .find(".loading-status")
                .text(`Allowing swapping ${fromCryptocurrency.symbol}...`);
              await ALEPH_ZERO_TOKENS.psp22.increaseAllowance(
                fromCryptocurrency.smart_contract.address,
                ALEPH_ZERO.contracts.common.router.address,
                BigNumber(POLKADOTJS.maxU128).minus(allowance)
              );
              $(SUBMIT_BUTTON_SELECTOR)
                .find(".loading-status")
                .text(`Swapping...`);
            }
            let response;
            if (toCryptocurrency.smart_contract_id) {
              response =
                await ALEPH_ZERO.contracts.common.router.swapExactTokensForTokens(
                  fromAmount,
                  minAmount,
                  selectedSwapPath.crypto_address_path_as_array
                );
            } else {
              response =
                await ALEPH_ZERO.contracts.common.router.swapExactTokensForNative(
                  fromAmount,
                  minAmount,
                  selectedSwapPath.crypto_address_path_as_array
                );
            }
            let swapPathsAmounts = JSON.parse(response.decodedOutput).Ok;
            toAmount = swapPathsAmounts[swapPathsAmounts.length - 1];
          }
          ALEPH_ZERO_BUTTON_SWAP.aggregator.resetAfterSwap();
          document.showAlertSuccess(
            ALEPH_ZERO_BUTTON_SWAP.aggregator.successMessage(
              fromAmount,
              toAmount,
              fromCryptocurrency,
              toCryptocurrency
            )
          );
        }
      } catch (error) {
        if (
          typeof error == "object" &&
          error.errorMessage &&
          error.errorMessage == "InsufficientOutputAmount"
        ) {
          setTimeout(function () {
            $(FROM_AMOUNT_INPUT_SELECTOR).trigger("input");
          }, 500);
        }
        document.showAlertDanger(error);
      } finally {
        document.enableButton(SUBMIT_BUTTON_SELECTOR);
        ALEPH_ZERO_BUTTON_SWAP.aggregator.processingTransaction = false;
      }
    };
  },
  changeCryptocurrencies: async (fromId = undefined, toId = undefined) => {
    let comboChanged = false;
    let refreshSwapPaths = true;
    if (fromId) {
      let previousFromId = ALEPH_ZERO_BUTTON_SWAP.fromId;
      if (Number(previousFromId) != Number(fromId)) {
        comboChanged = true;
        ALEPH_ZERO_BUTTON_SWAP.fromId = fromId;
        // MARKET
        $(FROM_BALANCE_CONTAINER_SELECTOR).attr(
          "data-cryptocurrency-id",
          fromId
        );
        $(FROM_BALANCE_CONTAINER_SELECTOR)
          .find(".balance-view-button")
          .attr("data-cryptocurrency-id", fromId);
        document.setTokenButtonWithId(
          FROM_TOKEN_BUTTON_SELECTOR,
          ALEPH_ZERO_BUTTON_SWAP.fromId,
          ALEPH_ZERO.cryptocurrencies
        );

        // WHAT ARE WE ACTUALLY DOING HERE?
        // This is so that we don't have to get swap paths again when switching from token to it's un/wrapped version
        if (
          ALEPH_ZERO_BUTTON_SWAP.aggregator.swapPaths[previousFromId] &&
          !ALEPH_ZERO_BUTTON_SWAP.aggregator.oneForOneSwap(
            ALEPH_ZERO_BUTTON_SWAP.fromId,
            ALEPH_ZERO_BUTTON_SWAP.toId
          )
        ) {
          if (
            ALEPH_ZERO.wrapPaths[previousFromId] &&
            ALEPH_ZERO.wrapPaths[previousFromId].includes(
              ALEPH_ZERO_BUTTON_SWAP.fromId
            ) &&
            ALEPH_ZERO.wrapPaths[previousFromId].length == 1 &&
            ALEPH_ZERO.wrapPaths[ALEPH_ZERO_BUTTON_SWAP.fromId].length == 1
          ) {
            ALEPH_ZERO_BUTTON_SWAP.aggregator.swapPaths[
              ALEPH_ZERO_BUTTON_SWAP.fromId
            ] = {};
            ALEPH_ZERO_BUTTON_SWAP.aggregator.swapPaths[
              ALEPH_ZERO_BUTTON_SWAP.fromId
            ][ALEPH_ZERO_BUTTON_SWAP.toId] =
              ALEPH_ZERO_BUTTON_SWAP.aggregator.swapPaths[previousFromId][
                ALEPH_ZERO_BUTTON_SWAP.toId
              ];
            refreshSwapPaths = false;
          }
        } else {
          document.alephZeroButtonSwapForm.fromAmount.value = "";
          $(FROM_USD_PRICE_SELECTOR).text("");
        }

        // Balance
        ALEPH_ZERO.updateWalletBalance(ALEPH_ZERO_BUTTON_SWAP.fromId);
      }
    }

    if (toId) {
      let previousToId = ALEPH_ZERO_BUTTON_SWAP.toId;
      if (Number(previousToId) != Number(toId)) {
        comboChanged = true;
        ALEPH_ZERO_BUTTON_SWAP.toId = toId;
        // market
        $(TO_BALANCE_CONTAINER_SELECTOR).attr("data-cryptocurrency-id", toId);
        $(TO_BALANCE_CONTAINER_SELECTOR)
          .find(".balance-view-button")
          .attr("data-cryptocurrency-id", toId);
        document.setTokenButtonWithId(
          TO_TOKEN_BUTTON_SELECTOR,
          ALEPH_ZERO_BUTTON_SWAP.toId,
          ALEPH_ZERO.cryptocurrencies
        );

        // WHAT ARE WE ACTUALLY DOING HERE?
        // This is so that we don't have to get swap paths again when switching to token to it's un/wrapped version
        if (
          ALEPH_ZERO_BUTTON_SWAP.aggregator.swapPaths[
            ALEPH_ZERO_BUTTON_SWAP.fromId
          ] &&
          !ALEPH_ZERO_BUTTON_SWAP.aggregator.oneForOneSwap(
            ALEPH_ZERO_BUTTON_SWAP.fromId,
            ALEPH_ZERO_BUTTON_SWAP.toId
          )
        ) {
          if (
            ALEPH_ZERO.wrapPaths[previousToId] &&
            ALEPH_ZERO.wrapPaths[previousToId].includes(
              ALEPH_ZERO_BUTTON_SWAP.toId
            )
          ) {
            if (
              ALEPH_ZERO.wrapPaths[previousToId].length == 1 &&
              ALEPH_ZERO.wrapPaths[ALEPH_ZERO_BUTTON_SWAP.toId].length == 1
            ) {
              ALEPH_ZERO_BUTTON_SWAP.aggregator.swapPaths[
                ALEPH_ZERO_BUTTON_SWAP.fromId
              ][ALEPH_ZERO_BUTTON_SWAP.toId] =
                ALEPH_ZERO_BUTTON_SWAP.aggregator.swapPaths[
                  ALEPH_ZERO_BUTTON_SWAP.fromId
                ][previousToId];
              refreshSwapPaths = false;
            }
          }
        }
        $(SUBMIT_BUTTON_SELECTOR)
          .find(".ready")
          .text(
            `Buy ${
              ALEPH_ZERO.cryptocurrencies[ALEPH_ZERO_BUTTON_SWAP.toId].symbol
            }`
          );

        // balance
        ALEPH_ZERO.updateWalletBalance(ALEPH_ZERO_BUTTON_SWAP.toId);
      }
    }
    if (comboChanged) {
      ALEPH_ZERO_BUTTON_SWAP.refreshCryptocurrencyPriceChart(
        ALEPH_ZERO_BUTTON_SWAP.toId,
        ALEPH_ZERO_BUTTON_SWAP.fromId
      );
      if (refreshSwapPaths) {
        ALEPH_ZERO_BUTTON_SWAP.toggleConfig();
        ALEPH_ZERO_BUTTON_SWAP.aggregator.getEstimate();
      }
    }
  },
  fillForm: (currentQueryCount) => {
    if (
      currentQueryCount == ALEPH_ZERO_BUTTON_SWAP.aggregator.queryCount &&
      ALEPH_ZERO_BUTTON_SWAP.aggregator.selectedSwapPath
    ) {
      let toCryptocurrency =
        ALEPH_ZERO.cryptocurrencies[
          ALEPH_ZERO_BUTTON_SWAP.aggregator.selectedSwapPath.to_id
        ];
      document.alephZeroButtonSwapForm.estimateAmount.value = BigNumber(
        ALEPH_ZERO_BUTTON_SWAP.aggregator.selectedSwapPath.resultOfSwaps
      )
        .shiftedBy(toCryptocurrency.decimals * -1)
        .dp(toCryptocurrency.decimals, BigNumber.ROUND_DOWN);
      ALEPH_ZERO.setUsdTextFromAmount(
        document.alephZeroButtonSwapForm.estimateAmount.value,
        toCryptocurrency.id,
        TO_USD_PRICE_SELECTOR
      );
      $(SLIPPAGE_TOLERANCE_INPUT_SELECTOR).trigger("input");
    }
  },
  // Paywall unless both coins aren't on the whitelist
  refreshCryptocurrencyPriceChart: async (toId, fromId, range = "daily") => {
    while (Object.keys(ALEPH_ZERO.cryptocurrencies).length == 0) {
      await document.delay(1_000);
    }

    let freeSymbols = [
      "AZERO",
      "WAZERO",
      "USDC (ALEPH ZERO)",
      "USDT (ALEPH ZERO)",
      "WBTC (ALEPH ZERO)",
      "WETH (ALEPH ZERO)",
    ];
    let showChart = false;
    let fromCryptocurrency = ALEPH_ZERO.cryptocurrencies[fromId];
    let toCryptocurrency = ALEPH_ZERO.cryptocurrencies[toId];

    if (
      freeSymbols.includes(fromCryptocurrency.symbol) &&
      freeSymbols.includes(toCryptocurrency.symbol)
    ) {
      showChart = true;
    }

    if (ALEPH_ZERO.account) {
      // Check BTN balance
      if (!showChart) {
        let btnBalance = await ALEPH_ZERO_TOKENS.psp22.balanceOf(
          "5H1XSYvSpMriYdU8zr3drTxJXixd8Gmk352BxAVRJZdvt1ud",
          ALEPH_ZERO.account.address
        );
        if (btnBalance.gte(ALEPH_ZERO_BUTTON_SWAP.minimumBtnBalance)) {
          showChart = true;
        }
      }

      // Check DIBS amount in airdrop contract
      if (!showChart) {
        let airdropContractAddress =
          "5Hfn39bqBqe466LLE1PvHCHcbqFemumAeT4HrrpYPP5o9BTx";
        let contract = await ALEPH_ZERO.contracts.getContract(
          airdropContractAddress
        );
        if (!contract) {
          contract = await ALEPH_ZERO.contracts.setContract(
            airdropContractAddress,
            "https://link.storjshare.io/s/juldos5d7qtuwqx2itvdhgtgp3vq/smart-contract-hub-production/q8t6z9hq1hef41vstxasvybn62if.json?download=1"
          );
        }
        let api = await ALEPH_ZERO.api();
        // wallet with enough dibs: 5GqJMGS4syEEyxULZu3rwGhb6zX9WtDAovcY119E2LzPzbfv
        let response = await POLKADOTJS.contractQuery(
          api,
          ALEPH_ZERO.account.address,
          contract,
          "show",
          undefined,
          [ALEPH_ZERO.account.address]
        );
        ALEPH_ZERO.processResponse(response, airdropContractAddress);
        if (response.output) {
          response = response.output.toHuman();
          if (
            response.Ok &&
            response.Ok.Ok &&
            BigNumber(response.Ok.Ok.totalAmount.replace(/,/g, "")).gte(
              ALEPH_ZERO_BUTTON_SWAP.minimumDibsBalance
            )
          ) {
            showChart = true;
          }
        }
      }
    }

    if (showChart) {
      $(".chart-container").removeClass("d-none");
      $(".pay-wall").addClass("d-none");
      HELPERS.refreshCryptocurrencyPriceChart(
        toId,
        fromId,
        range,
        SWAP_CHART_ID,
        SWAP_CHART_CONTAINER_SELECTOR
      );
    } else {
      $(".chart-container").addClass("d-none");
      $(".pay-wall").removeClass("d-none");
    }
  },
  toggleConfig: () => {
    if (
      ALEPH_ZERO_BUTTON_SWAP.aggregator.oneForOneSwap(
        ALEPH_ZERO_BUTTON_SWAP.fromId,
        ALEPH_ZERO_BUTTON_SWAP.toId
      )
    ) {
      $("#cog-container").addClass("d-none");
      $("#slippage-container").addClass("d-none");
    } else {
      $("#cog-container").removeClass("d-none");
      $("#slippage-container").removeClass("d-none");
    }
  },
  updateAfterTokenSelect: async (event) => {
    let newCryptocurrencyId = event.currentTarget.dataset.cryptocurrencyId;
    if (
      [FROM_TOKEN_BUTTON_SELECTOR].includes(
        ALEPH_ZERO_BUTTON_SWAP.tokenModalFor
      )
    ) {
      await ALEPH_ZERO_BUTTON_SWAP.changeCryptocurrencies(newCryptocurrencyId);
    } else if (
      [TO_TOKEN_BUTTON_SELECTOR].includes(ALEPH_ZERO_BUTTON_SWAP.tokenModalFor)
    ) {
      await ALEPH_ZERO_BUTTON_SWAP.changeCryptocurrencies(
        undefined,
        newCryptocurrencyId
      );
    }
  },
  aggregator: {
    poolReserves: {},
    processingTransaction: false,
    queryCount: 0,
    queryCountStats: {
      swapPathsCount: 0,
      swapPathsSimulated: 0,
    },
    selectedSwapPath: undefined,
    simulationSwapResults: {},
    swapPaths: {},
    extractSwapToId: function (fromId, tradePairId) {
      let x;
      ALEPH_ZERO.tradePairs[tradePairId]["cryptocurrency_pools"].forEach(
        (cryptoPool) => {
          if (
            cryptoPool["cryptocurrency_id"] != Number(fromId) &&
            ["deposit", "receive"].includes(cryptoPool["cryptocurrency_role"])
          ) {
            x = cryptoPool["cryptocurrency_id"];
          }
        }
      );
      return x;
    },
    getAndSetSelectedSwapPath: async (
      fromId,
      toId,
      amount,
      featureObject,
      SUBMIT_BUTTON_SELECTOR,
      reverse = false
    ) => {
      let currentQueryCount = featureObject.queryCount;
      let fromAmountWithoutDecimals;
      try {
        // 0. Check if number is positive
        if (Number(amount) > 0) {
          // 1. Disable button and tell people we're getting swap paths
          document.disableButton(SUBMIT_BUTTON_SELECTOR);
          $(SUBMIT_BUTTON_SELECTOR)
            .find(".loading-status")
            .text("Getting swap paths");

          // 2. Make sure ALEPH_ZERO.trade_pairs is available
          while (Object.keys(ALEPH_ZERO.tradePairs).length == 0) {
            await document.delay(1_000);
          }

          // 3. Put a time delay of 1.5 seconds to factor in key stroke changes
          await document.delay(1_500);

          if (currentQueryCount == featureObject.queryCount) {
            // 4. reset poolsReserves
            ALEPH_ZERO_BUTTON_SWAP.aggregator.poolReserves = {};

            // 5. Set up the featureObjects swapPaths object
            featureObject.swapPaths[fromId] = {};

            // 6. Get and set swap paths
            if (reverse) {
              let toAmountWithoutDecimals =
                document.formatHumanizedNumberForSmartContract(
                  amount,
                  ALEPH_ZERO.cryptocurrencies[toId].decimals
                );
              featureObject.swapPaths[fromId][toId] =
                await ALEPH_ZERO_BUTTON_SWAP.aggregator.getSwapPaths(
                  fromId,
                  toId,
                  undefined,
                  toAmountWithoutDecimals
                );
            } else {
              fromAmountWithoutDecimals =
                document.formatHumanizedNumberForSmartContract(
                  amount,
                  ALEPH_ZERO.cryptocurrencies[fromId].decimals
                );
              featureObject.swapPaths[fromId][toId] =
                await ALEPH_ZERO_BUTTON_SWAP.aggregator.getSwapPaths(
                  fromId,
                  toId,
                  fromAmountWithoutDecimals,
                  undefined
                );
            }

            // ASYNC check
            if (
              currentQueryCount == featureObject.queryCount &&
              featureObject.swapPaths[fromId][toId]
            ) {
              // 7. Set queryCountStats
              featureObject.queryCountStats.swapPathsCount =
                featureObject.swapPaths[fromId][toId].length;
              featureObject.queryCountStats.swapPathsSimulated = 0;

              // 8. If reverse, we get the fromAmount and simulate with that and set everything at the end. The render results method can be modified for this purpose.
              if (reverse) {
                fromAmountWithoutDecimals =
                  featureObject.swapPaths[fromId][toId][0].best_from_amount;
              }

              // 9. Get block height now so that we can do an async check straight after
              await ALEPH_ZERO.getBlockHeight();
              if (currentQueryCount == featureObject.queryCount) {
                $(SUBMIT_BUTTON_SELECTOR)
                  .find(".loading-status")
                  .text(
                    `Checking swap paths ${featureObject.queryCountStats.swapPathsSimulated}/${featureObject.queryCountStats.swapPathsCount}`
                  );
                featureObject.swapPaths[fromId][toId].forEach(
                  async (swapPath) => {
                    let resultOfSwaps =
                      await ALEPH_ZERO_BUTTON_SWAP.aggregator.getResultOfSwaps(
                        fromAmountWithoutDecimals,
                        swapPath,
                        currentQueryCount,
                        featureObject
                      );
                    if (currentQueryCount == featureObject.queryCount) {
                      swapPath.resultOfSwaps = parseFloat(resultOfSwaps);
                      swapPath.netUsdResultOfSwaps =
                        HELPERS.swapPaths.netUsdResultOfSwaps(
                          swapPath,
                          ALEPH_ZERO.cryptocurrencies
                        );
                      featureObject.queryCountStats.swapPathsSimulated += 1;
                      $(SUBMIT_BUTTON_SELECTOR)
                        .find(".loading-status")
                        .text(
                          `Checking swap paths ${featureObject.queryCountStats.swapPathsSimulated}/${featureObject.queryCountStats.swapPathsCount}`
                        );
                    }
                  }
                );
                // 11. Wait until all swap paths have been processed
                while (
                  featureObject.queryCountStats.swapPathsCount !=
                    featureObject.queryCountStats.swapPathsSimulated &&
                  currentQueryCount == featureObject.queryCount
                ) {
                  await document.delay(1_000);
                }
                featureObject.swapPaths[fromId][toId] = _.orderBy(
                  featureObject.swapPaths[fromId][toId],
                  [
                    (o) => o.netUsdResultOfSwaps.toNumber(),
                    (o) => Number(o.resultOfSwaps),
                  ],
                  ["desc", "desc"]
                );
                featureObject.selectedSwapPath =
                  featureObject.swapPaths[fromId][toId][0];
                if (reverse) {
                  featureObject.selectedSwapPath.best_from_amount =
                    fromAmountWithoutDecimals;
                }
              }
            }
          }
        }
      } catch (err) {
        if (currentQueryCount == featureObject.queryCount) {
          if (err.status == 503) {
            throw err;
          } else if (err.status != 401) {
            document.showAlertDanger(err);
          }
        }
      } finally {
        if (currentQueryCount == featureObject.queryCount) {
          document.enableButton(SUBMIT_BUTTON_SELECTOR);
          $(SUBMIT_BUTTON_SELECTOR).find(".loading-status").text("Loading...");
        }
      }
    },
    getEstimate: async (reverse = false) => {
      // 1. Increase query count
      ALEPH_ZERO_BUTTON_SWAP.aggregator.queryCount += 1;
      let currentQueryCount = ALEPH_ZERO_BUTTON_SWAP.aggregator.queryCount;
      // 2. Reset before estimate
      ALEPH_ZERO_BUTTON_SWAP.aggregator.resetBeforeEstimate(reverse);
      let amount;
      let fromId = ALEPH_ZERO_BUTTON_SWAP.fromId;
      let toId = ALEPH_ZERO_BUTTON_SWAP.toId;
      // 3. Set the amount and usd amount based on whether it's a reverse or not
      if (reverse) {
        amount = document.alephZeroButtonSwapForm.estimateAmount.value;
        ALEPH_ZERO.setUsdTextFromAmount(amount, toId, TO_USD_PRICE_SELECTOR);
      } else {
        amount = document.alephZeroButtonSwapForm.fromAmount.value;
        ALEPH_ZERO.setUsdTextFromAmount(
          amount,
          fromId,
          FROM_USD_PRICE_SELECTOR
        );
      }
      // 4. Action depend on wrap/unwrap or swap
      if (ALEPH_ZERO_BUTTON_SWAP.aggregator.oneForOneSwap(fromId, toId)) {
        if (reverse) {
          document.alephZeroButtonSwapForm.fromAmount.value = amount;
        } else {
          document.alephZeroButtonSwapForm.estimateAmount.value = amount;
        }
        document.enableButton(SUBMIT_BUTTON_SELECTOR);
      } else {
        try {
          await ALEPH_ZERO_BUTTON_SWAP.aggregator.getAndSetSelectedSwapPath(
            fromId,
            toId,
            amount,
            ALEPH_ZERO_BUTTON_SWAP.aggregator,
            SUBMIT_BUTTON_SELECTOR,
            reverse
          );
          if (
            currentQueryCount == ALEPH_ZERO_BUTTON_SWAP.aggregator.queryCount
          ) {
            if (reverse) {
              document.alephZeroButtonSwapForm.fromAmount.value =
                document.applyDecimals(
                  ALEPH_ZERO_BUTTON_SWAP.aggregator.selectedSwapPath
                    .best_from_amount,
                  ALEPH_ZERO.cryptocurrencies[ALEPH_ZERO_BUTTON_SWAP.fromId]
                    .decimals
                );
              ALEPH_ZERO.setUsdTextFromAmount(
                document.alephZeroButtonSwapForm.fromAmount.value,
                ALEPH_ZERO_BUTTON_SWAP.fromId,
                FROM_USD_PRICE_SELECTOR
              );
            }
            ALEPH_ZERO_BUTTON_SWAP.fillForm(currentQueryCount);
          }
        } catch (err) {
          if (
            currentQueryCount == ALEPH_ZERO_BUTTON_SWAP.aggregator.queryCount
          ) {
            if (err.status == 503) {
              $(FROM_AMOUNT_INPUT_SELECTOR).trigger("input");
            }
          }
        }
      }
    },
    getResultOfSwaps: async (
      fromAmountFormatted,
      swapPath,
      currentQueryCount,
      objectWithQueryCount
    ) => {
      fromAmountFormatted = String(fromAmountFormatted);
      let fromId = swapPath.from_id;
      swapPath.simulationResults = [];
      for (const poolId of swapPath.swap_path_as_array) {
        if (currentQueryCount == objectWithQueryCount.queryCount) {
          fromAmountFormatted =
            await ALEPH_ZERO_BUTTON_SWAP.aggregator.querySwapSimulation(
              fromAmountFormatted,
              fromId,
              poolId,
              currentQueryCount,
              objectWithQueryCount
            );
          swapPath.simulationResults.push(fromAmountFormatted);
          fromId = ALEPH_ZERO_BUTTON_SWAP.aggregator.extractSwapToId(
            fromId,
            poolId
          );
        }
      }
      if (currentQueryCount == objectWithQueryCount.queryCount) {
        return fromAmountFormatted;
      }
      return "0";
    },
    getSwapPaths: async (
      fromId,
      toId,
      fromAmount = undefined,
      toAmount = undefined
    ) => {
      let noSwapPathsError =
        "No swap paths available. Please try different tokens or different amounts.";
      try {
        let swapPaths;
        let headers = {};
        let url = `/swap_paths?blockchain_id=185&from_id=${fromId}&to_id=${toId}`;
        if (fromAmount) {
          url += `&from_amount=${fromAmount}`;
        } else {
          url += `&to_amount=${toAmount}`;
        }
        swapPaths = await $.ajax({
          url,
          headers,
        });
        if (swapPaths.length) {
          return swapPaths;
        } else {
          throw noSwapPathsError;
        }
      } catch (err) {
        if (err == noSwapPathsError) {
          document.showAlertInfo(err);
        } else {
          document.showAlertDanger(err);
        }
      }
    },
    oneForOneSwap: (fromId, toId) => {
      fromId = Number(fromId);
      toId = Number(toId);
      let x = false;
      if (fromId != toId && ALEPH_ZERO.wrapPaths[fromId]) {
        // 1. wrap/unwrap/migration
        // 2. unwrapping from one version of a token and then wrapping to a different version
        x =
          ALEPH_ZERO.wrapPaths[fromId].includes(toId) ||
          (ALEPH_ZERO.wrapPaths[toId] &&
            ALEPH_ZERO.wrapPaths[fromId].toString() ==
              ALEPH_ZERO.wrapPaths[toId].toString());
      }
      return x;
    },
    // Unlike secret network, with Common pools, we don't need to interact with the chain!
    // Just keep it simple.
    querySwapSimulation: async (fromAmountFormatted, fromId, poolId) => {
      let pool = ALEPH_ZERO.tradePairs[poolId];
      let fromCryptoAddress =
        ALEPH_ZERO.cryptocurrencies[fromId].smart_contract.address;
      // This follows fn get_out_amount used in the router contract
      // 1. get reserves
      if (
        !ALEPH_ZERO_BUTTON_SWAP.aggregator.poolReserves[
          pool.smart_contract.address
        ]
      ) {
        ALEPH_ZERO_BUTTON_SWAP.aggregator.poolReserves[
          pool.smart_contract.address
        ] = await ALEPH_ZERO.contracts.common.pair.getReserves(
          pool.smart_contract.address
        );
      }

      // 2.
      let otherTokenReserve;
      let thisTokenReserve;
      pool.cryptocurrency_pools.forEach(function (cp) {
        if (cp.cryptocurrency_id == fromId) {
          if (cp.position == 0) {
            thisTokenReserve =
              ALEPH_ZERO_BUTTON_SWAP.aggregator.poolReserves[
                pool.smart_contract.address
              ][0];
            otherTokenReserve =
              ALEPH_ZERO_BUTTON_SWAP.aggregator.poolReserves[
                pool.smart_contract.address
              ][1];
          } else {
            thisTokenReserve =
              ALEPH_ZERO_BUTTON_SWAP.aggregator.poolReserves[
                pool.smart_contract.address
              ][1];
            otherTokenReserve =
              ALEPH_ZERO_BUTTON_SWAP.aggregator.poolReserves[
                pool.smart_contract.address
              ][0];
          }
        }
      });
      if (thisTokenReserve.lte(0)) {
        return "0";
      }

      // 3. Apply fee
      let fee;
      if (pool.swap_fee) {
        fee = pool.swap_fee * 1_000;
      } else {
        fee = 3;
      }
      let amountInWithFee = BigNumber(fromAmountFormatted).times(1_000 - fee);
      let numerator = amountInWithFee.times(otherTokenReserve);
      let denominator = thisTokenReserve.times(1_000).plus(amountInWithFee);
      let outAmount = numerator.div(denominator).dp(0).toString();
      return outAmount;
    },
    resetAfterSwap: () => {
      document.alephZeroButtonSwapForm.fromAmount.value = "";
      document.alephZeroButtonSwapForm.estimateAmount.value = "";
      document.alephZeroButtonSwapForm.minAmount.value = "";
      $(FROM_USD_PRICE_SELECTOR).text("");
      $(MIN_ACCEPTABLE_AMOUNT_USD_PRICE_SELECTOR).text("");
      $(TO_USD_PRICE_SELECTOR).text("");
      ALEPH_ZERO.updateWalletBalance(ALEPH_ZERO_BUTTON_SWAP.fromId);
      ALEPH_ZERO.updateWalletBalance(ALEPH_ZERO_BUTTON_SWAP.toId);
    },
    resetBeforeEstimate: (reverse = false) => {
      ALEPH_ZERO_BUTTON_SWAP.aggregator.bestResultsPerProtocol = {};
      ALEPH_ZERO_BUTTON_SWAP.aggregator.selectedSwapPath = undefined;
      // This holds the results of swaps for a pool, for crypto id, for the amount offered
      ALEPH_ZERO_BUTTON_SWAP.aggregator.simulationSwapResults = {};
      ALEPH_ZERO_BUTTON_SWAP.aggregator.swapPaths = {};
      $(FROM_USD_PRICE_SELECTOR).text("");
      $(MIN_ACCEPTABLE_AMOUNT_USD_PRICE_SELECTOR).text("");
      $(TO_USD_PRICE_SELECTOR).text("");
      if (reverse) {
        document.alephZeroButtonSwapForm.fromAmount.value = "";
      } else {
        document.alephZeroButtonSwapForm.estimateAmount.value = "";
        document.alephZeroButtonSwapForm.minAmount.value = "";
      }
    },
    successMessage: function (
      fromAmount,
      toAmount,
      fromCryptocurrency,
      toCryptocurrency
    ) {
      return `${document.humanizeStringNumberFromSmartContract(
        fromAmount,
        fromCryptocurrency.decimals
      )} ${
        fromCryptocurrency.symbol
      } => ${document.humanizeStringNumberFromSmartContract(
        toAmount,
        toCryptocurrency.decimals
      )} ${toCryptocurrency.symbol}`;
    },
  },
};

$(function () {
  if ($("#aleph-zero-button-swap").length) {
    ALEPH_ZERO_BUTTON_SWAP.init();
  }
});
