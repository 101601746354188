// CUSTOM MODULES
import { OSMOSIS } from "./../osmosis/helpers";
import { SECRET_NETWORK } from "./../secret_network/helpers";

$(function () {
  if ($("#arbitrage").length) {
    $("#routes-table").removeClass("table-striped");
    const ARBITRAGE = {
      routes: undefined,
      datatable: $("#routes-table").DataTable({
        columns: [
          {
            data: "blockchain.name",
            title: "Blockchain",
            fnCreatedCell: function (nTd, sData, _oData, _iRow) {
              $(nTd).html(_.startCase(_.toLower(sData)));
            },
          },
          {
            data: "from.symbol",
            title: "From",
            fnCreatedCell: function (nTd, sData, oData, _iRow) {
              $(nTd).html(
                `<div>${sData}</div><div>${document.humanizeStringNumberFromSmartContract(
                  oData.from_amount,
                  0
                )}</div>`
              );
            },
          },
          {
            data: "to.symbol",
            title: "To",
            fnCreatedCell: function (nTd, sData, oData, _iRow) {
              $(nTd).html(
                `<div>${sData}</div><div>${
                  document.humanizeStringNumberFromSmartContract(
                    oData.to_amount,
                    0
                  ) || ""
                }</div>`
              );
            },
          },
          {
            data: "route_finder_template.extra_expense",
            title: "Extra Expense ($)",
            fnCreatedCell: function (nTd, sData, _oData, _iRow) {
              if (sData) {
                $(nTd).html(document.amountToCurrency(sData, 1));
              }
            },
          },
          {
            data: "net_usd_result",
            title: "Net ($)",
            searchable: false,
            fnCreatedCell: function (nTd, sData, oData, _iRow) {
              let html = ARBITRAGE.urlHtml(
                oData,
                document.amountToCurrency(sData, 1)
              );
              if (
                oData.from.secret_network_unwrap ||
                oData.to.secret_network_unwrap
              ) {
                html += `<br>${ARBITRAGE.urlHtml(oData, "native")}`;
              }
              $(nTd).html(html);
            },
          },
          {
            data: "net_osmosis_reverse_result",
            title: "Net Osmosis Reverse Result ($)",
            searchable: false,
            fnCreatedCell: function (nTd, sData, oData, _iRow) {
              let html = "";
              if (sData != 0) {
                let fromSymbol = oData.to.symbol;
                let toSymbol = oData.from.symbol;
                if (oData.to.secret_network_unwrap) {
                  fromSymbol = oData.to.secret_network_unwrap.symbol;
                }
                if (oData.from.secret_network_unwrap) {
                  toSymbol = oData.from.secret_network_unwrap.symbol;
                }
                html = `<a href="https://app.osmosis.zone/?from=${fromSymbol}&to=${toSymbol}" target="_blank">${document.amountToCurrency(
                  sData,
                  1
                )}</a>`;
              }
              $(nTd).html(html);
            },
          },
          {
            data: "result_for_alert",
            title: "Result For Alert ($)",
            searchable: false,
            fnCreatedCell: function (nTd, sData, _oData, _iRow) {
              $(nTd).html(document.amountToCurrency(sData, 1));
            },
          },
          {
            // Using result_for_alert so I can access oData
            data: "route_finder_template_id",
            title: "",
            searchable: false,
            fnCreatedCell: function (nTd, sData, _oData, _iRow) {
              $(nTd).html(
                `<div class="d-flex"><a href="/admin/route_finder_templates/${sData}/edit" target="_blank" class="btn btn-sm btn-secondary">edit</a><button class="btn btn-sm btn-danger reset-button ms-2"><div class="d-none loading"><em aria-hidden="true" class="spinner-grow spinner-grow-sm" role="status"></em><em>Loading...</em></div><div class="ready">reset</div></button></div>`
              );
            },
          },
        ],
        dom: '<"top"i>frtp',
        order: [[6, "desc"]],
        ordering: true,
        paging: false,
        rowId: function (a) {
          return a.id;
        },
        createdRow: function (row, data) {
          if (ARBITRAGE.routeAlertable(data)) {
            $(row).addClass("bg-light-success");
          }
        },
        drawCallback: function () {
          // Set listeners for button after
          document.querySelectorAll(".reset-button").forEach((item) => {
            item.addEventListener("click", async (e) => {
              let $buttonSelector = $(e.target).closest(".reset-button");
              try {
                document.disableButton($buttonSelector);
                await $.ajax({
                  url: `/routes/${$(e.target).closest("tr")[0].id}`,
                  type: "put",
                  data: {
                    route: {
                      status: "cancelled",
                    },
                  },
                  dataType: "json",
                });
                await ARBITRAGE.getAndSetRoutes();
                ARBITRAGE.refreshRoutesInterval();
              } catch (err) {
                document.showAlertDanger(err);
              } finally {
                document.enableButton($buttonSelector);
              }
            });
          });
        },
      }),
      updateBinancePricesInterval: undefined,
      updateRoutesInterval: undefined,
      init: async () => {
        document.activateKeplr();
        ARBITRAGE.refreshRoutesInterval();
        ARBITRAGE.getAndSetRoutes();
        $("#play-sound").on("click", function (e) {
          e.preventDefault();
          $("#play-sound").addClass("d-none");
          $("#bird-audio").removeClass("d-none");
        });
        document.arbitrageBridgeForm.onsubmit = async (e) => {
          e.preventDefault();
          document.disableButton(
            'form[name="arbitrageBridgeForm"] button[type="submit"]'
          );
          let amount = Number(document.arbitrageBridgeForm.amount.value);
          try {
            if (amount <= 0) {
              throw "Amount must be greater than 0.";
            }

            SECRET_NETWORK.ibcTransfer(
              "osmo1a7nnjtqhsc2vsy87vle6qjhrlhc5lnqe629c55",
              1_000_000,
              "OSMO"
            );
            document.showAlertSuccess("Bridge successful");
          } catch (error) {
            document.showAlertDanger(error);
          } finally {
            document.enableButton(
              'form[name="arbitrageBridgeForm"] button[type="submit"]'
            );
          }
        };
      },
      drawTableAndPlaySound: () => {
        let playSound = false;
        ARBITRAGE.routes.forEach(function (route) {
          if (!route.net_osmosis_reverse_result) {
            route.net_osmosis_reverse_result = 0;
          }
          if (route.route_finder_template.osmosis_reverse_result_for_alert) {
            route.result_for_alert = route.net_osmosis_reverse_result;
          } else {
            route.result_for_alert = route.net_usd_result;
          }
          if (ARBITRAGE.routeAlertable(route)) {
            playSound = true;
          }
        });
        ARBITRAGE.datatable.clear();
        ARBITRAGE.datatable.rows.add(ARBITRAGE.routes);
        ARBITRAGE.datatable.columns.adjust().draw();
        if (playSound && !$("#bird-audio").hasClass("d-none")) {
          $("#bird-audio audio")[0].play();
        }
      },
      getAndSetRoutes: async () => {
        let blockchainStats = {};
        let response = await $.ajax({
          url: `/routes?arbitrage_opportunity=true&status=open,processing`,
          dataType: "json",
        });
        ARBITRAGE.routes = response.filter(
          (route) => route.net_usd_result != undefined
        );
        ARBITRAGE.routes = response.filter((route) => route.status == "open");
        let someArbitrageOpportuniesNotUpdatedRecently = false;
        let now = new Date();
        let tenMinutesAgo = now.setMinutes(now.getMinutes() - 10);
        for (const route of ARBITRAGE.routes) {
          if (!blockchainStats[route.blockchain.identifier]) {
            blockchainStats[route.blockchain.identifier] = {
              count: 0,
              staleCount: 0,
            };
          }
          blockchainStats[route.blockchain.identifier].count += 1;
          if (new Date(route.updated_at) < tenMinutesAgo) {
            blockchainStats[route.blockchain.identifier].staleCount += 1;
            someArbitrageOpportuniesNotUpdatedRecently = true;
          }
          // Set net osmosis reverse result
          if (
            Number(route.net_usd_result) > 0 &&
            route.to.secret_network_unwrap_osmosis_denom &&
            route.from.secret_network_unwrap_osmosis_denom &&
            route.from.id != route.to.id
          ) {
            let result = await $.ajax({
              url: `https://sqs.osmosis.zone/router/quote?tokenIn=${route.to_amount_as_integer_string}${route.to.secret_network_unwrap_osmosis_denom}&tokenOutDenom=${route.from.secret_network_unwrap_osmosis_denom}`,
              type: "get",
            });
            let amountWithDecimals = document.applyDecimals(
              result.amount_out,
              route.from.decimals
            );
            route.net_osmosis_reverse_result =
              (amountWithDecimals - Number(route.from_amount)) *
              route.from.price;
          }
        }
        if (someArbitrageOpportuniesNotUpdatedRecently) {
          $(".alert-info").removeClass("d-none");
          let warningMessage = "";
          Object.keys(blockchainStats).forEach((key) => {
            if (blockchainStats[key].staleCount > 0) {
              if (warningMessage.length) {
                warningMessage += ` & `;
              }
              warningMessage += `${blockchainStats[key].staleCount}/${blockchainStats[key].count} ${key} routes`;
            }
          });
          warningMessage += " have not been updated in the last 10 minutes.";
          $(".alert-info .alert-body").text(warningMessage);
        } else {
          $(".alert-info").addClass("d-none");
        }
        ARBITRAGE.refreshBinancePricesInterval();
        ARBITRAGE.updateBinancePrices();
      },
      refreshBinancePricesInterval: function () {
        clearInterval(ARBITRAGE.updateBinancePricesInterval);
        ARBITRAGE.updateBinancePricesInterval = setInterval(function () {
          ARBITRAGE.updateBinancePrices();
        }, 15_000);
      },
      refreshRoutesInterval: () => {
        clearInterval(ARBITRAGE.updateRoutesInterval);
        ARBITRAGE.updateRoutesInterval = setInterval(function () {
          ARBITRAGE.getAndSetRoutes();
        }, 60_000);
      },
      routeAlertable: (route) => {
        let alertable = false;
        if (
          Number(route.result_for_alert) >=
          Number(route.route_finder_template.alert_usd_result)
        ) {
          alertable = true;
        }
        return alertable;
      },
      updateBinancePrices: async () => {
        let binancePriceSymbols = [];
        ARBITRAGE.routes.forEach(function (v, _k) {
          if (
            v.from.binance_price_symbol &&
            !binancePriceSymbols.includes(v.from.binance_price_symbol)
          ) {
            binancePriceSymbols.push(v.from.binance_price_symbol);
          }
          if (
            v.to.binance_price_symbol &&
            !binancePriceSymbols.includes(v.to.binance_price_symbol)
          ) {
            binancePriceSymbols.push(v.to.binance_price_symbol);
          }
        });
        let string = "";
        binancePriceSymbols.forEach(function (v, i) {
          string += `"${v}"`;
          if (i < binancePriceSymbols.length - 1) {
            string += ",";
          }
        });
        if ("".length) {
          let prices = await $.ajax({
            url: `https://binance-steven.butthub.workers.dev/binance/api/v3/ticker/price?symbols=[${string}]`,
            type: "get",
          });
          prices.forEach(
            function (value) {
              ARBITRAGE.routes.forEach(function (route, k) {
                if (route.from.binance_price_symbol == value.symbol) {
                  route.from.price = value.price;
                }
                if (route.to.binance_price_symbol == value.symbol) {
                  route.to.price = value.price;
                }
              });
            }.bind(this)
          );
          ARBITRAGE.routes.forEach(function (route, _k) {
            if (
              route.from.binance_price_symbol &&
              route.to.binance_price_symbol &&
              route.from_amount &&
              route.to_amount
            ) {
              let bestFromAmountAsUsd = BigNumber(route.from.price).times(
                BigNumber(route.from_amount)
              );
              let bestToAmountAsUsd = BigNumber(route.to.price).times(
                BigNumber(route.to_amount)
              );
              route.net_usd_result = bestToAmountAsUsd
                .minus(bestFromAmountAsUsd)
                .toFixed(2);
            }
          });
        }
        ARBITRAGE.drawTableAndPlaySound();
      },
      urlHtml: (route, label) => {
        let html;
        let fromSymbol = route.from.symbol;
        let toSymbol = route.to.symbol;
        if (label == "native") {
          if (route.from.secret_network_unwrap) {
            fromSymbol = route.from.secret_network_unwrap.symbol;
          }
          if (route.to.secret_network_unwrap) {
            toSymbol = route.to.secret_network_unwrap.symbol;
          }
        }
        if (route.blockchain_id == 1) {
          html = `<a href='/secret_network/button_swap?slippage_tolerance=0.2&from=${fromSymbol}&to=${toSymbol}&from_amount=${route.from_amount}' target='_blank'>${label}</a>`;
        } else if (route.blockchain_id == 4) {
          html = `<a href='https://app.osmosis.zone/?from=${fromSymbol}&to=${toSymbol}' target='_blank'>${label}</a>`;
        }
        return html;
      },
    };
    ARBITRAGE.init();
  }
});
