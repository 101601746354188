// CUSTOM
document.setTokenButton = function (
  selector,
  symbol,
  cloudinaryPublicId = "external-content.duckduckgo-1_memqe7"
) {
  $(selector).find(".token-symbol").text(symbol);
  $(selector)
    .find("img")
    .attr(
      "src",
      `https://res.cloudinary.com/hv5cxagki/image/upload/c_scale,f_auto,h_50,q_100,w_50/v1/${cloudinaryPublicId}`
    );
};

document.setTokenButtonWithId = function (
  selector,
  tokenId,
  cryptocurrenciesObject
) {
  let cryptocurrency = cryptocurrenciesObject[tokenId];
  let cloudinaryPublicId;
  if (cryptocurrency.attachments[0]) {
    cloudinaryPublicId = cryptocurrency.attachments[0].cloudinary_public_id;
  } else {
    cloudinaryPublicId = "external-content.duckduckgo-1_memqe7";
  }
  document.setTokenButton(selector, cryptocurrency.symbol, cloudinaryPublicId);
};
