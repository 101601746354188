// CUSTOM
import { HELPERS } from "./../../application";
import {
  SN_B3_ADDRESS,
  SN_BUTT_ADDRESS,
  SN_BUTT_DATA_HASH,
  SN_BUTT_ID,
  SN_LIMIT_ORDERS_ADDRESS,
  SN_LIMIT_ORDERS_DATA_HASH,
  VIP_LEVELS,
} from "./constants";
import { SECRET_NETWORK } from "./helpers";

$(function () {
  if ($("#secret-network-button-swap").length) {
    // === CONSTANTS ===
    const PROTOCOLS = {
      0: "btn.group",
      2: "Secret Swap",
      4: "Sienna",
      39: "Shade Protocol",
    };
    const RESULTS_SELECTOR = "#results";

    // secretNetworkDexAggregatorForm
    const FEE_CARD_SELECTOR = "#fee-card";
    const FROM_AMOUNT_INPUT_SELECTOR =
      'form[name="secretNetworkDexAggregatorForm"] input[name="fromAmount"]';
    const FROM_BALANCE_CONTAINER_SELECTOR = "#from-balance-container";
    const FROM_TOKEN_BUTTON_SELECTOR = "#from-token-button";
    const FROM_USD_PRICE_SELECTOR = "#from-usd-price";
    const MIN_ACCEPTABLE_AMOUNT_USD_PRICE_SELECTOR =
      "#min-acceptable-amount-usd-price";
    const SLIPPAGE_TOLERANCE_INPUT_SELECTOR = "#slippage-tolerance";
    const SUBMIT_BUTTON_SELECTOR =
      'form[name="secretNetworkDexAggregatorForm"] button[type="submit"]';
    const TO_AMOUNT_INPUT_SELECTOR =
      'form[name="secretNetworkDexAggregatorForm"] input[name="estimateAmount"]';
    const TO_BALANCE_CONTAINER_SELECTOR = "#to-balance-container";
    const TO_TOKEN_BUTTON_SELECTOR = "#to-token-button";
    const TO_USD_PRICE_SELECTOR = "#to-usd-price";

    // secretNetworkLimitForm
    const LIMIT_FROM_BALANCE_CONTAINER_SELECTOR =
      "#limit-from-balance-container";
    const LIMIT_FROM_AMOUNT_INPUT_SELECTOR =
      'form[name="secretNetworkLimitForm"] input[name="fromAmount"]';
    const LIMIT_FROM_TOKEN_BUTTON_SELECTOR = "#limit-from-token-button";
    const LIMIT_FROM_USD_PRICE_SELECTOR = "#limit-from-usd-price";
    const LIMIT_PRICE_INPUT_SELECTOR =
      'form[name="secretNetworkLimitForm"] input[name="price"]';
    const LIMIT_SUBMIT_BUTTON_SELECTOR =
      'form[name="secretNetworkLimitForm"] button[type="submit"]';
    const LIMIT_TO_AMOUNT_INPUT_SELECTOR =
      'form[name="secretNetworkLimitForm"] input[name="toAmount"]';
    const LIMIT_TO_BALANCE_CONTAINER_SELECTOR = "#limit-to-balance-container";
    const LIMIT_TO_TOKEN_BUTTON_SELECTOR = "#limit-to-token-button";
    const LIMIT_TO_USD_PRICE_SELECTOR = "#limit-to-usd-price";

    // Pools
    const POOL_BUTTON_SELECTOR = ".pool-button";

    // secretNetworkProvideForm
    const PROVIDE_FROM_AMOUNT_INPUT_SELECTOR =
      'form[name="secretNetworkProvideForm"] input[name="fromAmount"]';
    const PROVIDE_TO_AMOUNT_INPUT_SELECTOR =
      'form[name="secretNetworkProvideForm"] input[name="toAmount"]';
    const PROVIDE_FROM_TOKEN_BUTTON_SELECTOR = "#provide-from-token-button";
    const PROVIDE_TO_TOKEN_BUTTON_SELECTOR = "#provide-to-token-button";
    const PROVIDE_FROM_BALANCE_CONTAINER_SELECTOR =
      "#provide-from-balance-container";
    const PROVIDE_TO_BALANCE_CONTAINER_SELECTOR =
      "#provide-to-balance-container";
    const PROVIDE_FROM_USD_PRICE_SELECTOR = "#provide-from-usd-price";
    const PROVIDE_TO_USD_PRICE_SELECTOR = "#provide-to-usd-price";
    const PROVIDE_FORM_SUBMIT_BUTTON_SELECTOR =
      'form[name="secretNetworkProvideForm"] button[type="submit"]';

    // secretNetworkWithdrawForm
    const WITHDRAW_BALANCE_CONTAINER_SELECTOR = "#withdraw-balance-container";
    const WITHDRAW_TOKEN_BUTTON_SELECTOR = "#withdraw-token-button";
    const WITHDRAW_FORM_SUBMIT_BUTTON_SELECTOR =
      'form[name="secretNetworkWithdrawForm"] button[type="submit"]';

    // === LISTENERS ===
    $("#pay-fees-with-butt").on(
      "change",
      function () {
        SECRET_NETWORK.buttonSwap.aggregator.displayFee();
        // Toggle estimate amount
        this.fillForm(SECRET_NETWORK.buttonSwap.aggregator.queryCount);
      }.bind(this)
    );

    $(".flip-token").on("click", function (event) {
      event.preventDefault();
      SECRET_NETWORK.buttonSwap.changeCryptocurrencies(
        SECRET_NETWORK.buttonSwap.toId,
        SECRET_NETWORK.buttonSwap.fromId
      );
    });

    $(FROM_AMOUNT_INPUT_SELECTOR).on("input", async () => {
      let amount = BigNumber($(FROM_AMOUNT_INPUT_SELECTOR).val());
      let cryptoDecimals =
        SECRET_NETWORK.cryptocurrencies[SECRET_NETWORK.buttonSwap.fromId]
          .decimals;
      if (amount.decimalPlaces() > cryptoDecimals) {
        $(FROM_AMOUNT_INPUT_SELECTOR).val(amount.dp(cryptoDecimals));
      }
      await SECRET_NETWORK.buttonSwap.aggregator.getEstimate(false);
    });

    $(TO_AMOUNT_INPUT_SELECTOR).on("input", async () => {
      let amount = BigNumber($(TO_AMOUNT_INPUT_SELECTOR).val());
      let cryptoDecimals =
        SECRET_NETWORK.cryptocurrencies[SECRET_NETWORK.buttonSwap.toId]
          .decimals;
      if (amount.decimalPlaces() > cryptoDecimals) {
        $(TO_AMOUNT_INPUT_SELECTOR).val(
          amount.dp(cryptoDecimals, BigNumber.ROUND_DOWN)
        );
      }
      await SECRET_NETWORK.buttonSwap.aggregator.getEstimate(true);
    });

    $.each(
      [
        FROM_TOKEN_BUTTON_SELECTOR,
        TO_TOKEN_BUTTON_SELECTOR,
        LIMIT_FROM_TOKEN_BUTTON_SELECTOR,
        LIMIT_TO_TOKEN_BUTTON_SELECTOR,
        POOL_BUTTON_SELECTOR,
        PROVIDE_FROM_TOKEN_BUTTON_SELECTOR,
        PROVIDE_TO_TOKEN_BUTTON_SELECTOR,
      ],
      function (_index, value) {
        $(value).on(
          "click",
          function (e) {
            if (e.target && e.target.href && e.target.href == "#") {
              e.preventDefault();
            }
            setTimeout(function () {
              if (
                [
                  POOL_BUTTON_SELECTOR,
                  PROVIDE_FROM_TOKEN_BUTTON_SELECTOR,
                  PROVIDE_TO_TOKEN_BUTTON_SELECTOR,
                ].includes(value)
              ) {
                $("#secret-network-pools-input").trigger("focus");
              } else {
                $("#input-text-2").trigger("focus");
              }
            }, 500);
            SECRET_NETWORK.buttonSwap.tokenModalFor = value;
          }.bind(this)
        );
      }.bind(this)
    );

    $(SLIPPAGE_TOLERANCE_INPUT_SELECTOR).on("input", () => {
      let estimateAmount = BigNumber(
        document.secretNetworkDexAggregatorForm.estimateAmount.value
      );
      if (estimateAmount.isPositive()) {
        let toCryptocurrencyDecimals =
          SECRET_NETWORK.cryptocurrencies[SECRET_NETWORK.buttonSwap.toId]
            .decimals;
        let slippage = BigNumber(
          document.secretNetworkDexAggregatorForm.slippageTolerance.value
        )
          .times(estimateAmount)
          .dividedBy(100);
        let minAmount = estimateAmount
          .minus(slippage)
          .dp(toCryptocurrencyDecimals);
        document.secretNetworkDexAggregatorForm.minAmount.value = minAmount;
        SECRET_NETWORK.setUsdTextFromAmount(
          minAmount,
          SECRET_NETWORK.buttonSwap.toId,
          MIN_ACCEPTABLE_AMOUNT_USD_PRICE_SELECTOR
        );
      }
    });

    // === FUNCTIONS ===
    this.fillForm = (currentQueryCount) => {
      if (
        currentQueryCount == SECRET_NETWORK.buttonSwap.aggregator.queryCount &&
        SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath
      ) {
        let toCryptocurrency =
          SECRET_NETWORK.cryptocurrencies[
            SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath.to_id
          ];
        if (
          document.secretNetworkDexAggregatorForm.payFeesWithButt.checked &&
          SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath
            .resultOfSwapsWhenFeesPaidWithButt
        ) {
          document.secretNetworkDexAggregatorForm.estimateAmount.value =
            BigNumber(
              SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath
                .resultOfSwapsWhenFeesPaidWithButt
            ).shiftedBy(toCryptocurrency.decimals * -1);
        } else {
          document.secretNetworkDexAggregatorForm.estimateAmount.value =
            BigNumber(
              SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath
                .resultOfSwaps
            ).shiftedBy(toCryptocurrency.decimals * -1);
        }
        SECRET_NETWORK.setUsdTextFromAmount(
          document.secretNetworkDexAggregatorForm.estimateAmount.value,
          toCryptocurrency.id,
          TO_USD_PRICE_SELECTOR
        );
        $(SLIPPAGE_TOLERANCE_INPUT_SELECTOR).trigger("input");
      }
    };

    SECRET_NETWORK.buttonSwap = {
      fromId: $(FROM_TOKEN_BUTTON_SELECTOR).data("initialId"),
      toId: $(TO_TOKEN_BUTTON_SELECTOR).data("initialId"),
      tokenModalFor: undefined,
      init: async () => {
        SECRET_NETWORK.buttonSwap.addListeners();
        SECRET_NETWORK.getAndSetCryptocurrencies(
          "blockchain_id=1&nft=false&include_attachments=true",
          true
        );
        SECRET_NETWORK.getAndSetTradePairsAndWrapPaths();
        // Wait for both
        while (
          Object.keys(SECRET_NETWORK.cryptocurrencies).length == 0 ||
          Object.keys(SECRET_NETWORK.tradePairs).length == 0
        ) {
          await document.delay(1_000);
        }

        // === DATATABLE ===
        SECRET_NETWORK.buttonSwap.aggregator.bestResultsPerProtocolTable = $(
          "#results-by-protocol-table"
        ).DataTable({
          columns: [
            {
              data: "dataTable.protocolName",
              title: "Protocol",
              defaultContent: "-",
            },
            {
              data: "dataTable.resultOfSwaps",
              title: "Return Amount",
              defaultContent: "-",
            },
            {
              data: "dataTable.gasAsUsd",
              title: "Gas ($)",
              defaultContent: "-",
            },
            {
              data: "dataTable.netUsdResultOfSwaps",
              title: "Net ($)",
              defaultContent: "-",
            },
          ],
          info: false,
          ordering: false,
          paging: false,
          rowId: function (a) {
            return `results-by-protocol-row-${a.protocol_id}`;
          },
          drawCallback: function () {
            if (SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath) {
              let protocolId =
                SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath
                  .protocol_id;
              $(`#results-by-protocol-row-${protocolId}`).addClass(
                "text-success"
              );
              $(`#results-by-protocol-row-${protocolId}`).addClass("fw-bold");
            }
          },
        });

        // === LIST ===
        HELPERS.initTokenLists(["hacker-list"]);

        if (Number($(FROM_AMOUNT_INPUT_SELECTOR).val() > 0)) {
          $(FROM_AMOUNT_INPUT_SELECTOR).trigger("input");
        }
        $("#tab-swap .loading-container").addClass("d-none");
        $("#tab-swap .after-loaded-contents").removeClass("d-none");
        // Refresh every 30 minutes so that the price gets updated every 30 minutes
        setInterval(function () {
          SECRET_NETWORK.getAndSetCryptocurrencies(
            "blockchain_id=1&nft=false&include_attachments=true",
            true
          );
          SECRET_NETWORK.getAndSetTradePairsAndWrapPaths();
        }, 60_000 * 30);
      },
      addListeners: function () {
        $('a[href="#tab-swap"]').on("click", function () {
          // containers
          $("#tab-pools").removeClass("active");
          $("#tab-swap").addClass("active");
          // tabs
          $('a[href="#tab-swap"]').addClass("active");
          $('a[href="#tab-pools"]').removeClass("active");
        });
        $('a[href="#tab-pools"]').on("click", function () {
          SECRET_NETWORK.buttonSwap.pools.init();
          // containers
          $("#tab-pools").addClass("active");
          $("#tab-swap").removeClass("active");
          // tabs
          $('a[href="#tab-swap"]').removeClass("active");
          $('a[href="#tab-pools"]').addClass("active");
        });
        $('a[href="#tab-market"]').on("click", function () {
          // containers
          $(".tab-market").addClass("active");
          $(".tab-limit").removeClass("active");
          // tabs
          $('a[href="#tab-market"]').addClass("active");
          $('a[href="#tab-limit"]').removeClass("active");
        });
        $('a[href="#tab-limit"]').on("click", function () {
          // containers
          $(".tab-market").removeClass("active");
          $(".tab-limit").addClass("active");
          // tabs
          $('a[href="#tab-limit"]').addClass("active");
          $('a[href="#tab-market"]').removeClass("active");
        });

        $(document).on("keplr_connected", async () => {
          $(".balance-container").removeClass("d-none");
          while (
            !SECRET_NETWORK.cryptocurrencies[SECRET_NETWORK.buttonSwap.fromId]
          ) {
            await document.delay(1_000);
          }
          $(".user-balance-container .bi-arrow-repeat").trigger("click");
        });

        HELPERS.listeners.listenForTokenSelect(SECRET_NETWORK.buttonSwap);
      },
      changeCryptocurrencies: async (fromId = undefined, toId = undefined) => {
        let comboChanged = false;
        let refreshSwapPaths = true;
        if (fromId) {
          let previousFromId = SECRET_NETWORK.buttonSwap.fromId;
          if (Number(previousFromId) != Number(fromId)) {
            comboChanged = true;
            SECRET_NETWORK.buttonSwap.fromId = fromId;
            // LIMIT
            $(LIMIT_FROM_BALANCE_CONTAINER_SELECTOR).attr(
              "data-cryptocurrency-id",
              fromId
            );
            $(LIMIT_FROM_BALANCE_CONTAINER_SELECTOR)
              .find(".balance-view-button")
              .attr("data-cryptocurrency-id", fromId);
            SECRET_NETWORK.buttonSwap.limit.updatePriceInput(
              SECRET_NETWORK.buttonSwap.fromId,
              SECRET_NETWORK.buttonSwap.toId
            );
            document.setTokenButtonWithId(
              LIMIT_FROM_TOKEN_BUTTON_SELECTOR,
              SECRET_NETWORK.buttonSwap.fromId,
              SECRET_NETWORK.cryptocurrencies
            );

            // MARKET
            $(FROM_BALANCE_CONTAINER_SELECTOR).attr(
              "data-cryptocurrency-id",
              fromId
            );
            $(FROM_BALANCE_CONTAINER_SELECTOR)
              .find(".balance-view-button")
              .attr("data-cryptocurrency-id", fromId);
            document.setTokenButtonWithId(
              FROM_TOKEN_BUTTON_SELECTOR,
              SECRET_NETWORK.buttonSwap.fromId,
              SECRET_NETWORK.cryptocurrencies
            );

            // WHAT ARE WE ACTUALLY DOING HERE?
            // This is so that we don't have to get swap paths again when switching from token to it's un/wrapped version
            if (
              SECRET_NETWORK.buttonSwap.aggregator.swapPaths[previousFromId] &&
              !SECRET_NETWORK.oneForOneSwap(
                SECRET_NETWORK.buttonSwap.fromId,
                SECRET_NETWORK.buttonSwap.toId
              )
            ) {
              if (
                SECRET_NETWORK.wrapPaths[previousFromId] &&
                SECRET_NETWORK.wrapPaths[previousFromId].includes(
                  SECRET_NETWORK.buttonSwap.fromId
                ) &&
                SECRET_NETWORK.wrapPaths[previousFromId].length == 1 &&
                SECRET_NETWORK.wrapPaths[SECRET_NETWORK.buttonSwap.fromId]
                  .length == 1
              ) {
                SECRET_NETWORK.buttonSwap.aggregator.swapPaths[
                  SECRET_NETWORK.buttonSwap.fromId
                ] = {};
                SECRET_NETWORK.buttonSwap.aggregator.swapPaths[
                  SECRET_NETWORK.buttonSwap.fromId
                ][SECRET_NETWORK.buttonSwap.toId] =
                  SECRET_NETWORK.buttonSwap.aggregator.swapPaths[
                    previousFromId
                  ][SECRET_NETWORK.buttonSwap.toId];
                refreshSwapPaths = false;
              }
            } else {
              document.secretNetworkDexAggregatorForm.fromAmount.value = "";
              $(FROM_USD_PRICE_SELECTOR).text("");
            }

            // Balance
            SECRET_NETWORK.updateWalletBalance(
              SECRET_NETWORK.buttonSwap.fromId
            );
          }
        }

        if (toId) {
          let previousToId = SECRET_NETWORK.buttonSwap.toId;
          if (Number(previousToId) != Number(toId)) {
            comboChanged = true;
            SECRET_NETWORK.buttonSwap.toId = toId;
            // limit
            $(LIMIT_TO_BALANCE_CONTAINER_SELECTOR).attr(
              "data-cryptocurrency-id",
              toId
            );
            $(LIMIT_TO_BALANCE_CONTAINER_SELECTOR)
              .find(".balance-view-button")
              .attr("data-cryptocurrency-id", toId);
            SECRET_NETWORK.buttonSwap.limit.updatePriceInput(
              SECRET_NETWORK.buttonSwap.fromId,
              SECRET_NETWORK.buttonSwap.toId
            );
            document.setTokenButtonWithId(
              LIMIT_TO_TOKEN_BUTTON_SELECTOR,
              SECRET_NETWORK.buttonSwap.toId,
              SECRET_NETWORK.cryptocurrencies
            );
            $(LIMIT_SUBMIT_BUTTON_SELECTOR)
              .find(".ready")
              .text(
                `Buy ${
                  SECRET_NETWORK.cryptocurrencies[
                    SECRET_NETWORK.buttonSwap.toId
                  ].symbol
                }`
              );

            // market
            $(TO_BALANCE_CONTAINER_SELECTOR).attr(
              "data-cryptocurrency-id",
              toId
            );
            $(TO_BALANCE_CONTAINER_SELECTOR)
              .find(".balance-view-button")
              .attr("data-cryptocurrency-id", toId);
            document.setTokenButtonWithId(
              TO_TOKEN_BUTTON_SELECTOR,
              SECRET_NETWORK.buttonSwap.toId,
              SECRET_NETWORK.cryptocurrencies
            );

            // WHAT ARE WE ACTUALLY DOING HERE?
            // This is so that we don't have to get swap paths again when switching to token to it's un/wrapped version
            if (
              SECRET_NETWORK.buttonSwap.aggregator.swapPaths[
                SECRET_NETWORK.buttonSwap.fromId
              ] &&
              !SECRET_NETWORK.oneForOneSwap(
                SECRET_NETWORK.buttonSwap.fromId,
                SECRET_NETWORK.buttonSwap.toId
              )
            ) {
              if (
                SECRET_NETWORK.wrapPaths[previousToId] &&
                SECRET_NETWORK.wrapPaths[previousToId].includes(
                  SECRET_NETWORK.buttonSwap.toId
                )
              ) {
                if (
                  SECRET_NETWORK.wrapPaths[previousToId].length == 1 &&
                  SECRET_NETWORK.wrapPaths[SECRET_NETWORK.buttonSwap.toId]
                    .length == 1
                ) {
                  SECRET_NETWORK.buttonSwap.aggregator.swapPaths[
                    SECRET_NETWORK.buttonSwap.fromId
                  ][SECRET_NETWORK.buttonSwap.toId] =
                    SECRET_NETWORK.buttonSwap.aggregator.swapPaths[
                      SECRET_NETWORK.buttonSwap.fromId
                    ][previousToId];
                  refreshSwapPaths = false;
                }
              }
            }
            $(SUBMIT_BUTTON_SELECTOR)
              .find(".ready")
              .text(
                `Buy ${
                  SECRET_NETWORK.cryptocurrencies[
                    SECRET_NETWORK.buttonSwap.toId
                  ].symbol
                }`
              );

            // balance
            SECRET_NETWORK.updateWalletBalance(SECRET_NETWORK.buttonSwap.toId);
          }
        }
        if (comboChanged) {
          SECRET_NETWORK.buttonSwap.limit.resetForm();
          if (refreshSwapPaths) {
            this.toggleConfig();
            SECRET_NETWORK.buttonSwap.aggregator.getEstimate();
          }
          $(LIMIT_PRICE_INPUT_SELECTOR).prop(
            "disabled",
            SECRET_NETWORK.oneForOneSwap(
              SECRET_NETWORK.buttonSwap.fromId,
              SECRET_NETWORK.buttonSwap.toId
            )
          );
          if (
            SECRET_NETWORK.oneForOneSwap(
              SECRET_NETWORK.buttonSwap.fromId,
              SECRET_NETWORK.buttonSwap.toId
            )
          ) {
            $("#limit-execution-fee").addClass("d-none");
          } else {
            $("#limit-execution-fee").removeClass("d-none");
          }
        }
      },
      updateAfterTokenSelect: async (event) => {
        let newCryptocurrencyId = event.currentTarget.dataset.cryptocurrencyId;
        if (
          [
            FROM_TOKEN_BUTTON_SELECTOR,
            LIMIT_FROM_TOKEN_BUTTON_SELECTOR,
          ].includes(SECRET_NETWORK.buttonSwap.tokenModalFor)
        ) {
          await SECRET_NETWORK.buttonSwap.changeCryptocurrencies(
            newCryptocurrencyId
          );
        } else if (
          [TO_TOKEN_BUTTON_SELECTOR, LIMIT_TO_TOKEN_BUTTON_SELECTOR].includes(
            SECRET_NETWORK.buttonSwap.tokenModalFor
          )
        ) {
          await SECRET_NETWORK.buttonSwap.changeCryptocurrencies(
            undefined,
            newCryptocurrencyId
          );
        }
      },
    };

    this.toggleConfig = async () => {
      if (
        SECRET_NETWORK.oneForOneSwap(
          SECRET_NETWORK.buttonSwap.fromId,
          SECRET_NETWORK.buttonSwap.toId
        )
      ) {
        $("#cog-container").addClass("d-none");
        $("#slippage-container").addClass("d-none");
      } else {
        $("#cog-container").removeClass("d-none");
        $("#slippage-container").removeClass("d-none");
      }
    };

    document.secretNetworkDexAggregatorForm.onsubmit = async (e) => {
      e.preventDefault();
      let fromId = SECRET_NETWORK.buttonSwap.fromId;
      let toId = SECRET_NETWORK.buttonSwap.toId;
      let selectedSwapPath =
        SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath;
      if (!SECRET_NETWORK.oneForOneSwap(fromId, toId) && !selectedSwapPath) {
        return;
      }

      SECRET_NETWORK.buttonSwap.aggregator.processingTransaction = true;
      let fromCryptocurrency = SECRET_NETWORK.cryptocurrencies[fromId];
      let toCryptocurrency = SECRET_NETWORK.cryptocurrencies[toId];
      let fromAmount = document.secretNetworkDexAggregatorForm.fromAmount.value;
      let estimateAmount =
        document.secretNetworkDexAggregatorForm.estimateAmount.value;
      fromAmount = document.formatHumanizedNumberForSmartContract(
        fromAmount,
        fromCryptocurrency.decimals
      );
      estimateAmount = document.formatHumanizedNumberForSmartContract(
        estimateAmount,
        toCryptocurrency.decimals
      );
      document.disableButton(SUBMIT_BUTTON_SELECTOR);
      try {
        if (Number(estimateAmount) <= 0) {
          throw "Estimate amount must be greater than 0. Please try again.";
        }
        if (
          toCryptocurrency.symbol == "BUTT" &&
          new Date() >= new Date("2024-09-19T00:00:00.000Z")
        ) {
          throw "Swap to BUTT is now disabled.";
        }

        await document.connectKeplrWallet(true);
        if (SECRET_NETWORK.walletAddress) {
          let swapParams = SECRET_NETWORK.getSwapParams(
            fromId,
            toId,
            fromAmount,
            estimateAmount,
            document.secretNetworkDexAggregatorForm.minAmount.value,
            selectedSwapPath
          );

          // Send butt to butt lode if payFeesWithButt is checked, to token is not BUTT
          // and feesWhenPaidWithButt > 0
          if (
            document.secretNetworkDexAggregatorForm.payFeesWithButt.checked &&
            toCryptocurrency.symbol != "BUTT" &&
            Number(selectedSwapPath.feeAsButt) > 0
          ) {
            swapParams.messages.unshift(
              new MsgExecuteContract({
                sender: SECRET_NETWORK.walletAddress,
                contract_address: SN_BUTT_ADDRESS,
                code_hash: SN_BUTT_DATA_HASH,
                msg: {
                  transfer: {
                    recipient: SN_B3_ADDRESS,
                    amount: String(selectedSwapPath.feeAsButt),
                  },
                },
              })
            );
          }
          let response = await SECRET_NETWORK.broadcast(
            swapParams.messages,
            swapParams.gas,
            "production",
            1
          );
          SECRET_NETWORK.buttonSwap.aggregator.resetAfterSwap();
          document.showAlertSuccess(
            SECRET_NETWORK.buttonSwap.aggregator.successMessage(
              fromAmount,
              response,
              fromCryptocurrency,
              toCryptocurrency
            )
          );
        }
      } catch (error) {
        if (error.msg && error.msg.includes("minimum_acceptable_amount")) {
          setTimeout(function () {
            $(FROM_AMOUNT_INPUT_SELECTOR).trigger("input");
          }, 500);
        }
        document.showAlertDanger(error);
      } finally {
        document.enableButton(SUBMIT_BUTTON_SELECTOR);
        SECRET_NETWORK.buttonSwap.aggregator.processingTransaction = false;
      }
    };

    SECRET_NETWORK.buttonSwap.aggregator = {
      bestResultsPerProtocol: {},
      selectedSwapPath: undefined,
      bestResultsPerProtocolTable: undefined,
      processingTransaction: false,
      queryCount: 0,
      queryCountStats: {
        swapPathsCount: 0,
        swapPathsSimulated: 0,
      },
      simulationSwapResults: {},
      swapPaths: {},
      applyFee: () => {
        if (SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath) {
          let toCryptocurrency =
            SECRET_NETWORK.cryptocurrencies[
              SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath.to_id
            ];
          SECRET_NETWORK.buttonSwap.aggregator.setFeeForSelectedSwapPath();
          SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath.feeAsUsd =
            BigNumber(
              document.applyDecimals(
                SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath.fee,
                toCryptocurrency.decimals
              )
            ).times(BigNumber(toCryptocurrency.price));
          SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath.feeAsButt;
          SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath.feeAsButtAsUsd;
          SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath
            .netUsdResultOfSwapsWhenFeesPaidWithButt;
          SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath
            .resultOfSwapsWhenFeesPaidWithButt;
          SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath.resultOfSwaps;
          if (toCryptocurrency.symbol == "BUTT") {
            SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath.feeAsButtAsUsd =
              SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath.feeAsUsd;
            SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath.resultOfSwapsWhenFeesPaidWithButt =
              Math.round(
                SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath
                  .resultOfSwaps -
                  SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath.fee
              );
            SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath.feeAsButt =
              SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath.fee;
          } else {
            SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath.feeAsButtAsUsd =
              SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath.feeAsUsd
                .times(75)
                .dividedBy(100);
            SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath.resultOfSwapsWhenFeesPaidWithButt =
              Math.round(
                SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath
                  .resultOfSwaps
              );
            SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath.feeAsButt =
              Math.round(
                SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath.feeAsButtAsUsd
                  .dividedBy(
                    BigNumber(SECRET_NETWORK.cryptocurrencies[SN_BUTT_ID].price)
                  )
                  .times(1_000_000)
              );
          }
          SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath.resultOfSwaps =
            Math.round(
              SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath
                .resultOfSwaps -
                SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath.fee
            );

          SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath.netUsdResultOfSwaps =
            HELPERS.swapPaths.netUsdResultOfSwaps(
              SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath,
              SECRET_NETWORK.cryptocurrencies
            );
          if (toCryptocurrency.symbol == "BUTT") {
            SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath.netUsdResultOfSwapsWhenFeesPaidWithButt =
              SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath.netUsdResultOfSwaps;
          } else {
            SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath.netUsdResultOfSwapsWhenFeesPaidWithButt =
              BigNumber(
                SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath
                  .resultOfSwapsWhenFeesPaidWithButt
              )
                .times(BigNumber(toCryptocurrency.price))
                .shiftedBy(-1 * toCryptocurrency.decimals)
                .minus(
                  SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath
                    .gas_as_usd
                )
                .minus(
                  SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath
                    .feeAsButtAsUsd
                );
          }
          SECRET_NETWORK.buttonSwap.aggregator.displayFee();
        }
      },
      displayFee: () => {
        let toCryptocurrency =
          SECRET_NETWORK.cryptocurrencies[
            SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath.to_id
          ];
        if (
          SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath.fee &&
          SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath.feeAsButt !=
            SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath.fee
        ) {
          $(FEE_CARD_SELECTOR).removeClass("d-none");
          let feeFormatted;
          if (document.secretNetworkDexAggregatorForm.payFeesWithButt.checked) {
            feeFormatted = `${document.humanizeStringNumberFromSmartContract(
              SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath.feeAsButt,
              6
            )} BUTT`;
            $("#user-butt-balance-container").removeClass("d-none");
            $("#aggregator-fee-container").removeClass("d-none");
          } else {
            feeFormatted = `${document.humanizeStringNumberFromSmartContract(
              SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath.fee,
              toCryptocurrency.decimals
            )} ${toCryptocurrency.symbol}`;
            $("#user-butt-balance-container").addClass("d-none");
            $("#aggregator-fee-container").addClass("d-none");
          }
          $("#fee-formatted").text(feeFormatted);
        }
      },
      getEstimate: async (reverse = false) => {
        // 1. Increase query count
        SECRET_NETWORK.buttonSwap.aggregator.queryCount += 1;
        let currentQueryCount = SECRET_NETWORK.buttonSwap.aggregator.queryCount;
        // 2. Reset before estimate
        SECRET_NETWORK.buttonSwap.aggregator.resetBeforeEstimate(reverse);
        let amount;
        let fromId = SECRET_NETWORK.buttonSwap.fromId;
        let toId = SECRET_NETWORK.buttonSwap.toId;
        // 3. Set the amount and usd amount based on whether it's a reverse or not
        if (reverse) {
          amount = document.secretNetworkDexAggregatorForm.estimateAmount.value;
          SECRET_NETWORK.setUsdTextFromAmount(
            amount,
            toId,
            TO_USD_PRICE_SELECTOR
          );
        } else {
          amount = document.secretNetworkDexAggregatorForm.fromAmount.value;
          SECRET_NETWORK.setUsdTextFromAmount(
            amount,
            fromId,
            FROM_USD_PRICE_SELECTOR
          );
        }
        // 4. Action depend on wrap/unwrap or swap
        if (SECRET_NETWORK.oneForOneSwap(fromId, toId)) {
          if (reverse) {
            document.secretNetworkDexAggregatorForm.fromAmount.value = amount;
          } else {
            document.secretNetworkDexAggregatorForm.estimateAmount.value =
              amount;
          }
          document.enableButton(SUBMIT_BUTTON_SELECTOR);
        } else {
          try {
            await SECRET_NETWORK.getAndSetSelectedSwapPath(
              fromId,
              toId,
              amount,
              SECRET_NETWORK.buttonSwap.aggregator,
              SUBMIT_BUTTON_SELECTOR,
              reverse
            );
            if (
              currentQueryCount ==
              SECRET_NETWORK.buttonSwap.aggregator.queryCount
            ) {
              if (reverse) {
                document.secretNetworkDexAggregatorForm.fromAmount.value =
                  document.applyDecimals(
                    SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath
                      .best_from_amount,
                    SECRET_NETWORK.cryptocurrencies[
                      SECRET_NETWORK.buttonSwap.fromId
                    ].decimals
                  );
                SECRET_NETWORK.setUsdTextFromAmount(
                  document.secretNetworkDexAggregatorForm.fromAmount.value,
                  SECRET_NETWORK.buttonSwap.fromId,
                  FROM_USD_PRICE_SELECTOR
                );
              }
              SECRET_NETWORK.buttonSwap.aggregator.setBestResultPerProtocol(
                fromId,
                toId
              );
              SECRET_NETWORK.buttonSwap.aggregator.applyFee();
              SECRET_NETWORK.buttonSwap.aggregator.renderBestResultsPerProtocolTable();
              this.fillForm(currentQueryCount);
              $(RESULTS_SELECTOR).removeClass("d-none");
            }
          } catch (err) {
            if (
              currentQueryCount ==
              SECRET_NETWORK.buttonSwap.aggregator.queryCount
            ) {
              if (err.status == 503) {
                $(FROM_AMOUNT_INPUT_SELECTOR).trigger("input");
              }
            }
          }
        }
      },
      renderBestResultsPerProtocolTable: () => {
        let resultsForDataTable = [];
        Object.keys(
          SECRET_NETWORK.buttonSwap.aggregator.bestResultsPerProtocol
        ).forEach((key) => {
          resultsForDataTable.push(
            SECRET_NETWORK.buttonSwap.aggregator.bestResultsPerProtocol[key]
          );
        });
        SECRET_NETWORK.buttonSwap.aggregator.bestResultsPerProtocolTable.clear();
        SECRET_NETWORK.buttonSwap.aggregator.bestResultsPerProtocolTable.rows.add(
          resultsForDataTable
        );
        SECRET_NETWORK.buttonSwap.aggregator.bestResultsPerProtocolTable.draw();
      },
      resetAfterSwap: () => {
        document.secretNetworkDexAggregatorForm.fromAmount.value = "";
        document.secretNetworkDexAggregatorForm.estimateAmount.value = "";
        document.secretNetworkDexAggregatorForm.minAmount.value = "";
        $(FEE_CARD_SELECTOR).addClass("d-none");
        $(RESULTS_SELECTOR).addClass("d-none");
        $(FROM_USD_PRICE_SELECTOR).text("");
        $(MIN_ACCEPTABLE_AMOUNT_USD_PRICE_SELECTOR).text("");
        $(TO_USD_PRICE_SELECTOR).text("");
        SECRET_NETWORK.updateWalletBalance(SECRET_NETWORK.buttonSwap.fromId);
        SECRET_NETWORK.updateWalletBalance(SECRET_NETWORK.buttonSwap.toId);
      },
      resetBeforeEstimate: (reverse = false) => {
        $(FEE_CARD_SELECTOR).addClass("d-none");
        $(RESULTS_SELECTOR).addClass("d-none");
        SECRET_NETWORK.buttonSwap.aggregator.bestResultsPerProtocol = {};
        SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath = undefined;
        // This holds the results of swaps for a pool, for crypto id, for the amount offered
        SECRET_NETWORK.buttonSwap.aggregator.simulationSwapResults = {};
        SECRET_NETWORK.buttonSwap.aggregator.swapPaths = {};
        $(FROM_USD_PRICE_SELECTOR).text("");
        $(MIN_ACCEPTABLE_AMOUNT_USD_PRICE_SELECTOR).text("");
        $(TO_USD_PRICE_SELECTOR).text("");
        if (reverse) {
          document.secretNetworkDexAggregatorForm.fromAmount.value = "";
        } else {
          document.secretNetworkDexAggregatorForm.estimateAmount.value = "";
          document.secretNetworkDexAggregatorForm.minAmount.value = "";
        }
      },
      // The swapPaths are already ordered so just grab the first of each
      setBestResultPerProtocol: (fromId, toId) => {
        let protocolId = 0;
        SECRET_NETWORK.buttonSwap.aggregator.swapPaths[fromId][toId].forEach(
          (swapPath) => {
            protocolId = 0;
            if (swapPath.protocol_id && swapPath.protocol_id != 3) {
              protocolId = swapPath.protocol_id;
            }
            if (
              !SECRET_NETWORK.buttonSwap.aggregator.bestResultsPerProtocol[
                protocolId
              ]
            ) {
              SECRET_NETWORK.buttonSwap.aggregator.bestResultsPerProtocol[
                protocolId
              ] = swapPath;
            }
          }
        );
        // Add missing protocols and dataTable fields
        Object.keys(PROTOCOLS).forEach((key) => {
          if (
            !SECRET_NETWORK.buttonSwap.aggregator.bestResultsPerProtocol[key]
          ) {
            SECRET_NETWORK.buttonSwap.aggregator.bestResultsPerProtocol[key] = {
              dataTable: {
                protocolName: PROTOCOLS[key],
              },
            };
          } else {
            let swapPath =
              SECRET_NETWORK.buttonSwap.aggregator.bestResultsPerProtocol[key];
            swapPath.dataTable = {
              protocolName: PROTOCOLS[key],
              resultOfSwaps: document.humanizeStringNumberFromSmartContract(
                swapPath.resultOfSwaps,
                SECRET_NETWORK.cryptocurrencies[swapPath.to_id].decimals
              ),
              gasAsUsd: parseFloat(swapPath.gas_as_usd).toFixed(2),
              netUsdResultOfSwaps:
                document.humanizeStringNumberFromSmartContract(
                  swapPath.netUsdResultOfSwaps,
                  0,
                  2
                ),
            };
            SECRET_NETWORK.buttonSwap.aggregator.bestResultsPerProtocol[key] =
              swapPath;
          }
        });
      },
      setFeeForSelectedSwapPath: () => {
        if (SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath.protocol_id) {
          SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath.fee =
            Math.round(
              (SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath
                .resultOfSwaps *
                VIP_LEVELS[SECRET_NETWORK.userVipLevel].tradingFee) /
                100
            );
        } else {
          let otherProtocolsBestResultAmount;
          if (SECRET_NETWORK.buttonSwap.aggregator.bestResultsPerProtocol[2]) {
            otherProtocolsBestResultAmount =
              SECRET_NETWORK.buttonSwap.aggregator.bestResultsPerProtocol[2]
                .resultOfSwaps;
          }
          if (SECRET_NETWORK.buttonSwap.aggregator.bestResultsPerProtocol[4]) {
            if (
              SECRET_NETWORK.buttonSwap.aggregator.bestResultsPerProtocol[4]
                .resultOfSwaps > otherProtocolsBestResultAmount
            ) {
              otherProtocolsBestResultAmount =
                SECRET_NETWORK.buttonSwap.aggregator.bestResultsPerProtocol[4]
                  .resultOfSwaps;
            }
          }
          if (otherProtocolsBestResultAmount) {
            SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath.fee =
              Math.round(
                (VIP_LEVELS[SECRET_NETWORK.userVipLevel].commission *
                  (SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath
                    .resultOfSwaps -
                    otherProtocolsBestResultAmount)) /
                  100
              );
          } else {
            SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath.fee =
              Math.round(
                (SECRET_NETWORK.buttonSwap.aggregator.selectedSwapPath
                  .resultOfSwaps *
                  VIP_LEVELS[SECRET_NETWORK.userVipLevel].tradingFee) /
                  100
              );
          }
        }
      },
      successMessage: function (
        fromAmount,
        response,
        fromCryptocurrency,
        toCryptocurrency
      ) {
        let returnAmount;
        let successMessage = "Success";
        if (response && response.arrayLog) {
          response.arrayLog.forEach(function (attribute) {
            if (attribute.key == "return_amount") {
              returnAmount = attribute.value;
            }
          });
          if (returnAmount) {
            successMessage = `${document.humanizeStringNumberFromSmartContract(
              fromAmount,
              fromCryptocurrency.decimals
            )} ${
              fromCryptocurrency.symbol
            } => ${document.humanizeStringNumberFromSmartContract(
              returnAmount,
              toCryptocurrency.decimals
            )} ${toCryptocurrency.symbol}`;
          }
        }
        return successMessage;
      },
    };

    SECRET_NETWORK.buttonSwap.limit = {
      initiated: false,
      displayedWithButtKey: false,
      cancellingOrder: false,
      getOrdersInterval: undefined,
      historyTable: undefined,
      openTable: undefined,
      latestLimitOrderUpdatedAtFromChain: undefined,
      latestLimitOrderUpdatedAtFromServer: undefined,
      init: async () => {
        if (!SECRET_NETWORK.buttonSwap.limit.initiated) {
          SECRET_NETWORK.buttonSwap.limit.initiated = true;
          SECRET_NETWORK.buttonSwap.limit.displayWithButtKey();

          // LISTENERS
          SECRET_NETWORK.buttonSwap.limit.addListeners();

          // DATATABLE
          SECRET_NETWORK.buttonSwap.limit.historyTable = $(
            "#limit-orders-history-table"
          ).DataTable({
            columns: [
              {
                data: "created_at_block_time",
                title: "Date",
                fnCreatedCell: function (nTd, sData, _oData, _iRow) {
                  let date = new Date(sData * 1000);
                  $(nTd).html(
                    `${(date.getMonth() + 1).toString().padStart(2, "0")}-${date
                      .getDate()
                      .toString()
                      .padStart(2, "0")} ${date
                      .getHours()
                      .toString()
                      .padStart(2, "0")}:${date
                      .getMinutes()
                      .toString()
                      .padStart(2, "0")}:${date
                      .getSeconds()
                      .toString()
                      .padStart(2, "0")}`
                  );
                },
              },
              {
                data: "from",
                title: "From",
                fnCreatedCell: function (nTd, sData, oData, _iRow) {
                  $(nTd).html(
                    `<a href="#" data-from-id=${oData.from.id}  data-to-id=${
                      oData.to.id
                    }>${document.humanizeStringNumberFromSmartContract(
                      oData.from_amount_as_integer_string,
                      sData.decimals
                    )} ${sData.symbol}</a>`
                  );
                },
              },
              {
                data: "to",
                title: "To",
                fnCreatedCell: function (nTd, sData, oData, _iRow) {
                  $(nTd).html(
                    `<a href="#" data-from-id=${oData.from.id}  data-to-id=${
                      oData.to.id
                    }>${document.humanizeStringNumberFromSmartContract(
                      oData.net_to_amount_as_integer_string,
                      sData.decimals
                    )} ${sData.symbol}</a>`
                  );
                },
              },
              {
                data: "price",
                title: "Price",
              },
              {
                data: "filled_percentage",
                title: "Filled",
                fnCreatedCell: function (nTd, sData, _oData, _iRow) {
                  $(nTd).html(`${BigNumber(sData).dp(2)}%`);
                },
              },
              {
                data: "status",
                title: "Status",
              },
            ],
            dom: '<"top"i>frtp',
            order: [[0, "desc"]],
            info: false,
            ordering: false,
            paging: false,
            searching: false,
            drawCallback: function () {
              $("#limit-orders-history-table a[href='#']").on(
                "click",
                async (e) => {
                  e.preventDefault();
                  if ($(e.currentTarget).attr("data-from-id")) {
                    SECRET_NETWORK.buttonSwap.changeCryptocurrencies(
                      $(e.currentTarget).attr("data-from-id"),
                      $(e.currentTarget).attr("data-to-id")
                    );
                  }
                }
              );
            },
          });

          SECRET_NETWORK.buttonSwap.limit.openTable = $(
            "#limit-orders-open-table"
          ).DataTable({
            columns: [
              {
                data: "created_at_block_time",
                title: "Date",
                fnCreatedCell: function (nTd, sData, _oData, _iRow) {
                  let date = new Date(sData * 1000);
                  $(nTd).html(
                    `${(date.getMonth() + 1).toString().padStart(2, "0")}-${date
                      .getDate()
                      .toString()
                      .padStart(2, "0")} ${date
                      .getHours()
                      .toString()
                      .padStart(2, "0")}:${date
                      .getMinutes()
                      .toString()
                      .padStart(2, "0")}:${date
                      .getSeconds()
                      .toString()
                      .padStart(2, "0")}`
                  );
                },
              },
              {
                data: "from",
                title: "From",
                fnCreatedCell: function (nTd, sData, oData, _iRow) {
                  $(nTd).html(
                    `<a href="#" data-from-id=${oData.from.id}  data-to-id=${
                      oData.to.id
                    }>${document.humanizeStringNumberFromSmartContract(
                      oData.from_amount_as_integer_string,
                      sData.decimals
                    )} ${sData.symbol}</a>`
                  );
                },
              },
              {
                data: "to",
                title: "To",
                fnCreatedCell: function (nTd, sData, oData, _iRow) {
                  $(nTd).html(
                    `<a href="#" data-from-id=${oData.from.id}  data-to-id=${
                      oData.to.id
                    }>${document.humanizeStringNumberFromSmartContract(
                      oData.net_to_amount_as_integer_string,
                      sData.decimals
                    )} ${sData.symbol}</a>`
                  );
                },
              },
              {
                data: "price",
                title: "Price",
              },
              {
                data: "filled_percentage",
                title: "Filled",
                fnCreatedCell: function (nTd, sData, _oData, _iRow) {
                  $(nTd).html(`${BigNumber(sData).dp(2)}%`);
                },
              },
              {
                data: null,
                className: "dt-center editor-edit",
                defaultContent:
                  '<a href="#"><div class="d-none loading"><em aria-hidden="true" class="spinner-grow spinner-grow-sm" role="status"></em><em>Loading...</em></div><div class="ready"><div class="bi bi-trash"></div></div></a>',
                orderable: false,
                width: "91px",
              },
            ],
            dom: '<"top"i>frtp',
            order: [[0, "desc"]],
            info: false,
            ordering: false,
            paging: false,
            searching: false,
            createdRow: function (row, data) {
              $(row).attr(
                "data-limit-order-from-token-address",
                data.from.smart_contract.address
              );
              if (data.position) {
                $(row).attr("data-limit-order-position", data.position);
              } else {
                $(row).attr(
                  "data-limit-order-position",
                  data.third_party_identifier_for_wallet
                );
              }
              $(row).attr(
                "data-limit-order-wallet-address",
                SECRET_NETWORK.walletAddress
              );
            },
            drawCallback: function () {
              $("#tab-limit-open-orders .bi-trash").on("click", async (e) => {
                e.preventDefault();
                SECRET_NETWORK.buttonSwap.limit.cancellingOrder = true;
                try {
                  document.disableButton(e.target.closest("a"));
                  let from_token_address = $(e.target)
                    .closest("tr")
                    .data("limitOrderFromTokenAddress");
                  let position = String(
                    $(e.target).closest("tr").data("limitOrderPosition")
                  );
                  let walletAddress = $(e.target)
                    .closest("tr")
                    .data("limitOrderWalletAddress");
                  if (walletAddress != SECRET_NETWORK.walletAddress) {
                    throw "Wrong wallet. Please refresh the page.";
                  }
                  let msg = {
                    cancel_order: {
                      from_token_address,
                      position,
                    },
                  };
                  let params = {
                    sender: SECRET_NETWORK.walletAddress,
                    contract_address: SN_LIMIT_ORDERS_ADDRESS,
                    code_hash: SN_LIMIT_ORDERS_DATA_HASH,
                    msg,
                  };
                  await SECRET_NETWORK.executeContract(params, 200_000);
                  // Update latestLimitOrderUpdatedAtFromChain
                  SECRET_NETWORK.buttonSwap.limit.latestLimitOrderUpdatedAtFromChain =
                    SECRET_NETWORK.height;
                  await SECRET_NETWORK.buttonSwap.limit.updateOrdersOnServer();
                  await SECRET_NETWORK.buttonSwap.limit.getAndDisplayOrders();
                  document.showAlertSuccess("Order cancelled.", true);
                } catch (err) {
                  document.showAlertDanger(err);
                } finally {
                  SECRET_NETWORK.buttonSwap.limit.cancellingOrder = false;
                  document.enableButton(e.target.closest("a"));
                }
              });

              $("#tab-limit-open-orders a[href='#']").on("click", async (e) => {
                e.preventDefault();
                if ($(e.currentTarget).attr("data-from-id")) {
                  SECRET_NETWORK.buttonSwap.changeCryptocurrencies(
                    $(e.currentTarget).attr("data-from-id"),
                    $(e.currentTarget).attr("data-to-id")
                  );
                }
              });
            },
          });

          await SECRET_NETWORK.buttonSwap.limit.getAndSetExecutionFee();
        }
      },
      addListeners: function () {
        $(document).on("butt_balance_updated", async () => {
          SECRET_NETWORK.buttonSwap.limit.displayWithButtKey();
        });

        $(LIMIT_PRICE_INPUT_SELECTOR).on("input", async () => {
          let amount = Number($(LIMIT_PRICE_INPUT_SELECTOR).val());
          let cryptoDecimals =
            SECRET_NETWORK.cryptocurrencies[SECRET_NETWORK.buttonSwap.fromId]
              .decimals;
          if (amount.countDecimals() > cryptoDecimals) {
            $(LIMIT_PRICE_INPUT_SELECTOR).val(
              BigNumber(amount).dp(cryptoDecimals)
            );
          }
          SECRET_NETWORK.buttonSwap.limit.setFromAmount();
        });

        $(LIMIT_FROM_AMOUNT_INPUT_SELECTOR).on("input", async () => {
          let amount = Number($(LIMIT_FROM_AMOUNT_INPUT_SELECTOR).val());
          let cryptoDecimals =
            SECRET_NETWORK.cryptocurrencies[SECRET_NETWORK.buttonSwap.fromId]
              .decimals;
          if (amount.countDecimals() > cryptoDecimals) {
            $(LIMIT_FROM_AMOUNT_INPUT_SELECTOR).val(
              BigNumber(amount).dp(cryptoDecimals)
            );
          }
          SECRET_NETWORK.setUsdTextFromAmount(
            amount,
            SECRET_NETWORK.buttonSwap.fromId,
            LIMIT_FROM_USD_PRICE_SELECTOR
          );
          SECRET_NETWORK.buttonSwap.limit.setToAmount();
        });

        $(LIMIT_TO_AMOUNT_INPUT_SELECTOR).on("input", async () => {
          let amount = Number($(LIMIT_TO_AMOUNT_INPUT_SELECTOR).val());
          let cryptoDecimals =
            SECRET_NETWORK.cryptocurrencies[SECRET_NETWORK.buttonSwap.toId]
              .decimals;
          if (amount.countDecimals() > cryptoDecimals) {
            $(LIMIT_TO_AMOUNT_INPUT_SELECTOR).val(
              BigNumber(amount).dp(cryptoDecimals)
            );
          }
          SECRET_NETWORK.setUsdTextFromAmount(
            amount,
            SECRET_NETWORK.buttonSwap.toId,
            LIMIT_TO_USD_PRICE_SELECTOR
          );
          SECRET_NETWORK.buttonSwap.limit.setFromAmount();
        });

        // Commenting out because I don't want to deal with SECRET_NETWORK wrap paths right now
        document.secretNetworkLimitForm.onsubmit = async (e) => {
          // e.preventDefault();
          // document.disableButton(LIMIT_SUBMIT_BUTTON_SELECTOR);
          // try {
          //   let originalFromCryptocurrencyId = SECRET_NETWORK.buttonSwap.fromId;
          //   let fromCryptocurrency =
          //     SECRET_NETWORK.cryptocurrencies[originalFromCryptocurrencyId];
          //   let toCryptocurrency =
          //     SECRET_NETWORK.cryptocurrencies[SECRET_NETWORK.buttonSwap.toId];
          //   let fromAmount = document.secretNetworkLimitForm.fromAmount.value;
          //   let toAmount = document.secretNetworkLimitForm.toAmount.value;
          //   fromAmount = document.formatHumanizedNumberForSmartContract(
          //     fromAmount,
          //     fromCryptocurrency.decimals
          //   );
          //   toAmount = document.formatHumanizedNumberForSmartContract(
          //     toAmount,
          //     toCryptocurrency.decimals
          //   );
          //   await document.connectKeplrWallet(false);
          //   if (
          //     SECRET_NETWORK.wrapPaths[originalFromCryptocurrencyId].includes(toCryptocurrency.id)
          //   ) {
          //     let swapParams = SECRET_NETWORK.getSwapParams(
          //       originalFromCryptocurrencyId,
          //       toCryptocurrency.id,
          //       fromAmount
          //     );
          //     await SECRET_NETWORK.broadcast(
          //       swapParams.messages,
          //       swapParams.gas
          //     );
          //     SECRET_NETWORK.updateWalletBalance(toCryptocurrency.id);
          //     document.showAlertSuccess(
          //       SECRET_NETWORK.buttonSwap.aggregator.successMessage(
          //         0,
          //         0,
          //         0,
          //         0
          //       )
          //     );
          //   } else {
          //     let swapParams = {
          //       messages: [],
          //       gas: 0,
          //       transactionType: "N/A",
          //     };
          //     // Handle if first or second token need to be wrapped first
          //     if (fromCryptocurrency.smart_contract == undefined) {
          //       fromCryptocurrency =
          //         SECRET_NETWORK.cryptocurrencies[
          //           SECRET_NETWORK.wrapPaths[SECRET_NETWORK.buttonSwap.fromId]
          //         ];
          //       swapParams = SECRET_NETWORK.getSwapParams(
          //         SECRET_NETWORK.buttonSwap.fromId,
          //         fromCryptocurrency.id,
          //         fromAmount,
          //         fromAmount
          //       );
          //     }
          //     if (toCryptocurrency.smart_contract == undefined) {
          //       toCryptocurrency =
          //         SECRET_NETWORK.cryptocurrencies[
          //           SECRET_NETWORK.wrapPaths[SECRET_NETWORK.buttonSwap.toId]
          //         ];
          //     }
          //     let createOrderMsg = JSON.stringify({
          //       create_order: {
          //         to_amount: toAmount,
          //         to_token: toCryptocurrency.smart_contract.address,
          //       },
          //     });
          //     swapParams.messages.push(
          //       new MsgExecuteContract({
          //         sender: SECRET_NETWORK.walletAddress,
          //         contract_address: fromCryptocurrency.smart_contract.address,
          //         code_hash: fromCryptocurrency.smart_contract.data_hash,
          //         msg: {
          //           send: {
          //             recipient: SN_LIMIT_ORDERS_ADDRESS,
          //             amount: fromAmount,
          //             msg: Buffer.from(createOrderMsg).toString("base64"),
          //           },
          //         },
          //       })
          //     );
          //     swapParams.gas += 450_000;
          //     // Set execution fee
          //     if (SECRET_NETWORK.buttonSwap.limit.executionFee) {
          //       // Convert scrt to sscrt
          //       let scrt = SECRET_NETWORK.cryptocurrencies[515];
          //       let sscrt = SECRET_NETWORK.cryptocurrencies[129];
          //       if (scrt.symbol != "SCRT" || sscrt.symbol != "SSCRT") {
          //         throw "Wrong SCRT or SSCRT ids.";
          //       }
          //       // a. Wrap SCRT
          //       let wrapParams = SECRET_NETWORK.getSwapParams(
          //         scrt.id,
          //         sscrt.id,
          //         SECRET_NETWORK.buttonSwap.limit.executionFee
          //       );
          //       swapParams.messages = swapParams.messages.concat(
          //         wrapParams.messages
          //       );
          //       swapParams.gas += Number(wrapParams.gas);
          //       // b. Set execution fee for order
          //       swapParams.messages.push(
          //         new MsgExecuteContract({
          //           sender: SECRET_NETWORK.walletAddress,
          //           contract_address: sscrt.smart_contract.address,
          //           code_hash: sscrt.smart_contract.data_hash,
          //           msg: {
          //             send: {
          //               amount: SECRET_NETWORK.buttonSwap.limit.executionFee,
          //               recipient: SN_LIMIT_ORDERS_ADDRESS,
          //               msg: Buffer.from(
          //                 JSON.stringify({
          //                   set_execution_fee_for_order: {},
          //                 })
          //               ).toString("base64"),
          //             },
          //           },
          //         })
          //       );
          //       swapParams.gas += 250_000;
          //     }
          //     // If user does not have an api key, create one
          //     let apiKey;
          //     if (!SECRET_NETWORK.apiKey) {
          //       apiKey = await HELPERS.getRandomUUID();
          //       swapParams.messages.push(
          //         new MsgExecuteContract({
          //           sender: SECRET_NETWORK.walletAddress,
          //           contract_address: SN_API_KEY_MANAGER_ADDRESS,
          //           code_hash: SN_API_KEY_MANAGER_DATA_HASH,
          //           msg: {
          //             set_api_key: {
          //               api_key: apiKey,
          //             },
          //           },
          //         })
          //       );
          //       swapParams.gas += 150_000;
          //     }
          //     // Broadcast
          //     await SECRET_NETWORK.broadcast(
          //       swapParams.messages,
          //       swapParams.gas,
          //       "production",
          //       1
          //     );
          //     // Update latestLimitOrderUpdatedAtFromChain
          //     SECRET_NETWORK.buttonSwap.limit.latestLimitOrderUpdatedAtFromChain =
          //       SECRET_NETWORK.height;
          //     if (apiKey) {
          //       SECRET_NETWORK.apiKey = apiKey;
          //       await SECRET_NETWORK.updateApiKeyOnServer();
          //     }
          //     await SECRET_NETWORK.buttonSwap.limit.updateOrdersOnServer();
          //     await SECRET_NETWORK.buttonSwap.limit.getAndDisplayOrders();
          //     document.showAlertSuccess("Order created.");
          //   }
          //   SECRET_NETWORK.buttonSwap.limit.resetForm();
          //   SECRET_NETWORK.updateWalletBalance(originalFromCryptocurrencyId);
          // } catch (error) {
          //   document.showAlertDanger(error);
          // } finally {
          //   document.enableButton(LIMIT_SUBMIT_BUTTON_SELECTOR);
          // }
        };
      },
      displayWithButtKey: async () => {
        if (!SECRET_NETWORK.buttonSwap.limit.displayedWithButtKey) {
          let buttViewingKey = await window.keplr.getSecret20ViewingKey(
            SECRET_NETWORK.chainId(),
            SN_BUTT_ADDRESS
          );
          if (buttViewingKey) {
            SECRET_NETWORK.buttonSwap.limit.displayedWithButtKey = true;
            $("#limit-orders .tab-pane").removeClass("d-none");
            $(LIMIT_SUBMIT_BUTTON_SELECTOR).removeClass("d-none");
            await SECRET_NETWORK.buttonSwap.limit.getAndSetApiKeyAndInterval();
            SECRET_NETWORK.buttonSwap.limit.getAndDisplayOrders();
          }
        }
      },
      getAndDisplayOrders: async () => {
        if (
          !SECRET_NETWORK.buttonSwap.limit.cancellingOrder &&
          $(".tab-limit.active").length
        ) {
          if (SECRET_NETWORK.apiKey) {
            await SECRET_NETWORK.buttonSwap.limit.getAndDisplayOrdersFromServer();
          } else {
            await SECRET_NETWORK.buttonSwap.limit.getAndDisplayOrdersFromBlockchain();
          }
        }
      },
      getAndDisplayOrdersFromBlockchain: async () => {
        try {
          let buttViewingKey = await window.keplr.getSecret20ViewingKey(
            SECRET_NETWORK.chainId(),
            SN_BUTT_ADDRESS
          );
          let query = {
            orders: {
              address: SECRET_NETWORK.walletAddress,
              key: buttViewingKey,
              page: "0",
              page_size: "1000",
            },
          };
          let queryParams = {
            contract_address: SN_LIMIT_ORDERS_ADDRESS,
            code_hash: SN_LIMIT_ORDERS_DATA_HASH,
            query,
          };
          let result = await SECRET_NETWORK.queryContractSmart(queryParams);
          let orders = result.orders.orders;
          let openOrders = [];
          let finalizedOrders = [];
          orders.forEach(function (order) {
            order.from = SECRET_NETWORK.cryptocurrencies[order.from_token];
            order.to = SECRET_NETWORK.cryptocurrencies[order.to_token];
            order.from_amount_as_integer_string = order.from_amount;
            order.net_to_amount_as_integer_string = order.net_to_amount;
            order.price =
              Number(order.from_amount) / Number(order.net_to_amount);
            order.filled_percentage = Math.round(
              (Number(order.net_to_amount_filled) /
                Number(order.net_to_amount)) *
                100
            );
            if (order.cancelled) {
              order.status = "cancelled";
              finalizedOrders.push(order);
            } else if (order.from_amount == order.from_amount_filled) {
              order.status = "filled";
              finalizedOrders.push(order);
            } else {
              order.status = "open";
              openOrders.push(order);
            }
          });
          SECRET_NETWORK.buttonSwap.limit.openTable.clear();
          SECRET_NETWORK.buttonSwap.limit.openTable.rows.add(openOrders);
          SECRET_NETWORK.buttonSwap.limit.openTable.draw();
          SECRET_NETWORK.buttonSwap.limit.historyTable.clear();
          SECRET_NETWORK.buttonSwap.limit.historyTable.rows.add(
            finalizedOrders
          );
          SECRET_NETWORK.buttonSwap.limit.historyTable.draw();
        } catch (err) {
          if (!err.message.includes("no matched secret20")) {
            document.showAlertDanger(err);
          }
        }
      },
      getAndDisplayOrdersFromServer: async (
        statuses = "open,cancelled,filled"
      ) => {
        let url = `/api/v1/orders?smart_contract_address=${SN_LIMIT_ORDERS_ADDRESS}&statuses=${statuses}&creator_address=${SECRET_NETWORK.walletAddress}`;
        try {
          let orders = await $.ajax({
            url: url,
            type: "get",
            dataType: "json",
            beforeSend: function (xhr) {
              xhr.setRequestHeader(
                "Authorization",
                `Bearer ${SECRET_NETWORK.apiKey}`
              );
            },
          });
          let finalizedOrders = [];
          let openOrders = [];
          orders.forEach(function (order) {
            if (order.updated_at_block_height) {
              if (
                SECRET_NETWORK.buttonSwap.limit
                  .latestLimitOrderUpdatedAtFromServer
              ) {
                if (
                  order.updated_at_block_height >
                  SECRET_NETWORK.buttonSwap.limit
                    .latestLimitOrderUpdatedAtFromServer
                ) {
                  SECRET_NETWORK.buttonSwap.limit.latestLimitOrderUpdatedAtFromServer =
                    order.updated_at_block_height;
                }
              } else {
                SECRET_NETWORK.buttonSwap.limit.latestLimitOrderUpdatedAtFromServer =
                  order.updated_at_block_height;
              }
            }
            order.from = SECRET_NETWORK.cryptocurrencies[order.from_id];
            order.to = SECRET_NETWORK.cryptocurrencies[order.to_id];
            if (order.status == "cancelled") {
              finalizedOrders.push(order);
            } else if (order.status == "filled") {
              finalizedOrders.push(order);
            } else {
              openOrders.push(order);
            }
          });
          SECRET_NETWORK.buttonSwap.limit.openTable.clear();
          SECRET_NETWORK.buttonSwap.limit.openTable.rows.add(openOrders);
          SECRET_NETWORK.buttonSwap.limit.openTable.draw();
          SECRET_NETWORK.buttonSwap.limit.historyTable.clear();
          SECRET_NETWORK.buttonSwap.limit.historyTable.rows.add(
            finalizedOrders
          );
          SECRET_NETWORK.buttonSwap.limit.historyTable.draw();
        } catch (err) {
          if (err.status == 401) {
            await SECRET_NETWORK.updateApiKeyOnServer();
            SECRET_NETWORK.buttonSwap.limit.getAndDisplayOrders();
          } else {
            document.showAlertDanger(err);
          }
        }
      },
      getAndSetApiKeyAndInterval: async () => {
        await SECRET_NETWORK.getAndSetApiKey();
        if (SECRET_NETWORK.buttonSwap.limit.getOrdersInterval) {
          clearInterval(SECRET_NETWORK.buttonSwap.limit.getOrdersInterval);
        }

        let intervalTime = 60_000;
        if (SECRET_NETWORK.apiKey) {
          intervalTime = 10_000;
        }
        SECRET_NETWORK.buttonSwap.limit.getOrdersInterval = setInterval(
          function () {
            SECRET_NETWORK.buttonSwap.limit.getAndDisplayOrders();
          },
          intervalTime
        );
      },
      getAndSetExecutionFee: async () => {
        try {
          let query = {
            config: {},
          };
          let queryParams = {
            contract_address: SN_LIMIT_ORDERS_ADDRESS,
            code_hash: SN_LIMIT_ORDERS_DATA_HASH,
            query,
          };
          let response = await SECRET_NETWORK.queryContractSmart(queryParams);
          SECRET_NETWORK.buttonSwap.limit.executionFee = response.execution_fee;
          $("#limit-execution-fee").text(
            `Execution fee: ${
              Number(SECRET_NETWORK.buttonSwap.limit.executionFee) / 1_000_000
            } SCRT`
          );
        } catch (err) {
          document.showAlertDanger(err);
        }
      },
      updateOrdersOnServer: async () => {
        try {
          await $.ajax({
            url: `https://btn-group-node-js.herokuapp.com/orders/check`,
            type: "get",
            dataType: "json",
          });
        } catch (err) {
          if (err.status != 200) {
            document.showAlertDanger(err);
          }
        }
      },
      resetForm: function () {
        document.secretNetworkLimitForm.fromAmount.value = "";
        document.secretNetworkLimitForm.toAmount.value = "";
        $(LIMIT_FROM_USD_PRICE_SELECTOR).text("");
        $(LIMIT_TO_USD_PRICE_SELECTOR).text("");
      },
      setFromAmount: function () {
        let price = Number(document.secretNetworkLimitForm.price.value);
        let toAmount = Number(document.secretNetworkLimitForm.toAmount.value);
        let fromAmount = toAmount * price;
        let cryptoDecimals =
          SECRET_NETWORK.cryptocurrencies[SECRET_NETWORK.buttonSwap.fromId]
            .decimals;
        if (fromAmount <= 0) {
          fromAmount = "";
        } else if (fromAmount.countDecimals() > cryptoDecimals) {
          fromAmount = BigNumber(fromAmount).dp(cryptoDecimals);
        }
        document.secretNetworkLimitForm.fromAmount.value = fromAmount;
        SECRET_NETWORK.setUsdTextFromAmount(
          document.secretNetworkLimitForm.fromAmount.value,
          SECRET_NETWORK.buttonSwap.fromId,
          LIMIT_FROM_USD_PRICE_SELECTOR
        );
      },
      setToAmount: function () {
        let price = Number(document.secretNetworkLimitForm.price.value);
        let fromAmount = Number(
          document.secretNetworkLimitForm.fromAmount.value
        );
        let toAmount = fromAmount / price;
        let cryptoDecimals =
          SECRET_NETWORK.cryptocurrencies[SECRET_NETWORK.buttonSwap.toId]
            .decimals;
        if (toAmount <= 0) {
          toAmount = "";
        } else if (toAmount.countDecimals() > cryptoDecimals) {
          toAmount = BigNumber(toAmount).dp(cryptoDecimals);
        }
        document.secretNetworkLimitForm.toAmount.value = toAmount;
        SECRET_NETWORK.setUsdTextFromAmount(
          toAmount,
          SECRET_NETWORK.buttonSwap.toId,
          LIMIT_TO_USD_PRICE_SELECTOR
        );
      },
      // Commenting out because I don't want to deal with SECRET_NETWORK wrap paths right now
      updatePriceInput: async (fromId, toId) => {
        // try {
        //   document.setTokenButtonWithId("#price-button", fromId, SECRET_NETWORK.cryptocurrencies);
        //   // Get the wrapped versions of fromId and toId first
        //   if (!SECRET_NETWORK.cryptocurrencies[fromId].smart_contract) {
        //     fromId = SECRET_NETWORK.wrapPaths[fromId];
        //   }
        //   if (!SECRET_NETWORK.cryptocurrencies[toId].smart_contract) {
        //     toId = SECRET_NETWORK.wrapPaths[toId];
        //   }
        //   let priceObject = await $.ajax({
        //     url: `/cryptocurrency_prices/price?base_id=${toId}&quote_id=${fromId}`,
        //   });
        //   if (priceObject) {
        //     if (
        //       priceObject.original_base_id == String(toId) &&
        //       priceObject.original_quote_id == String(fromId)
        //     ) {
        //       document.secretNetworkLimitForm.price.value = priceObject.price;
        //     }
        //   } else {
        //     document.secretNetworkLimitForm.price.value = undefined;
        //   }
        // } catch (err) {
        //   document.showAlertDanger(err);
        // }
      },
    };

    SECRET_NETWORK.buttonSwap.pools = {
      initiated: false,
      poolId: undefined,
      fromId: undefined,
      toId: undefined,
      sharesId: undefined,
      init: async () => {
        if (!SECRET_NETWORK.buttonSwap.pools.initiated) {
          SECRET_NETWORK.buttonSwap.pools.initiated = true;
          SECRET_NETWORK.buttonSwap.pools.poolId =
            $(POOL_BUTTON_SELECTOR).data("initialId");
          SECRET_NETWORK.buttonSwap.pools.fromId = $(
            PROVIDE_FROM_TOKEN_BUTTON_SELECTOR
          ).data("initialId");
          SECRET_NETWORK.buttonSwap.pools.toId = $(
            PROVIDE_TO_TOKEN_BUTTON_SELECTOR
          ).data("initialId");
          SECRET_NETWORK.buttonSwap.pools.sharesId = $(
            WITHDRAW_TOKEN_BUTTON_SELECTOR
          ).data("initialId");
          SECRET_NETWORK.buttonSwap.pools.getAndSetRate(
            SECRET_NETWORK.buttonSwap.pools.poolId,
            SECRET_NETWORK.buttonSwap.pools.fromId,
            SECRET_NETWORK.buttonSwap.pools.toId
          );

          // List
          try {
            await $.ajax({
              url: `/pools/list`,
            });
            HELPERS.initPoolLists(["secret-network-pools-list"]);
            // Listeners
            SECRET_NETWORK.buttonSwap.pools.addListeners();
            $("#tab-pools .loading-container").addClass("d-none");
            $("#tab-pools .after-loaded-contents").removeClass("d-none");
          } catch (err) {
            document.showAlertDanger(err);
          }
        }
      },
      addListeners: () => {
        $(document).on("butt_balance_updated", async () => {
          SECRET_NETWORK.buttonSwap.pools.displayFee(
            SECRET_NETWORK.buttonSwap.pools.fromId,
            SECRET_NETWORK.buttonSwap.pools.toId
          );
        });

        HELPERS.listeners.listenForPoolSelect(SECRET_NETWORK.buttonSwap.pools);

        $(PROVIDE_FROM_AMOUNT_INPUT_SELECTOR).on("input", async () => {
          let amount = $(PROVIDE_FROM_AMOUNT_INPUT_SELECTOR).val();
          SECRET_NETWORK.setUsdTextFromAmount(
            amount,
            SECRET_NETWORK.buttonSwap.pools.fromId,
            PROVIDE_FROM_USD_PRICE_SELECTOR
          );
          let pool =
            SECRET_NETWORK.tradePairs[SECRET_NETWORK.buttonSwap.pools.poolId];
          if (pool.rate_without_decimals) {
            amount = document.formatHumanizedNumberForSmartContract(
              amount,
              SECRET_NETWORK.cryptocurrencies[
                SECRET_NETWORK.buttonSwap.pools.fromId
              ].decimals
            );
            let toTokenDecimals =
              SECRET_NETWORK.cryptocurrencies[
                SECRET_NETWORK.buttonSwap.pools.toId
              ].decimals;
            let toAmount = document.humanizeStringNumberFromSmartContract(
              BigNumber(amount).times(pool.rate_without_decimals),
              toTokenDecimals,
              toTokenDecimals,
              true
            );
            $(PROVIDE_TO_AMOUNT_INPUT_SELECTOR).val(toAmount);
            SECRET_NETWORK.setUsdTextFromAmount(
              toAmount,
              SECRET_NETWORK.buttonSwap.pools.toId,
              PROVIDE_TO_USD_PRICE_SELECTOR
            );
          }
        });
        $(PROVIDE_TO_AMOUNT_INPUT_SELECTOR).on("input", async () => {
          let amount = $(PROVIDE_TO_AMOUNT_INPUT_SELECTOR).val();
          SECRET_NETWORK.setUsdTextFromAmount(
            amount,
            SECRET_NETWORK.buttonSwap.pools.toId,
            PROVIDE_TO_USD_PRICE_SELECTOR
          );
          let pool =
            SECRET_NETWORK.tradePairs[SECRET_NETWORK.buttonSwap.pools.poolId];
          if (pool.rate_without_decimals) {
            amount = document.formatHumanizedNumberForSmartContract(
              amount,
              SECRET_NETWORK.cryptocurrencies[
                SECRET_NETWORK.buttonSwap.pools.toId
              ].decimals
            );
            let fromTokenDecimals =
              SECRET_NETWORK.cryptocurrencies[
                SECRET_NETWORK.buttonSwap.pools.fromId
              ].decimals;
            let fromAmount = document.humanizeStringNumberFromSmartContract(
              BigNumber(amount).dividedBy(pool.rate_without_decimals),
              fromTokenDecimals,
              fromTokenDecimals,
              true
            );
            $(PROVIDE_FROM_AMOUNT_INPUT_SELECTOR).val(fromAmount);
            SECRET_NETWORK.setUsdTextFromAmount(
              fromAmount,
              SECRET_NETWORK.buttonSwap.pools.fromId,
              PROVIDE_FROM_USD_PRICE_SELECTOR
            );
          }
        });

        $('form[name="secretNetworkWithdrawForm"] input[name="amount"]').on(
          "input",
          async () => {
            let fromCryptocurrency =
              SECRET_NETWORK.cryptocurrencies[
                SECRET_NETWORK.buttonSwap.pools.fromId
              ];
            let toCryptocurrency =
              SECRET_NETWORK.cryptocurrencies[
                SECRET_NETWORK.buttonSwap.pools.toId
              ];
            let sharesCryptocurrency =
              SECRET_NETWORK.cryptocurrencies[
                SECRET_NETWORK.buttonSwap.pools.sharesId
              ];
            let pool =
              SECRET_NETWORK.tradePairs[SECRET_NETWORK.buttonSwap.pools.poolId];
            let sharesAmount = document.secretNetworkWithdrawForm.amount.value;
            let sharesAmountFormatted =
              document.formatHumanizedNumberForSmartContract(
                sharesAmount,
                sharesCryptocurrency.decimals
              );
            let totalShares;
            let totalFromAmount;
            let totalToAmount;
            pool.cryptocurrency_pools.forEach((cp) => {
              if (cp.cryptocurrency_role == "shares") {
                totalShares = cp.amount;
              } else if (cp.cryptocurrency_id == fromCryptocurrency.id) {
                totalFromAmount = cp.amount;
              } else if (cp.cryptocurrency_id == toCryptocurrency.id) {
                totalToAmount = cp.amount;
              }
            });
            let fromAmount =
              (Number(totalFromAmount) * Number(sharesAmountFormatted)) /
              Number(totalShares);
            let fromAmountWithDecimals =
              document.humanizeStringNumberFromSmartContract(
                fromAmount,
                fromCryptocurrency.decimals,
                fromCryptocurrency.decimals
              );
            let toAmount =
              (Number(totalToAmount) * Number(sharesAmountFormatted)) /
              Number(totalShares);
            let toAmountWithDecimals =
              document.humanizeStringNumberFromSmartContract(
                toAmount,
                toCryptocurrency.decimals,
                toCryptocurrency.decimals
              );
            $("#withdraw-from-amount").text(
              `~ ${fromAmountWithDecimals} ${fromCryptocurrency.symbol}`
            );
            $("#withdraw-to-amount").text(
              `~ ${toAmountWithDecimals} ${toCryptocurrency.symbol}`
            );
          }
        );

        document.secretNetworkProvideForm.onsubmit = async (e) => {
          e.preventDefault();
          let poolId = SECRET_NETWORK.buttonSwap.pools.poolId;
          let pool = SECRET_NETWORK.tradePairs[poolId];
          let fromId = SECRET_NETWORK.buttonSwap.pools.fromId;
          let fromCryptocurrency = SECRET_NETWORK.cryptocurrencies[fromId];
          let fromAmount = document.secretNetworkProvideForm.fromAmount.value;
          let toId = SECRET_NETWORK.buttonSwap.pools.toId;
          let toCryptocurrency = SECRET_NETWORK.cryptocurrencies[toId];
          let toAmount = document.secretNetworkProvideForm.toAmount.value;
          let sharesId = SECRET_NETWORK.buttonSwap.pools.sharesId;
          let gas = 0;
          let messages = [];
          let fromAmountFormatted =
            document.formatHumanizedNumberForSmartContract(
              fromAmount,
              fromCryptocurrency.decimals
            );
          let toAmountFormatted =
            document.formatHumanizedNumberForSmartContract(
              toAmount,
              toCryptocurrency.decimals
            );
          document.disableButton(PROVIDE_FORM_SUBMIT_BUTTON_SELECTOR);
          try {
            await document.connectKeplrWallet(true);
            if (SECRET_NETWORK.walletAddress) {
              // Broadcast
              // 1. Increase allowance to contract for amounts specified for both tokens
              let fromViewingKey = await window.keplr.getSecret20ViewingKey(
                SECRET_NETWORK.chainId(),
                fromCryptocurrency.smart_contract.address
              );
              let query = {
                allowance: {
                  owner: SECRET_NETWORK.walletAddress,
                  key: fromViewingKey,
                  spender: pool.smart_contract.address,
                },
              };
              let queryParams = {
                contract_address: fromCryptocurrency.smart_contract.address,
                code_hash: fromCryptocurrency.smart_contract.data_hash,
                query,
              };
              let result = await SECRET_NETWORK.queryContractSmart(queryParams);
              if (
                Number(fromAmountFormatted) > Number(result.allowance.allowance)
              ) {
                messages.push(
                  new MsgExecuteContract({
                    sender: SECRET_NETWORK.walletAddress,
                    contract_address: fromCryptocurrency.smart_contract.address,
                    code_hash: fromCryptocurrency.smart_contract.data_hash,
                    msg: {
                      increase_allowance: {
                        spender: pool.smart_contract.address,
                        amount: fromAmountFormatted,
                      },
                    },
                  })
                );
                gas += 150_000;
              }
              let toViewingKey = await window.keplr.getSecret20ViewingKey(
                SECRET_NETWORK.chainId(),
                toCryptocurrency.smart_contract.address
              );
              query = {
                allowance: {
                  owner: SECRET_NETWORK.walletAddress,
                  key: toViewingKey,
                  spender: pool.smart_contract.address,
                },
              };
              queryParams = {
                contract_address: toCryptocurrency.smart_contract.address,
                code_hash: toCryptocurrency.smart_contract.data_hash,
                query,
              };
              result = await SECRET_NETWORK.queryContractSmart(queryParams);
              if (
                Number(toAmountFormatted) > Number(result.allowance.allowance)
              ) {
                messages.push(
                  new MsgExecuteContract({
                    sender: SECRET_NETWORK.walletAddress,
                    contract_address: toCryptocurrency.smart_contract.address,
                    code_hash: toCryptocurrency.smart_contract.data_hash,
                    msg: {
                      increase_allowance: {
                        spender: pool.smart_contract.address,
                        amount: toAmountFormatted,
                      },
                    },
                  })
                );
                gas += 150_000;
              }
              // 2. Provide liquidity to contract
              if (pool.protocol_id == 2) {
                messages.push(
                  new MsgExecuteContract({
                    sender: SECRET_NETWORK.walletAddress,
                    contract_address: pool.smart_contract.address,
                    code_hash: pool.smart_contract.data_hash,
                    msg: {
                      provide_liquidity: {
                        assets: [
                          {
                            info: {
                              token: {
                                contract_addr:
                                  fromCryptocurrency.smart_contract.address,
                                token_code_hash:
                                  fromCryptocurrency.smart_contract.data_hash,
                                viewing_key: "",
                              },
                            },
                            amount: fromAmountFormatted,
                          },
                          {
                            info: {
                              token: {
                                contract_addr:
                                  toCryptocurrency.smart_contract.address,
                                token_code_hash:
                                  toCryptocurrency.smart_contract.data_hash,
                                viewing_key: "",
                              },
                            },
                            amount: toAmountFormatted,
                          },
                        ],
                      },
                    },
                  })
                );
                gas += 1_000_000;
              } else if (pool.protocol_id == 4) {
                let token_0 = {
                  custom_token: {
                    contract_addr: fromCryptocurrency.smart_contract.address,
                    token_code_hash:
                      fromCryptocurrency.smart_contract.data_hash,
                  },
                };
                let amount_0 = fromAmountFormatted;
                let token_1 = {
                  custom_token: {
                    contract_addr: toCryptocurrency.smart_contract.address,
                    token_code_hash: toCryptocurrency.smart_contract.data_hash,
                  },
                };
                let amount_1 = toAmountFormatted;
                for (let cp of pool.cryptocurrency_pools) {
                  if (cp.cryptocurrency_role == "deposit" && cp.position == 1) {
                    if (cp.cryptocurrency_id == fromCryptocurrency.id) {
                      token_0 = {
                        custom_token: {
                          contract_addr:
                            toCryptocurrency.smart_contract.address,
                          token_code_hash:
                            toCryptocurrency.smart_contract.data_hash,
                        },
                      };
                      amount_0 = toAmountFormatted;
                      token_1 = {
                        custom_token: {
                          contract_addr:
                            fromCryptocurrency.smart_contract.address,
                          token_code_hash:
                            fromCryptocurrency.smart_contract.data_hash,
                        },
                      };
                      amount_1 = fromAmountFormatted;
                    }
                  }
                }

                if (pool.cryptocurrency_pools)
                  messages.push(
                    new MsgExecuteContract({
                      sender: SECRET_NETWORK.walletAddress,
                      contract_address: pool.smart_contract.address,
                      code_hash: pool.smart_contract.data_hash,
                      msg: {
                        add_liquidity: {
                          deposit: {
                            pair: {
                              token_0,
                              token_1,
                            },
                            amount_0,
                            amount_1,
                          },
                        },
                      },
                    })
                  );
                gas += 1_000_000;
              }
              // 3. Send fee to b1 if not vip 5
              let fee = SECRET_NETWORK.buttonSwap.pools.btnGroupFee(
                fromId,
                toId
              );
              if (fee > 0) {
                fee *= 1_000_000;
                let scrt = SECRET_NETWORK.cryptocurrencies[515];
                let sscrt = SECRET_NETWORK.cryptocurrencies[129];
                if (scrt.symbol != "SCRT" || sscrt.symbol != "SSCRT") {
                  throw "Wrong SCRT or SSCRT ids.";
                }
                // a. Wrap SCRT
                let wrapParams = SECRET_NETWORK.getSwapParams(
                  scrt.id,
                  sscrt.id,
                  fee
                );
                messages = messages.concat(wrapParams.messages);
                gas += Number(wrapParams.gas);

                // b. Send SSCRT to admin
                messages.push(
                  new MsgExecuteContract({
                    sender: SECRET_NETWORK.walletAddress,
                    contract_address: sscrt.smart_contract.address,
                    code_hash: sscrt.smart_contract.data_hash,
                    msg: {
                      transfer: {
                        amount: String(fee),
                        recipient: SN_B3_ADDRESS,
                      },
                    },
                  })
                );
                gas += 15_000;
              }
              await SECRET_NETWORK.broadcast(messages, gas);
              // Show success message like the one for the market swap
              document.showAlertSuccess(
                `${fromAmount} ${fromCryptocurrency.symbol} + ${toAmount} ${toCryptocurrency.symbol} provided.`
              );
              SECRET_NETWORK.buttonSwap.pools.resetForms();
              SECRET_NETWORK.updateWalletBalance(fromId);
              SECRET_NETWORK.updateWalletBalance(toId);
              SECRET_NETWORK.updateWalletBalance(sharesId);
            }
          } catch (error) {
            document.showAlertDanger(error);
          } finally {
            document.enableButton(PROVIDE_FORM_SUBMIT_BUTTON_SELECTOR);
          }
        };

        document.secretNetworkWithdrawForm.onsubmit = async (e) => {
          e.preventDefault();
          let poolId = SECRET_NETWORK.buttonSwap.pools.poolId;
          let pool = SECRET_NETWORK.tradePairs[poolId];
          let fromId = SECRET_NETWORK.buttonSwap.pools.fromId;
          let toId = SECRET_NETWORK.buttonSwap.pools.toId;
          let sharesId = SECRET_NETWORK.buttonSwap.pools.sharesId;
          let sharesCryptocurrency = SECRET_NETWORK.cryptocurrencies[sharesId];
          let amount = document.secretNetworkWithdrawForm.amount.value;
          let amountFormatted = document.formatHumanizedNumberForSmartContract(
            amount,
            sharesCryptocurrency.decimals
          );
          let messages = [];
          let gas = 0;
          document.disableButton(WITHDRAW_FORM_SUBMIT_BUTTON_SELECTOR);
          try {
            await document.connectKeplrWallet(true);
            if (SECRET_NETWORK.walletAddress) {
              // Broadcast
              // 1. Withdraw liquidity
              let withdrawMsg;
              if (pool.protocol_id == 2) {
                withdrawMsg = JSON.stringify({ withdraw_liquidity: {} });
                messages.push(
                  new MsgExecuteContract({
                    sender: SECRET_NETWORK.walletAddress,
                    contract_address:
                      sharesCryptocurrency.smart_contract.address,
                    code_hash: sharesCryptocurrency.smart_contract.data_hash,
                    msg: {
                      send: {
                        recipient: pool.smart_contract.address,
                        amount: amountFormatted,
                        msg: Buffer.from(withdrawMsg).toString("base64"),
                      },
                    },
                  })
                );
                gas += 1_100_000;
              } else if (pool.protocol_id == 4) {
                withdrawMsg = JSON.stringify({
                  remove_liquidity: {
                    recipient: SECRET_NETWORK.walletAddress,
                  },
                });
                messages.push(
                  new MsgExecuteContract({
                    sender: SECRET_NETWORK.walletAddress,
                    contract_address:
                      sharesCryptocurrency.smart_contract.address,
                    code_hash: sharesCryptocurrency.smart_contract.data_hash,
                    msg: {
                      send: {
                        recipient: pool.smart_contract.address,
                        amount: amountFormatted,
                        msg: Buffer.from(withdrawMsg).toString("base64"),
                      },
                    },
                  })
                );
                gas += 1_250_000;
              }
              // 2. Send fee to b1 if not vip 5
              let fee = SECRET_NETWORK.buttonSwap.pools.btnGroupFee(
                fromId,
                toId
              );
              if (fee > 0) {
                fee *= 1_000_000;
                let scrt = SECRET_NETWORK.cryptocurrencies[515];
                let sscrt = SECRET_NETWORK.cryptocurrencies[129];
                if (scrt.symbol != "SCRT" || sscrt.symbol != "SSCRT") {
                  throw "Wrong SCRT or SSCRT ids.";
                }
                // a. Wrap SCRT
                let wrapParams = SECRET_NETWORK.getSwapParams(
                  scrt.id,
                  sscrt.id,
                  fee
                );
                messages = messages.concat(wrapParams.messages);
                gas += Number(wrapParams.gas);

                // b. Send SSCRT to admin
                messages.push(
                  new MsgExecuteContract({
                    sender: SECRET_NETWORK.walletAddress,
                    contract_address: sscrt.smart_contract.address,
                    code_hash: sscrt.smart_contract.data_hash,
                    msg: {
                      transfer: {
                        amount: String(fee),
                        recipient: SN_B3_ADDRESS,
                      },
                    },
                  })
                );
                gas += 15_000;
              }
              await SECRET_NETWORK.broadcast(messages, gas);
              // Show success message like the one for the market swap
              document.showAlertSuccess("Liquidity withdrawn.");
              SECRET_NETWORK.buttonSwap.pools.resetForms();
              SECRET_NETWORK.updateWalletBalance(sharesId);
            }
          } catch (error) {
            document.showAlertDanger(error);
          } finally {
            document.enableButton(WITHDRAW_FORM_SUBMIT_BUTTON_SELECTOR);
          }
        };
      },
      // In SCRT
      btnGroupFee: (fromId, toId) => {
        let fromCryptocurrency = SECRET_NETWORK.cryptocurrencies[fromId];
        let toCryptocurrency = SECRET_NETWORK.cryptocurrencies[toId];

        if (
          fromCryptocurrency.symbol == "BUTT" ||
          toCryptocurrency.symbol == "BUTT" ||
          SECRET_NETWORK.userVipLevel == 5
        ) {
          return 0;
        } else if (SECRET_NETWORK.userVipLevel == 4) {
          return 0.01;
        } else if (SECRET_NETWORK.userVipLevel == 3) {
          return 0.02;
        } else if (SECRET_NETWORK.userVipLevel == 2) {
          return 0.03;
        } else if (SECRET_NETWORK.userVipLevel == 1) {
          return 0.04;
        } else {
          return 0.05;
        }
      },
      displayFee: (fromId, toId) => {
        let fee = SECRET_NETWORK.buttonSwap.pools.btnGroupFee(fromId, toId);
        if (fee > 0) {
          $(".pool-btn-group-fee").removeClass("d-none");
          $(".pool-btn-group-fee").text(`btn.group fee: ${fee} SSCRT`);
        } else {
          $(".pool-btn-group-fee").addClass("d-none");
          $(".pool-btn-group-fee").text(`btn.group fee: ${fee} SSCRT`);
        }
      },
      getAndSetRate: async (poolId, fromId, toId) => {
        await SECRET_NETWORK.refreshPoolFromBlockchain(poolId);
        while (
          Object.keys(SECRET_NETWORK.cryptocurrencies).length == 0 ||
          Object.keys(SECRET_NETWORK.tradePairs).length == 0
        ) {
          await document.delay(1_000);
        }
        let pool = SECRET_NETWORK.tradePairs[poolId];
        let fromAssetAmount;
        let toAssetAmount;
        for (let cp of pool.cryptocurrency_pools) {
          if (cp.cryptocurrency_role == "deposit") {
            if (fromId == cp.cryptocurrency_id) {
              fromAssetAmount = cp.amount;
            } else {
              toAssetAmount = cp.amount;
            }
          }
        }
        pool.rate_without_decimals =
          Number(toAssetAmount) / Number(fromAssetAmount);
      },
      resetForms: () => {
        // Provide form
        document.secretNetworkProvideForm.fromAmount.value = "";
        document.secretNetworkProvideForm.toAmount.value = "";
        $(PROVIDE_FROM_USD_PRICE_SELECTOR).text("");
        $(PROVIDE_TO_USD_PRICE_SELECTOR).text("");
        // Withdraw form
        document.secretNetworkWithdrawForm.amount.value = "";
        $("#withdraw-from-amount").text("");
        $("#withdraw-to-amount").text("");
      },
      updateButtons: async (poolId, fromId, toId) => {
        try {
          await $.ajax({
            url: `/pools/${poolId}/button`,
          });
          document.setTokenButtonWithId(
            PROVIDE_FROM_TOKEN_BUTTON_SELECTOR,
            fromId,
            SECRET_NETWORK.cryptocurrencies
          );
          document.setTokenButtonWithId(
            PROVIDE_TO_TOKEN_BUTTON_SELECTOR,
            toId,
            SECRET_NETWORK.cryptocurrencies
          );
          // Don't worry about this, it's always gonna be swap-lp symbol with no attachments
          // document.setTokenButtonWithId(WITHDRAW_TOKEN_BUTTON_SELECTOR, sharesId, SECRET_NETWORK.cryptocurrencies);
        } catch (err) {
          document.showAlertDanger(err);
        }
      },
      updateAfterPoolSelect: async (event) => {
        let newPoolId = event.currentTarget.dataset.poolId;
        let previousPoolId = SECRET_NETWORK.buttonSwap.pools.poolId;
        if (Number(newPoolId) != Number(previousPoolId)) {
          // Set IDS
          SECRET_NETWORK.buttonSwap.pools.poolId = newPoolId;
          SECRET_NETWORK.buttonSwap.pools.fromId =
            event.currentTarget.dataset.poolFromId;
          SECRET_NETWORK.buttonSwap.pools.toId =
            event.currentTarget.dataset.poolToId;
          SECRET_NETWORK.buttonSwap.pools.sharesId =
            event.currentTarget.dataset.poolSharesId;

          // Display fee
          SECRET_NETWORK.buttonSwap.pools.displayFee(
            event.currentTarget.dataset.poolFromId,
            event.currentTarget.dataset.poolToId
          );

          // Update balances
          $(PROVIDE_FROM_BALANCE_CONTAINER_SELECTOR).attr(
            "data-cryptocurrency-id",
            SECRET_NETWORK.buttonSwap.pools.fromId
          );
          $(PROVIDE_FROM_BALANCE_CONTAINER_SELECTOR)
            .find(".balance-view-button")
            .attr(
              "data-cryptocurrency-id",
              SECRET_NETWORK.buttonSwap.pools.fromId
            );
          $(PROVIDE_TO_BALANCE_CONTAINER_SELECTOR).attr(
            "data-cryptocurrency-id",
            SECRET_NETWORK.buttonSwap.pools.toId
          );
          $(PROVIDE_TO_BALANCE_CONTAINER_SELECTOR)
            .find(".balance-view-button")
            .attr(
              "data-cryptocurrency-id",
              SECRET_NETWORK.buttonSwap.pools.toId
            );
          $(WITHDRAW_BALANCE_CONTAINER_SELECTOR).attr(
            "data-cryptocurrency-id",
            SECRET_NETWORK.buttonSwap.pools.sharesId
          );
          $(WITHDRAW_BALANCE_CONTAINER_SELECTOR)
            .find(".balance-view-button")
            .attr(
              "data-cryptocurrency-id",
              SECRET_NETWORK.buttonSwap.pools.sharesId
            );
          await SECRET_NETWORK.buttonSwap.pools.updateButtons(
            newPoolId,
            SECRET_NETWORK.buttonSwap.pools.fromId,
            SECRET_NETWORK.buttonSwap.pools.toId
          );
          SECRET_NETWORK.updateWalletBalance(
            event.currentTarget.dataset.poolFromId
          );
          SECRET_NETWORK.updateWalletBalance(
            event.currentTarget.dataset.poolToId
          );
          SECRET_NETWORK.updateWalletBalance(
            event.currentTarget.dataset.poolSharesId
          );
          SECRET_NETWORK.buttonSwap.pools.resetForms();
          await SECRET_NETWORK.buttonSwap.pools.getAndSetRate(
            newPoolId,
            SECRET_NETWORK.buttonSwap.pools.fromId,
            SECRET_NETWORK.buttonSwap.pools.toId
          );
        }
      },
    };

    SECRET_NETWORK.buttonSwap.init();
    document.activateKeplr();
  }
});
