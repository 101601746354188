// CUSTOM
import { HELPERS } from "./../../application";
import {
  SN_ADDRESS_ALIAS_ADDRESS,
  SN_ADDRESS_ALIAS_DATA_HASH,
  SN_API_KEY_MANAGER_ADDRESS,
  SN_API_KEY_MANAGER_DATA_HASH,
  SN_B3_ADDRESS,
  SN_BUTT_ADDRESS,
  SN_BUTT_ID,
  SN_DEX_AGGREGATOR_ADDRESS,
  SN_DEX_AGGREGATOR_DATA_HASH,
  SN_SHADE_ROUTER_ADDRESS,
  SN_SHADE_ROUTER_DATA_HASH,
} from "./constants";

export const SECRET_NETWORK = {
  apiKey: undefined,
  cryptocurrencies: {},
  environment: "production",
  gettingBlockHeight: false,
  height: 0,
  heightUpdatedAt: undefined,
  keplrConnectedTriggered: false,
  smartContracts: {},
  tradePairs: {},
  settingUserVipLevel: false,
  userVipLevel: 0,
  walletAddress: undefined,
  wrapPaths: {},
  migrationPoolByCryptoId: {},
  addressAlias: {
    fromId: SN_BUTT_ID,
    gettingAfterKeplrConnect: undefined,
    search: async (searchType, searchValue) => {
      let searchParams = { search_type: searchType, search_value: searchValue };
      let queryParams = {
        contract_address: SN_ADDRESS_ALIAS_ADDRESS,
        code_hash: SN_ADDRESS_ALIAS_DATA_HASH,
        query: { search: searchParams },
      };
      return await SECRET_NETWORK.queryContractSmart(queryParams);
    },
    searchAndSetUserAddressAlias: async () => {
      try {
        let result = await SECRET_NETWORK.addressAlias.search(
          "address",
          SECRET_NETWORK.walletAddress
        );
        if (result.attributes) {
          let alias = result.attributes.alias;
          $("#keplr-user-menu-toggle .user-address-alias").text(alias);
          $(
            "#keplr-user-menu-toggle .user-address-alias-container"
          ).removeClass("d-none");
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
  nfts: {
    balance: async (
      owner,
      viewing_key,
      contract_address,
      code_hash = undefined,
      blockHeight = undefined,
      humanized = undefined
    ) => {
      let query = {
        tokens: {
          limit: 340_282_366_920_938_463_463_374_607_431_768_211_455n,
          owner,
          viewing_key,
        },
      };
      let queryParamsTwo = {
        contract_address,
        code_hash,
        query,
      };
      let response = await SECRET_NETWORK.queryContractSmart(
        queryParamsTwo,
        "production",
        1,
        blockHeight
      );
      if (response["viewing_key_error"]) {
        throw response["viewing_key_error"]["msg"];
      }
      let balance = response["token_list"]["tokens"].length;
      if (humanized) {
        balance = document.humanizeStringNumberFromSmartContract(balance, 0);
      }
      return balance;
    },
  },
  tokens: {
    balance: async (
      address,
      key,
      contract_address,
      code_hash = undefined,
      blockHeight = undefined,
      decimals = undefined,
      humanized = undefined
    ) => {
      let queryParams = {
        contract_address,
        code_hash,
        query: {
          balance: {
            address,
            key,
          },
        },
      };
      let balanceResponse = await SECRET_NETWORK.queryContractSmart(
        queryParams,
        "production",
        1,
        blockHeight
      );
      if (balanceResponse["viewing_key_error"]) {
        throw balanceResponse["viewing_key_error"]["msg"];
      }
      let b = balanceResponse.balance.amount;
      if (decimals) {
        if (humanized) {
          b = document.humanizeStringNumberFromSmartContract(b, decimals);
        } else {
          b = document.applyDecimals(b, decimals);
        }
      }
      return b;
    },
    info: async (contract_address, code_hash = undefined) => {
      let tokenInfoResponse = await SECRET_NETWORK.queryContractSmart({
        contract_address,
        code_hash,
        query: { token_info: {} },
      });
      return tokenInfoResponse.token_info;
    },
    transfers: async (
      address,
      key,
      page,
      pageSize,
      tokenAddress,
      tokenDataHash
    ) => {
      let params = {
        transfer_history: {
          address,
          key,
          page,
          page_size: pageSize,
          should_filter_decoys: true,
        },
      };
      let queryParams = {
        code_hash: tokenDataHash,
        contract_address: tokenAddress,
        query: params,
      };
      let transfer_response = await SECRET_NETWORK.queryContractSmart(
        queryParams
      );
      if (transfer_response["viewing_key_error"]) {
        throw transfer_response["viewing_key_error"]["msg"];
      }
      return transfer_response["transfer_history"]["txs"];
    },
    transactions: async (
      address,
      viewing_key,
      page,
      page_size,
      contract_address,
      code_hash
    ) => {
      let params = {
        transaction_history: {
          address,
          viewing_key,
          page,
          page_size,
          should_filter_decoys: true,
        },
      };
      let queryParams = {
        code_hash,
        contract_address,
        query: params,
      };
      let transaction_response = await SECRET_NETWORK.queryContractSmart(
        queryParams
      );
      if (transaction_response["viewing_key_error"]) {
        throw transaction_response["viewing_key_error"]["msg"];
      }
      return transfer_response["transaction_response"]["txs"];
    },
  },
  transactions: {
    transactionsTableId: "#transactions-table",
    extractDescriptionAddressFromTx: function (ownerAddress, tx) {
      let descriptionAddress;
      if (ownerAddress != tx["receiver"]) {
        descriptionAddress = tx["receiver"];
      } else {
        descriptionAddress = tx["from"];
      }
      return descriptionAddress;
    },
    getSmartContractsInTxs: async (ownerAddress, txs) => {
      let addressesString = "";
      let addressesInString = {};
      txs.forEach((tx) => {
        let address =
          SECRET_NETWORK.transactions.extractDescriptionAddressFromTx(
            ownerAddress,
            tx
          );
        if (
          !SECRET_NETWORK.smartContracts[address] ||
          !addressesInString[address]
        ) {
          addressesString = addressesString + address + ",";
          addressesInString[address] = true;
        }
      });

      try {
        let contracts = await $.ajax({
          url: "/smart_contracts?addresses=" + addressesString,
          type: "GET",
        });
        contracts.forEach((smartContract) => {
          SECRET_NETWORK.smartContracts[smartContract["address"]] =
            smartContract;
        });
      } catch (err) {
        console.log(err);
      }
    },
    forTable: (
      txs,
      tokenDecimals,
      accountAddress,
      transactionHistory = false
    ) => {
      let txsForTable = [];
      txs.forEach((tx) => {
        let txForTable = {};
        // id
        txForTable.id = Number(tx.id);
        if (transactionHistory) {
          txForTable.id = Number(tx_id);
        }
        // date
        if (tx["block_time"]) {
          txForTable.date = new Date(Number(tx["block_time"]) * 1_000);
        }
        // amount and descriptionHtml
        txForTable.amount = 1;
        if (transactionHistory) {
          if (accountAddress != tx.action.transfer.recipient) {
            txForTable.amount *= -1;
            txForTable.descriptionHtml = tx.action.transfer.recipient;
          } else {
            txForTable.descriptionHtml = tx.action.transfer.from;
          }
        } else {
          txForTable.amount = Number(tx.coins.amount);
          if (accountAddress != tx.receiver) {
            txForTable.amount *= -1;
            txForTable.descriptionHtml = tx.receiver;
          } else {
            txForTable.descriptionHtml = tx.from;
          }
        }
        txForTable.amount = document.humanizeStringNumberFromSmartContract(
          txForTable.amount,
          tokenDecimals
        );
        let smartContractLabel;
        if (SECRET_NETWORK.smartContracts[txForTable.descriptionHtml]) {
          smartContractLabel =
            SECRET_NETWORK.smartContracts[txForTable.descriptionHtml].label;
        }
        txForTable.descriptionHtml = `<a href="${SECRET_NETWORK.explorerUrl(
          txForTable.descriptionHtml
        )}" target="_blank" rel="noopener">${txForTable.descriptionHtml}</a>`;
        if (smartContractLabel) {
          txForTable.descriptionHtml += `<br>${smartContractLabel}`;
          if (tx.token_id) {
            txForTable.descriptionHtml += ` #${tx.token_id}`;
          }
        }
        txsForTable.push(txForTable);
      });
      return txsForTable;
    },
  },
  allBalances: async (
    environment = "production",
    attempt = 1,
    height = undefined
  ) => {
    try {
      if (!height) {
        height = await SECRET_NETWORK.getBlockHeight();
      }
      let client = await SECRET_NETWORK.client(environment);
      let response = await client.query.bank.allBalances(
        { address: SECRET_NETWORK.walletAddress },
        [["x-cosmos-block-height", height]]
      );
      return response.balances;
    } catch (err) {
      document.errors.push(err);
      if (attempt < 10) {
        if (err.message.includes("future block height")) {
          await document.delay(3_000);
        }
        return await SECRET_NETWORK.allBalances(
          environment,
          attempt + 1,
          height
        );
      } else {
        throw err;
      }
    }
  },
  chainId: function (environment = "production") {
    let chainId = "secret-4";
    if (environment == "staging") {
      chainId = "pulsar-2";
    }
    return chainId;
  },
  client: async (environment) => {
    let chainId = SECRET_NETWORK.chainId(environment);
    let httpUrls = await SECRET_NETWORK.httpUrls(environment);
    if (environment == "staging") {
      if (!SECRET_NETWORK.clientsStaging) {
        SECRET_NETWORK.clientsStaging = [];
        for (const url of httpUrls) {
          let c = new SecretNetworkClient({
            url,
            chainId,
          });
          SECRET_NETWORK.clientsStaging.push(c);
        }
      }
      return _.sample(SECRET_NETWORK.clientsStaging);
    } else {
      if (!SECRET_NETWORK.clientsProduction) {
        SECRET_NETWORK.clientsProduction = [];
        for (const url of httpUrls) {
          let c = new SecretNetworkClient({
            url,
            chainId,
          });
          SECRET_NETWORK.clientsProduction.push(c);
        }
      }
      return _.sample(SECRET_NETWORK.clientsProduction);
    }
  },
  broadcast: async (
    messages,
    gasLimit,
    environment = "production",
    attempt = 1
  ) => {
    let response;
    try {
      let client = await SECRET_NETWORK.signingClient(environment);
      response = await client.tx.broadcast(messages, {
        gasLimit,
      });
      if (response && response.height) {
        SECRET_NETWORK.height = response.height;
        SECRET_NETWORK.heightUpdatedAt = new Date();
      }
      return SECRET_NETWORK.handleResponse(response);
    } catch (err) {
      console.log(err);
      document.errors.push(err);
      if (
        (typeof err == "string" && err.includes("sequence")) ||
        err.message == "" ||
        (err.message &&
          (err.message.includes("Bad status on response: 502") ||
            err.message.includes("Response closed without headers") ||
            err.message.includes("Request failed with status code 429")) &&
          attempt < 30)
      ) {
        if (typeof err == "string" && err.includes("sequence")) {
          document.showAlertWarning("Blockchain issue: Please try again.");
        }
        return await SECRET_NETWORK.broadcast(
          messages,
          gasLimit,
          environment,
          attempt + 1
        );
        // Handle tx not found error
      } else if (err.message && err.message.includes("tx not found")) {
        let a = err.message.split(" ");
        await SECRET_NETWORK.getTx(a[a.length - 1]);
      } else {
        throw err;
      }
    }
  },
  addressValid: async (
    addressToCheck,
    environment = "production",
    error = undefined
  ) => {
    try {
      let client = await SECRET_NETWORK.client(environment);
      let x = await client.query.auth.account({
        address: addressToCheck,
      });
      return x.account.address;
    } catch (err) {
      if (error) {
        err = error;
      }
      throw err;
    }
  },
  executeContract: async (
    params,
    gasLimit,
    environment = "production",
    attempt = 1
  ) => {
    let response;
    try {
      let client = await SECRET_NETWORK.signingClient(environment);
      response = await client.tx.compute.executeContract(params, {
        gasLimit,
      });
      if (response && response.height) {
        SECRET_NETWORK.height = response.height;
        SECRET_NETWORK.heightUpdatedAt = new Date();
      }
      return SECRET_NETWORK.handleResponse(response);
    } catch (err) {
      console.log(err);
      document.errors.push(err);
      if (
        (typeof err == "string" && err.includes("sequence")) ||
        err.message == "" ||
        (err.message &&
          (err.message.includes("Bad status on response: 502") ||
            err.message.includes("Response closed without headers") ||
            err.message.includes("Request failed with status code 429")) &&
          attempt < 30)
      ) {
        if (typeof err == "string" && err.includes("sequence")) {
          document.showAlertWarning("Blockchain issue: Please try again.");
        }
        return await SECRET_NETWORK.executeContract(
          params,
          gasLimit,
          environment,
          attempt + 1
        );
        // Handle tx not found error
      } else if (err.message && err.message.includes("tx not found")) {
        let a = err.message.split(" ");
        await SECRET_NETWORK.getTx(a[a.length - 1]);
      } else {
        throw err;
      }
    }
  },
  explorerUrl: (address) => {
    return `https://www.mintscan.io/secret/account/${address}`;
  },
  extractSwapToId: function (fromId, tradePairId) {
    let x;
    SECRET_NETWORK.tradePairs[tradePairId]["cryptocurrency_pools"].forEach(
      (cryptoPool) => {
        if (
          cryptoPool["cryptocurrency_id"] != Number(fromId) &&
          ["deposit", "receive"].includes(cryptoPool["cryptocurrency_role"])
        ) {
          x = cryptoPool["cryptocurrency_id"];
        }
      }
    );
    return x;
  },
  // This was made to handle tx not found error.
  getTx: async (hash, attempt = 1) => {
    try {
      await document.delay(1_000);
      let client = await SECRET_NETWORK.client();
      return await client.query.getTx(hash);
    } catch (err) {
      if (attempt <= 5) {
        await SECRET_NETWORK.client(hash, attempt + 1);
      } else {
        throw err;
      }
    }
  },
  gasWrap: function () {
    return 70_000;
  },
  getAndSetApiKey: async () => {
    try {
      let buttViewingKey = await window.keplr.getSecret20ViewingKey(
        SECRET_NETWORK.chainId(),
        SN_BUTT_ADDRESS
      );
      let query = {
        api_key: {
          address: SECRET_NETWORK.walletAddress,
          butt_viewing_key: buttViewingKey,
          admin: false,
        },
      };
      let queryParams = {
        contract_address: SN_API_KEY_MANAGER_ADDRESS,
        code_hash: SN_API_KEY_MANAGER_DATA_HASH,
        query,
      };
      SECRET_NETWORK.apiKey = await SECRET_NETWORK.queryContractSmart(
        queryParams
      );
    } catch (err) {
      console.log(err);
    }
  },
  getAndSetCryptocurrencies: async (
    data = "blockchain_id=1&official=true&nft=false&include_attachments=true",
    storeBySmartContractAddress = false
  ) => {
    try {
      let result = await $.ajax({
        data,
        type: "GET",
        url: `/cryptocurrencies?${data}`,
      });
      result.forEach((cryptocurrency) => {
        cryptocurrency.blockchain_cryptocurrencies.forEach((bc) => {
          if (bc.blockchain_id == 1) {
            cryptocurrency.denom = bc.denom;
          }
        });
        SECRET_NETWORK.cryptocurrencies[cryptocurrency["id"]] = cryptocurrency;
        if (storeBySmartContractAddress && cryptocurrency.smart_contract) {
          SECRET_NETWORK.cryptocurrencies[
            cryptocurrency.smart_contract.address
          ] = cryptocurrency;
        }
      });
    } catch (err) {
      document.showAlertDanger(err);
    }
  },
  getAndSetSelectedSwapPath: async (
    fromId,
    toId,
    amount,
    featureObject,
    submitButtonSelector,
    reverse = false
  ) => {
    let currentQueryCount = featureObject.queryCount;
    let fromAmountWithoutDecimals;
    try {
      // 0. Check if number is positive
      if (Number(amount) > 0) {
        // 1. Disable button and tell people we're getting swap paths
        document.disableButton(submitButtonSelector);
        $(submitButtonSelector)
          .find(".loading-status")
          .text("Getting swap paths");

        // 2. Make sure SECRET_NETWORK.trade_pairs is available
        while (Object.keys(SECRET_NETWORK.tradePairs).length == 0) {
          await document.delay(1_000);
        }

        // 3. Put a time delay of 1.5 seconds to factor in key stroke changes
        await document.delay(1_500);

        if (currentQueryCount == featureObject.queryCount) {
          // 4. Set up the featureObjects swapPaths object
          featureObject.swapPaths[fromId] = {};

          // 5. Get and set swap paths
          if (reverse) {
            let toAmountWithoutDecimals =
              document.formatHumanizedNumberForSmartContract(
                amount,
                SECRET_NETWORK.cryptocurrencies[toId].decimals
              );
            featureObject.swapPaths[fromId][toId] =
              await SECRET_NETWORK.getSwapPaths(
                fromId,
                toId,
                undefined,
                toAmountWithoutDecimals
              );
          } else {
            fromAmountWithoutDecimals =
              document.formatHumanizedNumberForSmartContract(
                amount,
                SECRET_NETWORK.cryptocurrencies[fromId].decimals
              );
            featureObject.swapPaths[fromId][toId] =
              await SECRET_NETWORK.getSwapPaths(
                fromId,
                toId,
                fromAmountWithoutDecimals,
                undefined
              );
          }

          // ASYNC check
          if (
            currentQueryCount == featureObject.queryCount &&
            featureObject.swapPaths[fromId][toId]
          ) {
            // 6. Set queryCountStats
            featureObject.queryCountStats.swapPathsCount =
              featureObject.swapPaths[fromId][toId].length;
            featureObject.queryCountStats.swapPathsSimulated = 0;

            // 7. If reverse, we get the fromAmount and simulate with that and set everything at the end. The render results method can be modified for this purpose.
            if (reverse) {
              fromAmountWithoutDecimals =
                featureObject.swapPaths[fromId][toId][0].best_from_amount;
            }

            // 10. Get block height now so that we can do an async check straight after
            await SECRET_NETWORK.getBlockHeight();
            if (currentQueryCount == featureObject.queryCount) {
              $(submitButtonSelector)
                .find(".loading-status")
                .text(
                  `Checking swap paths ${featureObject.queryCountStats.swapPathsSimulated}/${featureObject.queryCountStats.swapPathsCount}`
                );
              featureObject.swapPaths[fromId][toId].forEach(
                async (swapPath) => {
                  let resultOfSwaps = await SECRET_NETWORK.getResultOfSwaps(
                    fromAmountWithoutDecimals,
                    swapPath,
                    currentQueryCount,
                    featureObject
                  );
                  if (currentQueryCount == featureObject.queryCount) {
                    swapPath.resultOfSwaps = parseFloat(resultOfSwaps);
                    swapPath.netUsdResultOfSwaps =
                      HELPERS.swapPaths.netUsdResultOfSwaps(
                        swapPath,
                        SECRET_NETWORK.cryptocurrencies
                      );
                    featureObject.queryCountStats.swapPathsSimulated += 1;
                    $(submitButtonSelector)
                      .find(".loading-status")
                      .text(
                        `Checking swap paths ${featureObject.queryCountStats.swapPathsSimulated}/${featureObject.queryCountStats.swapPathsCount}`
                      );
                  }
                }
              );
              // 11. Wait until all swap paths have been processed
              while (
                featureObject.queryCountStats.swapPathsCount !=
                  featureObject.queryCountStats.swapPathsSimulated &&
                currentQueryCount == featureObject.queryCount
              ) {
                await document.delay(1_000);
              }
              featureObject.swapPaths[fromId][toId] = _.orderBy(
                featureObject.swapPaths[fromId][toId],
                [
                  (o) => o.netUsdResultOfSwaps.toNumber(),
                  (o) => Number(o.resultOfSwaps),
                ],
                ["desc", "desc"]
              );
              featureObject.selectedSwapPath =
                featureObject.swapPaths[fromId][toId][0];
              if (reverse) {
                featureObject.selectedSwapPath.best_from_amount =
                  fromAmountWithoutDecimals;
              }
            }
          }
        }
      }
    } catch (err) {
      if (currentQueryCount == featureObject.queryCount) {
        if (err.status == 503) {
          throw err;
        } else if (err.status != 401) {
          document.showAlertDanger(err);
        }
      }
    } finally {
      if (currentQueryCount == featureObject.queryCount) {
        document.enableButton(submitButtonSelector);
        $(submitButtonSelector).find(".loading-status").text("Loading...");
      }
    }
  },
  getAndSetTradePairsAndWrapPaths: async (
    data = "enabled=true&categories=trade_pair,wrap,migration"
  ) => {
    try {
      let result = await $.ajax({
        data,
        type: "GET",
        url: "/pools",
      });
      result.forEach(async (pool) => {
        SECRET_NETWORK.tradePairs[pool.id] = pool;
        SECRET_NETWORK.tradePairs[pool.smart_contract.address] = pool;
        while (Object.keys(SECRET_NETWORK.cryptocurrencies).length == 0) {
          await document.delay(1_000);
        }
        if (pool.category == "wrap") {
          let nativeId;
          let tokenId;
          pool["cryptocurrency_pools"].forEach((cp) => {
            if (
              SECRET_NETWORK.cryptocurrencies[cp.cryptocurrency_id][
                "smart_contract"
              ] == undefined
            ) {
              nativeId = cp["cryptocurrency_id"];
            } else {
              tokenId = cp["cryptocurrency_id"];
            }
          });
          // Handle multiple wrap to tokens
          if (SECRET_NETWORK.wrapPaths[nativeId]) {
            SECRET_NETWORK.wrapPaths[nativeId].push(tokenId);
          } else {
            SECRET_NETWORK.wrapPaths[nativeId] = [tokenId];
          }
          if (SECRET_NETWORK.wrapPaths[tokenId]) {
            SECRET_NETWORK.wrapPaths[tokenId].push(nativeId);
          } else {
            SECRET_NETWORK.wrapPaths[tokenId] = [nativeId];
          }
        } else if (pool.category == "migration") {
          // Handling one way for shade protocol right now
          let fromId;
          let toId;
          pool["cryptocurrency_pools"].forEach((cp) => {
            if (cp.cryptocurrency_role == "deposit") {
              fromId = cp.cryptocurrency_id;
              SECRET_NETWORK.migrationPoolByCryptoId[cp.cryptocurrency_id] =
                pool;
            } else {
              toId = cp.cryptocurrency_id;
            }
          });
          SECRET_NETWORK.wrapPaths[fromId] = [toId];
        }
      });
    } catch (err) {
      document.showAlertDanger(err);
    }
  },
  getAndSetWallet: async (apiKey, attempt = 1) => {
    try {
      let url = `https://btn.group/api/v1/wallets/555`;
      SECRET_NETWORK.wallet = await $.ajax({
        url: url,
        type: "get",
        dataType: "json",
        beforeSend: function (xhr) {
          xhr.setRequestHeader(
            "Authorization",
            `Bearer ${SECRET_NETWORK.apiKey}`
          );
        },
      });
    } catch (err) {
      if (err.status == 401 && attempt < 5) {
        await SECRET_NETWORK.updateApiKeyOnServer();
        SECRET_NETWORK.getAndSetWallet(apiKey, (attempt += 1));
      }
    }
  },
  getResultOfSwaps: async (
    fromAmountFormatted,
    swapPath,
    currentQueryCount,
    objectWithQueryCount
  ) => {
    fromAmountFormatted = String(fromAmountFormatted);
    let fromId = swapPath.from_id;
    swapPath.simulationResults = [];
    for (const poolId of swapPath.swap_path_as_array) {
      if (currentQueryCount == objectWithQueryCount.queryCount) {
        fromAmountFormatted = await SECRET_NETWORK.querySwapSimulation(
          fromAmountFormatted,
          fromId,
          poolId,
          currentQueryCount,
          objectWithQueryCount
        );
        swapPath.simulationResults.push(fromAmountFormatted);
        fromId = SECRET_NETWORK.extractSwapToId(fromId, poolId);
      }
    }
    if (currentQueryCount == objectWithQueryCount.queryCount) {
      return fromAmountFormatted;
    }
    return "0";
  },
  getSwapPaths: async (
    fromId,
    toId,
    fromAmount = undefined,
    toAmount = undefined
  ) => {
    let noSwapPathsError =
      "No swap paths available. Please try different tokens or different amounts.";
    try {
      let swapPaths;
      let headers = {};
      let url = `/swap_paths?blockchain_id=1&from_id=${fromId}&to_id=${toId}`;
      // if ($("body").attr("data-bg-ak")) {
      //   url = `https://btn.group/api/v1${url}&exclude_comparison_paths=true&`;
      //   headers["Authorization"] = `Bearer ${$("body").attr("data-bg-ak")}`;
      // }
      if (fromAmount) {
        url += `&from_amount=${fromAmount}`;
      } else {
        url += `&to_amount=${toAmount}`;
      }
      swapPaths = await $.ajax({
        url,
        headers,
      });
      if (swapPaths.length) {
        return swapPaths;
      } else {
        throw noSwapPathsError;
      }
    } catch (err) {
      if (err == noSwapPathsError) {
        document.showAlertInfo(err);
      } else {
        document.showAlertDanger(err);
      }
    }
  },
  // #[derive(Serialize, Deserialize, Clone, Debug, PartialEq, JsonSchema)]
  // pub struct Hop {
  //     pub from_token: Token,
  //     pub smart_contract: Option<SecretContract>,
  //     pub redeem_denom: Option<String>,
  //     pub migrate_to_token: Option<SecretContract>,
  //     pub shade_protocol_router_path: Option<Vec<SecretContractForShadeProtocol>>,
  // }
  // The final hop would just be for checking
  hopParams: (
    fromId,
    toId = undefined,
    poolId = undefined,
    wrapTokenId = undefined
  ) => {
    let toCryptocurrency = SECRET_NETWORK.cryptocurrencies[toId];
    let h = {
      hop: {
        from_token: SECRET_NETWORK.fromTokenForHop(fromId, wrapTokenId),
      },
      gas: 0,
    };
    if (wrapTokenId) {
      let wrapToken = SECRET_NETWORK.cryptocurrencies[wrapTokenId];
      h.hop.redeem_denom = wrapToken.denom;
      if (toId) {
        h.gas += SECRET_NETWORK.gasWrap();
      }
    } else if (poolId) {
      let pool = SECRET_NETWORK.tradePairs[poolId];
      h.gas += pool.gas_limit;
      if (pool.category == "migration") {
        h.hop.smart_contract = {
          address: pool.smart_contract.address,
          contract_hash: pool.smart_contract.data_hash,
        };
        h.migrate_to_token = {
          address: toCryptocurrency.smart_contract.address,
          contract_hash: toCryptocurrency.smart_contract.data_hash,
        };
      }
    }
    return h;
  },
  // Quick Farm contract can't handle native tokens
  fromTokenForHop: (id, wrapTokenId = undefined) => {
    let c = SECRET_NETWORK.cryptocurrencies[id];
    let fromToken = {};
    if (c.denom && !c.smart_contract) {
      let wrapToken = SECRET_NETWORK.cryptocurrencies[wrapTokenId];
      fromToken.native = {
        address: wrapToken.smart_contract.address,
        contract_hash: wrapToken.smart_contract.data_hash,
      };
    } else {
      fromToken.snip20 = {
        address: c.smart_contract.address,
        contract_hash: c.smart_contract.data_hash,
      };
    }
    return fromToken;
  },
  getNoTradePairSwapPathAsArray: (fromId, toId) => {
    fromId = Number(fromId);
    toId = Number(toId);
    let sp = [];
    let fromCryptocurrency = SECRET_NETWORK.cryptocurrencies[fromId];
    let toCryptocurrency = SECRET_NETWORK.cryptocurrencies[toId];
    if (fromId != toId) {
      if (
        SECRET_NETWORK.migrationPoolByCryptoId[fromId] &&
        SECRET_NETWORK.extractSwapToId(
          fromId,
          SECRET_NETWORK.migrationPoolByCryptoId[fromId].id
        ) == toId
      ) {
        sp.push(SECRET_NETWORK.migrationPoolByCryptoId[fromId].id);
        // un/wrap only
      } else if (SECRET_NETWORK.wrapPaths[fromId].includes(toId)) {
        if (fromCryptocurrency.smart_contract) {
          sp.push(
            SECRET_NETWORK.tradePairs[fromCryptocurrency.smart_contract.address]
              .id
          );
        } else {
          sp.push(
            SECRET_NETWORK.tradePairs[toCryptocurrency.smart_contract.address]
              .id
          );
        }
        // Version change
        // This should be first an unwrap and then a wrap
      } else if (
        SECRET_NETWORK.wrapPaths[fromId] &&
        SECRET_NETWORK.wrapPaths[toId] &&
        SECRET_NETWORK.wrapPaths[fromId].toString() ==
          SECRET_NETWORK.wrapPaths[toId].toString()
      ) {
        sp.push(
          SECRET_NETWORK.tradePairs[fromCryptocurrency.smart_contract.address]
            .id
        );
        sp.push(
          SECRET_NETWORK.tradePairs[toCryptocurrency.smart_contract.address].id
        );
      }
    }
    return sp;
  },
  getSwapParams: function (
    fromId,
    toId,
    amount,
    estimated_amount = undefined,
    minimum_acceptable_amount = undefined,
    selectedSwapPath = undefined,
    to = undefined
  ) {
    let fromCryptocurrency = SECRET_NETWORK.cryptocurrencies[fromId];
    // Everything goes through the aggregator now
    let gas = 550_000;
    let hops = [];
    let messages = [];
    amount = String(amount);
    let swapPathAsArray = [];
    // It's so bloody complex, I feel like the way we have it with swap path as array makes the most sense
    // Because the swap path pool id will not be present in some situations, we will have to make it take an object some times and handle it
    if (selectedSwapPath) {
      swapPathAsArray = swapPathAsArray.concat(
        SECRET_NETWORK.getNoTradePairSwapPathAsArray(
          fromId,
          selectedSwapPath.from_id
        )
      );
      swapPathAsArray = swapPathAsArray.concat(
        selectedSwapPath.swap_path_as_array
      );
      swapPathAsArray = swapPathAsArray.concat(
        SECRET_NETWORK.getNoTradePairSwapPathAsArray(
          selectedSwapPath.to_id,
          toId
        )
      );
    } else {
      swapPathAsArray = swapPathAsArray.concat(
        SECRET_NETWORK.getNoTradePairSwapPathAsArray(fromId, toId)
      );
    }
    let addHop;
    let hop;
    let pool;
    let wrapTokenId;
    let shadeProtocolRouterHop;
    swapPathAsArray.forEach((poolId) => {
      wrapTokenId = undefined;
      addHop = true;
      hop = {};
      pool = SECRET_NETWORK.tradePairs[poolId];
      gas += pool.gas_limit;
      switch (pool.category) {
        case "migration":
          let migrateToToken =
            SECRET_NETWORK.cryptocurrencies[
              SECRET_NETWORK.extractSwapToId(fromCryptocurrency.id, pool.id)
            ];
          hop.smart_contract = {
            address: pool.smart_contract.address,
            contract_hash: pool.smart_contract.data_hash,
          };
          hop.migrate_to_token = {
            address: migrateToToken.smart_contract.address,
            contract_hash: migrateToToken.smart_contract.data_hash,
          };
          break;
        case "wrap":
          if (fromCryptocurrency.smart_contract) {
            hop.redeem_denom =
              SECRET_NETWORK.cryptocurrencies[
                SECRET_NETWORK.extractSwapToId(fromCryptocurrency.id, pool.id)
              ].denom;
            wrapTokenId = fromCryptocurrency.id;
          } else {
            hop.redeem_denom = fromCryptocurrency.denom;
            wrapTokenId = SECRET_NETWORK.extractSwapToId(
              fromCryptocurrency.id,
              pool.id
            );
          }
          break;
        default:
          hop.smart_contract = {
            address: pool.smart_contract.address,
            contract_hash: pool.smart_contract.data_hash,
          };
          if (pool.protocol_id == 39) {
            shadeProtocolRouterHop = {
              addr: pool.smart_contract.address,
              code_hash: pool.smart_contract.data_hash,
            };
            if (
              hops.length &&
              hops[hops.length - 1].shade_protocol_router_path
            ) {
              hops[hops.length - 1].shade_protocol_router_path.push(
                shadeProtocolRouterHop
              );
              addHop = false;
            } else {
              // Add gas for going through the shade protocol router
              gas += 500_000;
              hop.smart_contract.address = SN_SHADE_ROUTER_ADDRESS;
              hop.smart_contract.contract_hash = SN_SHADE_ROUTER_DATA_HASH;
              hop.shade_protocol_router_path = [shadeProtocolRouterHop];
            }
          }
          break;
      }
      hop.from_token = SECRET_NETWORK.fromTokenForHop(
        fromCryptocurrency.id,
        wrapTokenId
      );
      if (addHop) {
        hops.push(hop);
      }
      fromCryptocurrency =
        SECRET_NETWORK.cryptocurrencies[
          SECRET_NETWORK.extractSwapToId(fromCryptocurrency.id, pool.id)
        ];
    });
    // Final hop is just for confirmation
    hops.push({
      from_token: SECRET_NETWORK.fromTokenForHop(
        fromCryptocurrency.id,
        wrapTokenId
      ),
    });
    let originalFromCryptocurrency = SECRET_NETWORK.cryptocurrencies[fromId];
    if (!to) {
      to = SECRET_NETWORK.walletAddress;
    }
    if (!estimated_amount) {
      estimated_amount = amount;
    }
    if (!minimum_acceptable_amount) {
      minimum_acceptable_amount = amount;
    } else {
      minimum_acceptable_amount =
        document.formatHumanizedNumberForSmartContract(
          minimum_acceptable_amount,
          SECRET_NETWORK.cryptocurrencies[selectedSwapPath.to_id].decimals
        );
    }
    let routeMessage = {
      hops,
      estimated_amount,
      minimum_acceptable_amount,
      to,
    };
    let routeMsgEncoded = Buffer.from(JSON.stringify(routeMessage)).toString(
      "base64"
    );
    if (
      originalFromCryptocurrency.denom &&
      !originalFromCryptocurrency.smart_contract
    ) {
      messages = [
        new MsgExecuteContract({
          sender: to,
          contract_address: SN_DEX_AGGREGATOR_ADDRESS,
          code_hash: SN_DEX_AGGREGATOR_DATA_HASH,
          msg: {
            receive: {
              from: to,
              amount,
              msg: routeMsgEncoded,
            },
          },
          sent_funds: [{ denom: originalFromCryptocurrency.denom, amount }],
        }),
      ];
    } else {
      messages = [
        new MsgExecuteContract({
          sender: to,
          contract_address: originalFromCryptocurrency.smart_contract.address,
          code_hash: originalFromCryptocurrency.smart_contract.data_hash,
          msg: {
            send: {
              amount,
              recipient: SN_DEX_AGGREGATOR_ADDRESS,
              msg: routeMsgEncoded,
            },
          },
        }),
      ];
    }

    return {
      messages,
      gas,
    };
  },
  getBlockHeight: async () => {
    if (SECRET_NETWORK.gettingBlockHeight) {
      while (SECRET_NETWORK.gettingBlockHeight) {
        await document.delay(1_000);
      }
      return SECRET_NETWORK.height;
    }

    try {
      SECRET_NETWORK.gettingBlockHeight = true;
      let threeSecondsInThePast = new Date(new Date().getTime() - 3_000);
      if (
        !SECRET_NETWORK.heightUpdatedAt ||
        threeSecondsInThePast > SECRET_NETWORK.heightUpdatedAt
      ) {
        await SECRET_NETWORK.setBlockHeight();
      }
      return SECRET_NETWORK.height;
    } catch (err) {
      document.showAlertDanger(err);
    } finally {
      SECRET_NETWORK.gettingBlockHeight = false;
    }
  },
  handleResponse: function (response) {
    if (response) {
      if (response["code"] > 0) {
        if (response["jsonLog"]) {
          if (response["jsonLog"]["generic_err"]) {
            throw response["jsonLog"]["generic_err"];
          } else if (response["jsonLog"]["not_found"]) {
            throw response["jsonLog"]["not_found"]["kind"] + " " + "not found";
          } else if (response["jsonLog"]["unauthorized"]) {
            throw "Unauthorized";
          } else {
            throw response["rawLog"];
          }
        } else if (response["rawLog"]) {
          throw response["rawLog"];
        }
      } else {
        return response;
      }
    }
  },
  httpUrls: async (environment) => {
    if (environment == "staging") {
      return [
        "https://api.pulsar.scrttestnet.com",
        "http://testnet.securesecrets.org:1317",
        "https://lcd.testnet.secretsaturn.net",
      ];
    }

    if (!SECRET_NETWORK.cachedHttpUrls) {
      if (SECRET_NETWORK.gettingHttpUrls) {
        while (SECRET_NETWORK.gettingHttpUrls) {
          await document.delay(500);
        }
      } else {
        SECRET_NETWORK.gettingHttpUrls = true;
        let apiEndpoints = await $.ajax({
          url: "/api_endpoints?blockchain_id=1&api_types=lcd&enabled=true",
          type: "GET",
        });
        SECRET_NETWORK.cachedHttpUrls = [];
        apiEndpoints.forEach(function (apiEndpoint) {
          SECRET_NETWORK.cachedHttpUrls.push(apiEndpoint.url);
        });
        SECRET_NETWORK.gettingHttpUrls = false;
      }
    }
    return SECRET_NETWORK.cachedHttpUrls;
  },
  ibcTransfer: async (address, amount, chain) => {
    let chainId = "secret-4";
    await window.keplr.enable(chainId);
    let client = await SECRET_NETWORK.signingClient();
    let source_channel;
    if (chain == "OSMO") {
      source_channel = "channel-1";
    } else {
      throw "IBC transfer to chain not allowed.";
    }
    try {
      const tx = await client.tx.ibc.transfer(
        {
          sender: SN_B3_ADDRESS,
          receiver: address,
          source_channel,
          source_port: "transfer",
          token: stringToCoin(`${amount}uscrt`),
          timeout_timestamp: String(Math.floor(Date.now() / 1000) + 10 * 60), // 10 minutes
        },
        {
          gasLimit: 40_000,
          ibcTxsOptions: {
            resolveResponses: true, // enable IBC responses resolution (defualt)
            resolveResponsesTimeoutMs: 12 * 60 * 1000, // stop checking after 12 minutes (default is 2 minutes)
            resolveResponsesCheckIntervalMs: 15_000, // check every 15 seconds (default)
          },
        }
      );
      if (tx.code !== 0) {
        console.error(
          `failed sending ${amount}uscrt from Secret to Osmosis:`,
          tx.rawLog
        );
      } else {
        try {
          const ibcResp = await tx.ibcResponses[0];
          if (ibcResp.type === "ack") {
            console.log(
              `successfuly sent ${amount} uscrt from Secret to Osmosis!`
            );
          } else {
            console.error(
              `failed sending ${amount} uscrt from Secret to Osmosis: IBC packet timed-out before committed on Osmosis`
            );
          }
        } catch (_error) {
          console.error(
            `timed-out while trying to resolve IBC response for txhash ${tx.transactionHash}`
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
  },
  queryContractSmart: async (
    params,
    environment = "production",
    attempt = 1,
    height = undefined
  ) => {
    let client;
    try {
      client = await SECRET_NETWORK.client(environment);
      if (!height) {
        height = await SECRET_NETWORK.getBlockHeight();
      }
      let response = await client.query.compute.queryContract(params, [
        ["x-cosmos-block-height", height],
      ]);
      if (typeof response == "string") {
        if (response[0] == "{") {
          let responseAsJson = JSON.parse(response);
          if (responseAsJson["generic_err"]) {
            throw responseAsJson["generic_err"]["msg"];
          } else if (responseAsJson["parse_err"]) {
            throw responseAsJson["parse_err"]["msg"];
          } else if (responseAsJson["not_found"]) {
            throw responseAsJson["not_found"]["kind"] + " not found";
          } else {
            throw responseAsJson;
          }
        } else if (response.includes("Error parsing")) {
          throw response;
        }
      }
      return response;
    } catch (err) {
      if (document.errr) {
        document.errr.push(err);
      } else {
        document.errr = [err];
      }
      if (
        err.code == 5 ||
        (err.message &&
          (err.message.includes("contract: not found") ||
            err.message.includes("Response closed without headers") ||
            err.message.includes("The enclave is too busy") ||
            err.message.includes("Bad status on response: 502") ||
            err.message.includes("Bad status on response: 520") ||
            err.message.includes("Query failed with (18)") ||
            err.message.includes("cannot query with height in the future") ||
            err.message.includes("future block height") ||
            err.message.includes("Network request failed") ||
            err.message == "") &&
          attempt < 10)
      ) {
        if (
          err.code == 5 ||
          err.message.includes("contract: not found") ||
          err.message.includes("future block height") ||
          err.message.includes("cannot query with height in the future") ||
          err.message.includes("Network request failed") ||
          err.message == ""
        ) {
          await document.delay(3_000);
        }
        return await SECRET_NETWORK.queryContractSmart(
          params,
          environment,
          attempt + 1,
          height
        );
      } else {
        throw err;
      }
    }
  },
  querySwapSimulation: async (
    fromAmountFormatted,
    fromId,
    poolId,
    currentQueryCount,
    objectWithQueryCount
  ) => {
    if (objectWithQueryCount.simulationSwapResults[poolId] == undefined) {
      objectWithQueryCount.simulationSwapResults[poolId] = {};
    }
    if (
      objectWithQueryCount.simulationSwapResults[poolId][fromId] == undefined
    ) {
      objectWithQueryCount.simulationSwapResults[poolId][fromId] = {};
    }
    // If this exact trade has been done before, return the result
    if (
      objectWithQueryCount.simulationSwapResults[poolId][fromId][
        fromAmountFormatted
      ]
    ) {
      while (
        objectWithQueryCount.simulationSwapResults[poolId][fromId][
          fromAmountFormatted
        ] == "loading" &&
        currentQueryCount == objectWithQueryCount.queryCount
      ) {
        await document.delay(1_000);
      }
      return objectWithQueryCount.simulationSwapResults[poolId][fromId][
        fromAmountFormatted
      ];
    }
    objectWithQueryCount.simulationSwapResults[poolId][fromId][
      fromAmountFormatted
    ] = "loading";
    let pool = SECRET_NETWORK.tradePairs[poolId];
    if (!pool) {
      console.log(poolId);
      return "0";
    }
    if (["migration", "wrap"].includes(pool.category)) {
      objectWithQueryCount.simulationSwapResults[poolId][fromId][
        fromAmountFormatted
      ] = fromAmountFormatted;
      return fromAmountFormatted;
    } else {
      let fromCryptoAddress =
        SECRET_NETWORK.cryptocurrencies[fromId].smart_contract.address;
      let fromCryptoCodeHash =
        SECRET_NETWORK.cryptocurrencies[fromId].smart_contract.data_hash;
      let protocol = await HELPERS.getProtocol(pool.protocol_id);
      let swapMsg;
      let cryptocurrencyPool;
      pool.cryptocurrency_pools.forEach(function (cp) {
        if (cp.cryptocurrency_id == fromId) {
          cryptocurrencyPool = cp;
        }
      });
      if (cryptocurrencyPool.downcase_data_hash_for_swap_simulation) {
        fromCryptoCodeHash = fromCryptoCodeHash.toLowerCase();
      }
      if (["btn_group", "secret_swap"].includes(protocol.identifier)) {
        swapMsg = {
          simulation: {
            offer_asset: {
              info: {
                token: {
                  contract_addr: fromCryptoAddress,
                  token_code_hash: fromCryptoCodeHash,
                  viewing_key: "SecretSwap",
                },
              },
              amount: fromAmountFormatted,
            },
          },
        };
      } else if (["shade_protocol", "sienna"].includes(protocol.identifier)) {
        swapMsg = {
          swap_simulation: {
            offer: {
              token: {
                custom_token: {
                  contract_addr: fromCryptoAddress,
                  token_code_hash: fromCryptoCodeHash,
                  viewing_key: "",
                },
              },
              amount: fromAmountFormatted,
            },
          },
        };
      } else {
        return fromAmountFormatted;
      }
      if (protocol.identifier == "btn_group") {
        swapMsg.simulation.time = Math.round(new Date() / 1_000);
      }
      try {
        let queryParams = {
          contract_address: pool.smart_contract.address,
          code_hash: pool.smart_contract.data_hash,
          query: swapMsg,
        };
        let result = await SECRET_NETWORK.queryContractSmart(
          queryParams,
          "production",
          1,
          SECRET_NETWORK.height
        );
        let resultAmount = "0";
        if (result.return_amount) {
          resultAmount = result.return_amount;
        } else if (result.swap_simulation) {
          resultAmount = result.swap_simulation.result.return_amount;
        } else {
          throw result;
        }
        objectWithQueryCount.simulationSwapResults[poolId][fromId][
          fromAmountFormatted
        ] = resultAmount;
        return resultAmount;
      } catch (error) {
        if (currentQueryCount == objectWithQueryCount.queryCount) {
          if (
            typeof error === "string" &&
            (error.includes("is not managed by this contract.") ||
              error.includes("is not presented in this contract."))
          ) {
            let tokenCodeHash;
            if (cryptocurrencyPool.downcase_data_hash_for_swap_simulation) {
              tokenCodeHash = fromCryptoCodeHash.toUpperCase();
            } else {
              tokenCodeHash = fromCryptoCodeHash.toLowerCase();
            }
            swapMsg = {
              swap_simulation: {
                offer: {
                  token: {
                    custom_token: {
                      contract_addr: fromCryptoAddress,
                      token_code_hash: tokenCodeHash,
                      viewing_key: "",
                    },
                  },
                  amount: fromAmountFormatted,
                },
              },
            };
            queryParams = {
              contract_address: pool.smart_contract.address,
              code_hash: pool.smart_contract.data_hash,
              query: swapMsg,
            };
            let result = await SECRET_NETWORK.queryContractSmart(
              queryParams,
              "production",
              1,
              SECRET_NETWORK.height
            );
            if (result.return_amount) {
              objectWithQueryCount.simulationSwapResults[poolId][fromId][
                fromAmountFormatted
              ] = result.return_amount;
            } else if (result.swap_simulation) {
              objectWithQueryCount.simulationSwapResults[poolId][fromId][
                fromAmountFormatted
              ] = result.swap_simulation.result.return_amount;
            }

            cryptocurrencyPool.downcase_data_hash_for_swap_simulation =
              !cryptocurrencyPool.downcase_data_hash_for_swap_simulation;
            $.ajax({
              url: "/cryptocurrency_pools/" + cryptocurrencyPool.id,
              type: "put",
              data: {
                cryptocurrency_pool: {
                  downcase_data_hash_for_swap_simulation:
                    cryptocurrencyPool.downcase_data_hash_for_swap_simulation,
                },
              },
              dataType: "json",
            });

            return objectWithQueryCount.simulationSwapResults[poolId][fromId][
              fromAmountFormatted
            ];
          } else if (
            typeof error === "string" &&
            error.includes("Trade size must be larger than")
          ) {
            objectWithQueryCount.simulationSwapResults[poolId][fromId][
              fromAmountFormatted
            ] = "0";
            return "0";
          } else {
            document.showAlertDanger(error);
          }
        }
      }
    }
  },
  oneForOneSwap: (fromId, toId) => {
    fromId = Number(fromId);
    toId = Number(toId);
    let x = false;
    if (fromId != toId && SECRET_NETWORK.wrapPaths[fromId]) {
      // 1. wrap/unwrap/migration
      // 2. unwrapping from one version of a token and then wrapping to a different version
      x =
        SECRET_NETWORK.wrapPaths[fromId].includes(toId) ||
        (SECRET_NETWORK.wrapPaths[toId] &&
          SECRET_NETWORK.wrapPaths[fromId].toString() ==
            SECRET_NETWORK.wrapPaths[toId].toString());
    }
    return x;
  },
  refreshPoolFromBlockchain: async (tradePairId) => {
    while (!SECRET_NETWORK.tradePairs[tradePairId]) {
      await document.delay(1_000);
    }
    let tradePair = SECRET_NETWORK.tradePairs[tradePairId];
    if (tradePair.protocol_id && tradePair["cryptocurrency_pools"].length) {
      let protocol = await HELPERS.getProtocol(tradePair.protocol_id);
      let address = tradePair["smart_contract"]["address"];
      let msg = { pool: {} };
      if (protocol.identifier == "sienna") {
        msg = "pair_info";
      }
      try {
        let asset_0_address;
        let asset_1_address;
        let asset_0_amount;
        let asset_1_amount;
        let sharesAmount;
        let queryParams = {
          contract_address: address,
          code_hash: tradePair["smart_contract"]["data_hash"],
          query: msg,
        };
        let result = await SECRET_NETWORK.queryContractSmart(
          queryParams,
          "production",
          1,
          SECRET_NETWORK.height
        );
        if (protocol.identifier == "sienna") {
          asset_0_address =
            result["pair_info"]["pair"]["token_0"]["custom_token"][
              "contract_addr"
            ];
          asset_0_amount = result["pair_info"]["amount_0"];
          asset_1_address =
            result["pair_info"]["pair"]["token_1"]["custom_token"][
              "contract_addr"
            ];
          asset_1_amount = result["pair_info"]["amount_1"];
          sharesAmount = result["pair_info"]["total_liquidity"];
        } else if (protocol.identifier == "secret_swap") {
          if (result["assets"][0]["info"]["token"]) {
            asset_0_address =
              result["assets"][0]["info"]["token"]["contract_addr"];
          } else {
            asset_0_address = "";
          }
          asset_0_amount = result["assets"][0]["amount"];
          if (result["assets"][1]["info"]["token"]) {
            asset_1_address =
              result["assets"][1]["info"]["token"]["contract_addr"];
          } else {
            asset_1_address = "";
          }
          asset_1_amount = result["assets"][1]["amount"];
          sharesAmount = result["total_share"];
        }
        for (let cp of tradePair["cryptocurrency_pools"]) {
          if (cp["cryptocurrency_role"] == "deposit") {
            let cryptocurrency =
              SECRET_NETWORK.cryptocurrencies[cp.cryptocurrency_id];
            let amount;
            if (cryptocurrency["smart_contract"]) {
              if (
                asset_0_address == cryptocurrency["smart_contract"]["address"]
              ) {
                amount = asset_0_amount;
              } else if (
                asset_1_address == cryptocurrency["smart_contract"]["address"]
              ) {
                amount = asset_1_amount;
              }
            } else {
              if (asset_0_address == undefined) {
                amount = asset_0_amount;
              } else if (asset_1_address == undefined) {
                amount = asset_1_amount;
              }
            }
            cp["amount"] = amount;
          } else if (cp["cryptocurrency_role"] == "shares") {
            cp["amount"] = sharesAmount;
          }
        }
      } catch (error) {
        document.showAlertDanger(error);
      }
    }
  },
  setUserBlockExplorerLinks: () => {
    $("#keplr-user-menu-toggle .block-explorers").removeClass("d-none");
    let html = "";
    [["Mintscan", "https://www.mintscan.io/secret/account/"]].forEach(function (
      blockExplorerDetails
    ) {
      html += `<div class="menu-item px-3"><a class="menu-link px-5" href="${
        blockExplorerDetails[1] + SECRET_NETWORK.walletAddress
      }" target="_blank">${blockExplorerDetails[0]}</a></div>`;
    });
    $("#keplr-user-menu-toggle .block-explorers .menu-sub").html(html);
  },
  setBlockHeight: async (attempt = 1) => {
    let response;
    let height;
    let blockTimeInSeconds;
    let oneMinuteInThePast = new Date(new Date().getTime() - 60_000);
    try {
      if (attempt == 1) {
        response = await $.ajax({
          url: "/blocks?blockchain_id=1",
        });
        if (response.length) {
          height = response[0].height;
          blockTimeInSeconds = response[0].time_in_seconds;
        } else {
          throw "Block retrieved is out of sync.";
        }
      } else {
        let client = await SECRET_NETWORK.client();
        response = await client.query.tendermint.getLatestBlock({});
        if (response.block) {
          height = Number(response.block.header.height);
          blockTimeInSeconds = Math.round(
            new Date(response.block.header.time) / 1_000
          );
        } else {
          throw "Error getting block height.";
        }
      }
      if (
        height < SECRET_NETWORK.height ||
        new Date(blockTimeInSeconds * 1000) < oneMinuteInThePast
      ) {
        throw "Block retrieved is out of sync.";
      } else {
        SECRET_NETWORK.height = height;
        SECRET_NETWORK.heightUpdatedAt = new Date();
      }
    } catch (err) {
      if (attempt <= 5) {
        await document.delay(3_000);
        await SECRET_NETWORK.setBlockHeight(attempt + 1);
      } else {
        throw err;
      }
    }
  },
  setUsdTextFromAmount: function (humanizedAmount, cryptocurrencyId, selector) {
    let usdText = "";
    if (Number(humanizedAmount)) {
      usdText = document.amountToCurrency(
        humanizedAmount,
        SECRET_NETWORK.cryptocurrencies[cryptocurrencyId].price
      );
    }
    $(selector).text(usdText);
  },
  setUserVipLevel: async (balanceForVipCalculation) => {
    if (!SECRET_NETWORK.settingUserVipLevel) {
      $("#keplr-user-menu-toggle .vip-level-container").removeClass("d-none");
      SECRET_NETWORK.settingUserVipLevel = true;
      try {
        balanceForVipCalculation = Number(balanceForVipCalculation);

        // Check if the user has the api key
        if (!SECRET_NETWORK.apiKey) {
          await SECRET_NETWORK.getAndSetApiKey();
        }

        // Check if user has a wallet
        if (SECRET_NETWORK.apiKey && !SECRET_NETWORK.wallet) {
          await SECRET_NETWORK.getAndSetWallet(SECRET_NETWORK.apiKey);
        }

        // Check if the user is a vvip
        if (SECRET_NETWORK.wallet) {
          if (SECRET_NETWORK.wallet.vvip) {
            SECRET_NETWORK.userVipLevel = 5;
          } else if (SECRET_NETWORK.wallet.butt_staked) {
            balanceForVipCalculation +=
              SECRET_NETWORK.wallet.butt_staked * 1_000_000;
          }
        }

        // Set userVipLevel for all non VIP users
        if (!(SECRET_NETWORK.wallet && SECRET_NETWORK.wallet.vvip)) {
          SECRET_NETWORK.userVipLevel = 0;
          if (balanceForVipCalculation >= 100_000_000_000) {
            SECRET_NETWORK.userVipLevel = 5;
          } else if (balanceForVipCalculation >= 50_000_000_000) {
            SECRET_NETWORK.userVipLevel = 4;
          } else if (balanceForVipCalculation >= 25_000_000_000) {
            SECRET_NETWORK.userVipLevel = 3;
          } else if (balanceForVipCalculation >= 12_500_000_000) {
            SECRET_NETWORK.userVipLevel = 2;
          } else if (balanceForVipCalculation >= 6_250_000_000) {
            SECRET_NETWORK.userVipLevel = 1;
          }
        }

        // Display vip level
        $("#keplr-user-menu-toggle .vip-level").text(
          SECRET_NETWORK.userVipLevel
        );
      } finally {
        SECRET_NETWORK.settingUserVipLevel = false;
      }
    }
  },
  signingClient: async (environment = "production") => {
    let chainId = SECRET_NETWORK.chainId(environment);
    let httpUrls = await SECRET_NETWORK.httpUrls(environment);
    let keplrOfflineSigner = window.keplr.getOfflineSignerOnlyAmino(chainId);
    let [{ address: myAddress }] = await keplrOfflineSigner.getAccounts();
    if (environment == "staging") {
      if (!SECRET_NETWORK.signingClientsStaging) {
        SECRET_NETWORK.signingClientsStaging = [];
        for (const url of httpUrls) {
          let c = new SecretNetworkClient({
            url,
            chainId,
            wallet: keplrOfflineSigner,
            walletAddress: myAddress,
            encryptionUtils: window.keplr.getEnigmaUtils(chainId),
          });
          SECRET_NETWORK.signingClientsStaging.push(c);
        }
      }
      return _.sample(SECRET_NETWORK.signingClientsStaging);
    } else {
      if (!SECRET_NETWORK.signingClientsProduction) {
        SECRET_NETWORK.signingClientsProduction = [];
        for (const url of httpUrls) {
          let c = new SecretNetworkClient({
            url,
            chainId,
            wallet: keplrOfflineSigner,
            walletAddress: myAddress,
            encryptionUtils: window.keplr.getEnigmaUtils(chainId),
          });
          SECRET_NETWORK.signingClientsProduction.push(c);
        }
      }
      return _.sample(SECRET_NETWORK.signingClientsProduction);
    }
  },
  updateApiKeyOnServer: async () => {
    try {
      await $.ajax({
        url: `https://btn-group-node-js.herokuapp.com/api_keys/check?address=${SECRET_NETWORK.walletAddress}`,
        type: "get",
        dataType: "json",
      });
    } catch (err) {
      if (err.status != 200) {
        document.showAlertDanger(err);
      }
    }
  },
  updateWalletBalance: async (cryptocurrencyId) => {
    let cryptocurrency = SECRET_NETWORK.cryptocurrencies[cryptocurrencyId];
    if (cryptocurrency == undefined) {
      return;
    }

    let $userBalanceContainers = $(
      `.user-balance-container[data-cryptocurrency-id=${cryptocurrencyId}][data-blockchain-id=1]`
    );
    document.disableButton($userBalanceContainers);
    // Set wallet balance to empty
    $userBalanceContainers.find(".balance").text("");
    // Hide sync button
    $userBalanceContainers
      .find(".ready")
      .find(".bi-arrow-repeat")
      .addClass("d-none");
    // Hide balance view button
    $(
      `.balance-view-button[data-cryptocurrency-id=${cryptocurrencyId}][data-blockchain-id=1]`
    ).addClass("d-none");

    let balance;
    let blockHeight;
    let cryptoAddress;
    try {
      await document.connectKeplrWallet(false);
      if (SECRET_NETWORK.walletAddress) {
        blockHeight = await SECRET_NETWORK.getBlockHeight();
        if (
          document.blockchainWalletBalance[1][cryptocurrencyId] == undefined
        ) {
          document.blockchainWalletBalance[1][cryptocurrencyId] = {};
        }
        // If there's no blockheight for that blockchain and cryptocurrency, search that balance
        if (
          document.blockchainWalletBalance[1][cryptocurrencyId].blockHeight ==
            undefined ||
          blockHeight >
            document.blockchainWalletBalance[1][cryptocurrencyId].blockHeight
        ) {
          document.blockchainWalletBalance[1][cryptocurrencyId].balance =
            undefined;
          document.blockchainWalletBalance[1][cryptocurrencyId].blockHeight =
            blockHeight;
          if (cryptocurrency["smart_contract"]) {
            cryptoAddress = cryptocurrency["smart_contract"]["address"];
            let key = await window.keplr.getSecret20ViewingKey(
              SECRET_NETWORK.chainId(),
              cryptoAddress
            );
            balance = await SECRET_NETWORK.tokens.balance(
              SECRET_NETWORK.walletAddress,
              key,
              cryptoAddress,
              cryptocurrency["smart_contract"]["data_hash"],
              blockHeight
            );
          } else {
            let accountDetails = await SECRET_NETWORK.allBalances(
              "production",
              1,
              blockHeight
            );
            accountDetails.forEach(function (balanceDetails) {
              if (cryptocurrency["denom"] == balanceDetails["denom"]) {
                balance = balanceDetails["amount"];
              }
            });
            if (balance == undefined) {
              balance = "0";
            }
          }
          if (
            blockHeight ==
            document.blockchainWalletBalance[1][cryptocurrencyId].blockHeight
          ) {
            document.blockchainWalletBalance[1][cryptocurrencyId].balance =
              balance;
          }
        } else {
          while (
            document.blockchainWalletBalance[1][cryptocurrencyId].balance ==
            undefined
          ) {
            await document.delay(1_000);
          }
          balance =
            document.blockchainWalletBalance[1][cryptocurrencyId].balance;
        }
        if (
          blockHeight ==
          document.blockchainWalletBalance[1][cryptocurrencyId].blockHeight
        ) {
          // Set balance
          let balanceFormatted = document.humanizeStringNumberFromSmartContract(
            balance,
            cryptocurrency["decimals"]
          );
          $userBalanceContainers = $(
            `.user-balance-container[data-cryptocurrency-id=${cryptocurrencyId}][data-blockchain-id=1]`
          );
          // Set wallet balances
          $userBalanceContainers.find(".balance").text(balanceFormatted);
          // Set inputToClickFillTo click listeners
          $userBalanceContainers.each(function (k, v) {
            let $v = $(v);
            let inputToClickFillTo = $v.attr(
              "data-input-selector-to-click-fill-to"
            );
            if (inputToClickFillTo) {
              $v.find(".balance").off("click");
              $v.find(".balance").attr("href", "#");
              $v.find(".balance").on("click", function (e) {
                e.preventDefault();
                $(inputToClickFillTo)
                  .val(balanceFormatted.replace(/,/g, ""))
                  .trigger("input");
              });
            }
          });
          // Show sync button
          $userBalanceContainers
            .find(".ready")
            .find(".bi-arrow-repeat")
            .removeClass("d-none");
          // Set user vip level if crypto is BUTT
          if (cryptocurrencyId == SN_BUTT_ID) {
            $(document).trigger("butt_balance_updated", {});
            SECRET_NETWORK.setUserVipLevel(balance);
          }
        }
      }
    } catch (err) {
      if (
        blockHeight ==
        document.blockchainWalletBalance[1][cryptocurrencyId].blockHeight
      ) {
        console.log(err);
        // Show balance view button
        $(
          `.balance-view-button[data-cryptocurrency-id=${cryptocurrencyId}][data-blockchain-id=1]`
        ).removeClass("d-none");
      }
    } finally {
      if (
        blockHeight ==
        document.blockchainWalletBalance[1][cryptocurrencyId].blockHeight
      ) {
        document.enableButton($userBalanceContainers);
      }
    }
  },
};
