// CUSTOM
import {
  SN_API_KEY_MANAGER_ADDRESS,
  SN_API_KEY_MANAGER_DATA_HASH,
  SN_BUTT_ID,
} from "./../secret_network/constants";
import { HELPERS } from "../../application";
import { SECRET_NETWORK } from "./../secret_network/helpers";

$(document).ready(function () {
  if ($("#account").length) {
    SECRET_NETWORK.account = {
      gettingApiKey: false,
      init: async () => {
        SECRET_NETWORK.account.addListeners();
        document.activateKeplr();
      },
      addListeners: function () {
        $(document).on("butt_balance_updated", async () => {
          $("#butt-staked-butt-balance-container").addClass("d-none");
          $(".api-key-button").removeClass("d-none");
          $(".api-key-button").trigger("click");
        });

        $(document).on("keplr_connected", async () => {
          // get and set BUTT which will also load up VIP
          SECRET_NETWORK.getAndSetCryptocurrencies(
            `blockchain_id=1&nft=false&cryptocurrency_ids=${SN_BUTT_ID}`
          );
          $(".balance-container").removeClass("d-none");
          while (!SECRET_NETWORK.cryptocurrencies[SN_BUTT_ID]) {
            await document.delay(1_000);
          }
          $(".user-balance-container .bi-arrow-repeat").trigger("click");
        });

        $(".api-key-button").on("click", async (e) => {
          if (!SECRET_NETWORK.account.gettingApiKey) {
            SECRET_NETWORK.account.gettingApiKey = true;
            let selector = e.currentTarget;
            document.disableButton(selector);
            try {
              await document.connectKeplrWallet(false);
              if (SECRET_NETWORK.walletAddress) {
                await SECRET_NETWORK.getAndSetApiKey();
                if (!SECRET_NETWORK.apiKey) {
                  let apiKey = await HELPERS.getRandomUUID();
                  let params = {
                    sender: SECRET_NETWORK.walletAddress,
                    contract_address: SN_API_KEY_MANAGER_ADDRESS,
                    code_hash: SN_API_KEY_MANAGER_DATA_HASH,
                    msg: {
                      set_api_key: {
                        api_key: apiKey,
                      },
                    },
                  };
                  await SECRET_NETWORK.executeContract(params, 50_000);
                  SECRET_NETWORK.apiKey = apiKey;
                  await SECRET_NETWORK.updateApiKeyOnServer();
                }
                await SECRET_NETWORK.getAndSetWallet(SECRET_NETWORK.apiKey);
                $(selector).addClass("d-none");
                // Set BUTT staked
                if (SECRET_NETWORK.wallet.butt_staked) {
                  $("#butt-staked").text(
                    BigNumber(SECRET_NETWORK.wallet.butt_staked).toFormat()
                  );
                } else {
                  $("#butt-staked").text(
                    "BUTT staked is updated daily ~7am UTC time."
                  );
                }
              }
            } catch (err) {
              document.showAlertDanger(err);
            } finally {
              SECRET_NETWORK.account.gettingApiKey = false;
              document.enableButton(selector);
            }
          }
        });
      },
    };

    SECRET_NETWORK.account.init();
  }
});
