// Used AB viewing key
// CUSTOM
import { AZ_BUTTON_ADDRESS } from "../aleph_zero/constants";
import { POLKADOTJS } from "../polkadotjs";
import { ALEPH_ZERO } from "../aleph_zero/helpers";
import { ALEPH_ZERO_TOKENS } from "../aleph_zero/tokens";
import { SECRET_NETWORK } from "../secret_network/helpers";
import {
  SN_BUTT_ADDRESS,
  SN_BUTT_DATA_HASH,
  SN_SSCRT_ADDRESS,
  SN_SSCRT_DATA_HASH,
} from "../secret_network/constants";
import { HELPERS } from "../../application";

$(function () {
  if ($("#button-migration").length) {
    const BUTTON_MIGRATION = {
      btnMigration: {
        address: "secret13zux3ta45q3w6srx3mhdw26lqztkusxceckpk7",
        code_hash:
          "d9a03232007761c60ef531a840ad64322e69c3e9af7f685e83240a859308ec98",
      },
      btnDistributorAddress: "5ChpfrUkiBaT39UirNZPVwRx6S1q9ArREyfCPcdh3uGkR8Cj",
      collectableForSmartContract: undefined,
      datatable: $("#orders-table").DataTable({
        columns: [
          {
            data: "created_at_block_time",
            title: "Date",
            fnCreatedCell: function (nTd, sData, _oData, _iRow) {
              let date = new Date(sData * 1000);
              let options = {
                year: "numeric",
                month: "short",
                day: "numeric",
              };
              let dateAsString = date.toLocaleDateString(undefined, options);
              $(nTd).html(`<div style='width: 85px;'>${dateAsString}</div>`);
            },
          },
          {
            data: "to",
            fnCreatedCell: function (nTd, sData, _oData, _iRow) {
              let html = ALEPH_ZERO.linkToExplorer(sData);
              $(nTd).html(`<a href="${html}" target="_blank">${sData}</a>`);
            },
          },
          {
            data: "amount",
            title: "Amount",
            fnCreatedCell: function (nTd, sData, _oData, _iRow) {
              $(nTd).text(
                document.humanizeStringNumberFromSmartContract(sData, 6)
              );
            },
          },
          {
            data: "status",
            fnCreatedCell: function (nTd, sData, _oData, _iRow) {
              $(nTd).html(BUTTON_MIGRATION.statusMap[sData]);
            },
          },
          {
            data: "status",
            title: undefined,
            fnCreatedCell: function (nTd, sData, _oData, _iRow) {
              if (sData == 0) {
                $(nTd).html(
                  '<button class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm cancel-button"><div class="d-none loading"><em aria-hidden="true" class="spinner-grow spinner-grow-sm" role="status"></em></div><div class="ready"><i class="bi bi-trash"></i></div></button>'
                );
              } else {
                $(nTd).html("");
              }
            },
          },
        ],
        createdRow: function (row, data) {
          $(row).attr("data-order-position", data.position);
        },
        drawCallback: function () {
          // Set listeners for button after
          document.querySelectorAll(".cancel-button").forEach((item) => {
            item.addEventListener("click", async (e) => {
              let $buttonSelector = $(e.target).closest(".cancel-button");
              try {
                document.disableButton($buttonSelector);
                // Get position
                let position = $(e.target)
                  .closest("tr")
                  .attr("data-order-position")
                  .toString();
                let params = {
                  sender: SECRET_NETWORK.walletAddress,
                  contract_address: BUTTON_MIGRATION.btnMigration.address,
                  code_hash: BUTTON_MIGRATION.btnMigration.code_hash,
                  msg: { cancel_order: { position } },
                };
                await SECRET_NETWORK.executeContract(params, 200_000);
                BUTTON_MIGRATION.ordersIndex();
                document.showAlertSuccess("Order cancelled.");
              } catch (err) {
                document.showAlertDanger(err);
              } finally {
                document.enableButton($buttonSelector);
              }
            });
          });
        },
        ordering: false,
        paging: false,
        bInfo: false,
      }),
      statusMap: {
        0: "<span class='badge badge-light'>Open</span>",
        // Orange
        1: "<span class='badge badge-light-warning'>Processing</span>",
        // Green
        2: "<span class='badge badge-light-success'>Success</span>",
        // Red
        3: "<span class='badge badge-light-danger'>Cancelled</span>",
      },
      init: async () => {
        BUTTON_MIGRATION.initListeners();
        SECRET_NETWORK.getAndSetCryptocurrencies(
          "blockchain_id=1&official=true&nft=false&include_attachments=true"
        );
        document.activateKeplr();
        ALEPH_ZERO.activatePolkadotJsExtension();
      },
      // https://github.com/polkadot-js/api/blob/master/packages/types/src/types/interfaces.ts
      collectCallBack: (result) => {
        if (!document.results) {
          document.results = [];
        }
        document.results.push(result);
        if (!result.isError && result.isCompleted) {
          BUTTON_MIGRATION.updateCollectable();
          document.showAlertSuccess("Success", true);
          document.enableButton("#collectable-button");
        }
      },
      initListeners: () => {
        $(document).on("keplr_connected", async () => {
          $(".balance-container").removeClass("d-none");
          while (!SECRET_NETWORK.cryptocurrencies[351]) {
            await document.delay(1_000);
          }
          $(".user-balance-container .bi-arrow-repeat").trigger("click");
          BUTTON_MIGRATION.ordersIndex();
        });
        $(document).on("aleph_zero_account_selected", async () => {
          BUTTON_MIGRATION.updateCollectable();
          $("#collectable-button").removeClass("d-none");
          $("#load-from-wallet-button").removeClass("d-none");
        });
        $("#collectable-button a").on("click", async (e) => {
          e.preventDefault();
          document.disableButton("#collectable-button");
          try {
            await BUTTON_MIGRATION.updateCollectable();
            if (Number(BUTTON_MIGRATION.collectableForSmartContract) > 0) {
              const contract = await ALEPH_ZERO.contracts.getContract(
                BUTTON_MIGRATION.btnDistributorAddress
              );
              let api = await ALEPH_ZERO.api();
              let account = ALEPH_ZERO.account;
              api.setSigner(POLKADOTJS.adapter.signer);

              await POLKADOTJS.contractTx(
                api,
                account.address,
                contract,
                "collect",
                undefined,
                undefined,
                BUTTON_MIGRATION.collectCallBack
              );
            }
          } catch (err) {
            document.showAlertDanger(err);
          } finally {
            document.enableButton("#collectable-button");
          }
        });
        $("#load-from-wallet-button").on("click", function (e) {
          e.preventDefault();
          document.buttonMigrationForm.alephZeroAddress.value =
            ALEPH_ZERO.account.address;
        });

        document.buttonMigrationForm.onsubmit = async (e) => {
          e.preventDefault();
          const SUBMIT_BUTTON_SELECTOR =
            'form[name="buttonMigrationForm"] button[type="submit"]';
          document.disableButton(SUBMIT_BUTTON_SELECTOR);
          let alephZeroAddress = HELPERS.removeSpaces(
            document.buttonMigrationForm.alephZeroAddress.value
          );
          document.buttonMigrationForm.alephZeroAddress.value =
            alephZeroAddress;
          let amount = document.buttonMigrationForm.buttAmount.value;
          try {
            if (Number(amount) <= 0) {
              throw "Amount must be greater than 0. Please try again.";
            }
            if (!POLKADOTJS.validateAddress(alephZeroAddress)) {
              throw "Aleph Zero address is invalid.";
            }
            if (new Date() >= new Date("2024-09-19T00:00:00.000Z")) {
              throw "Migration cut off date rached.";
            }
            await document.connectKeplrWallet(true);
            if (SECRET_NETWORK.walletAddress) {
              let createOrderMsg = JSON.stringify({
                create_order: {
                  to: alephZeroAddress,
                },
              });
              let messages = [
                // Create order by sending butt
                new MsgExecuteContract({
                  sender: SECRET_NETWORK.walletAddress,
                  contract_address: SN_BUTT_ADDRESS,
                  code_hash: SN_BUTT_DATA_HASH,
                  msg: {
                    send: {
                      amount: document.formatHumanizedNumberForSmartContract(
                        amount,
                        6
                      ),
                      recipient: BUTTON_MIGRATION.btnMigration.address,
                      msg: Buffer.from(createOrderMsg).toString("base64"),
                    },
                  },
                }),
                // Wrap SCRT to SSCRT
                new MsgExecuteContract({
                  sender: SECRET_NETWORK.walletAddress,
                  contract_address: SN_SSCRT_ADDRESS,
                  code_hash: SN_SSCRT_DATA_HASH,
                  msg: {
                    deposit: {},
                  },
                  sent_funds: [{ denom: "uscrt", amount: "1000000" }],
                }),
                // Send SSCRT to set fee for execution order
                new MsgExecuteContract({
                  sender: SECRET_NETWORK.walletAddress,
                  contract_address: SN_SSCRT_ADDRESS,
                  code_hash: SN_SSCRT_DATA_HASH,
                  msg: {
                    send: {
                      amount: "1000000",
                      recipient: BUTTON_MIGRATION.btnMigration.address,
                      msg: Buffer.from(
                        JSON.stringify({
                          set_execution_fee_for_order: {},
                        })
                      ).toString("base64"),
                    },
                  },
                }),
              ];
              await SECRET_NETWORK.broadcast(
                messages,
                400_000,
                "production",
                1
              );
              // Reset form and show success message
              document.buttonMigrationForm.buttAmount.value = "";
              document.buttonMigrationForm.alephZeroAddress.value = "";
              document.showAlertSuccess("Order created", true);
              // Reset BUTT balance
              $(".user-balance-container .bi-arrow-repeat").trigger("click");
              // Get orders
              BUTTON_MIGRATION.ordersIndex();
            }
          } catch (error) {
            document.showAlertDanger(error);
          } finally {
            document.enableButton(SUBMIT_BUTTON_SELECTOR);
          }
        };
      },
      ordersIndex: async (page = 0, size = 200) => {
        if (SECRET_NETWORK.walletAddress) {
          let buttViewingKey = await window.keplr.getSecret20ViewingKey(
            SECRET_NETWORK.chainId(),
            SN_BUTT_ADDRESS
          );
          if (buttViewingKey) {
            let queryParams = {
              contract_address: BUTTON_MIGRATION.btnMigration.address,
              code_hash: BUTTON_MIGRATION.btnMigration.code_hash,
              query: {
                orders: {
                  address: SECRET_NETWORK.walletAddress,
                  key: buttViewingKey,
                  page: page.toString(),
                  page_size: size.toString(),
                },
              },
            };
            let response = await SECRET_NETWORK.queryContractSmart(queryParams);
            BUTTON_MIGRATION.datatable.clear();
            BUTTON_MIGRATION.datatable.rows.add(response.orders.orders);
            BUTTON_MIGRATION.datatable.columns.adjust().draw();
          }
        }
      },
      updateCollectable: async () => {
        if (ALEPH_ZERO.account) {
          try {
            let walletAddress = ALEPH_ZERO.account.address;
            let collectable = await ALEPH_ZERO_TOKENS.psp22.allowance(
              AZ_BUTTON_ADDRESS,
              BUTTON_MIGRATION.btnDistributorAddress,
              walletAddress
            );
            BUTTON_MIGRATION.collectableForSmartContract =
              document.formatHumanizedNumberForSmartContract(collectable, 0);
            $("#collectable-button a").text(
              POLKADOTJS.humanizeStringNumberFromSmartContract(collectable, 6)
            );
          } catch (err) {
            document.showAlertDanger(err);
          }
        }
      },
    };

    BUTTON_MIGRATION.init();
  }
});
