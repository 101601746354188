// CUSTOM
import {
  SN_ADDRESS_ALIAS_ADDRESS,
  SN_ADDRESS_ALIAS_DATA_HASH,
  SN_B3_ADDRESS,
  SN_BUTT_ADDRESS,
  SN_BUTT_DATA_HASH,
  SN_BUTT_ID,
} from "./constants";
import { HELPERS } from "./../../application";
import { SECRET_NETWORK } from "./helpers";

$(document).ready(function () {
  if ($("#secret-network-address-alias").length) {
    // === CLOUDINARY ===
    var myWidget = cloudinary.createUploadWidget(
      {
        cloudName: "hv5cxagki",
        cropping: true,
        uploadPreset: "yxh7df5b",
        multiple: false,
        maxImageFileSize: 5_000_000,
        resourceType: "image",
      },
      (error, result) => {
        if (!error && result && result.event === "success") {
          console.log("Done! Here is the image info: ", result.info);
          let imageUrl = result.info.secure_url;
          if (result.info.coordinates) {
            let splitUrl = result.info.secure_url.split("upload/");
            let coordinates = result.info.coordinates.custom;
            let transformationString =
              "upload/c_crop,x_" +
              coordinates[0][0] +
              ",y_" +
              coordinates[0][1] +
              ",h_" +
              coordinates[0][2] +
              ",w_" +
              coordinates[0][3] +
              "/";
            imageUrl = splitUrl[0] + transformationString + splitUrl[1];
          }
          $("#avatar-url").val(imageUrl);
        }
      }
    );

    document.getElementById("cloudinary-upload-widget").addEventListener(
      "click",
      function (evt) {
        myWidget.open();
        evt.preventDefault();
      },
      false
    );

    // === LIST ===
    HELPERS.initTokenLists(["token-list"]);

    // === FUNCTIONS ===
    SECRET_NETWORK.addressAlias.init = async () => {
      SECRET_NETWORK.addressAlias.addListeners();
      await SECRET_NETWORK.getAndSetCryptocurrencies();
      document.activateKeplr();
      await SECRET_NETWORK.getAndSetTradePairsAndWrapPaths();
    };

    SECRET_NETWORK.addressAlias.addListeners = async () => {
      HELPERS.listeners.listenForTokenSelect(SECRET_NETWORK.addressAlias);
      document.aliasDeleteForm.onsubmit = async (e) => {
        e.preventDefault();
        document.disableButton(e.submitter);
        try {
          await document.connectKeplrWallet();
          if (SECRET_NETWORK.walletAddress) {
            let alias = $("form[name='aliasDeleteForm'] button").data("alias");
            let params = {
              sender: SECRET_NETWORK.walletAddress,
              contract_address: SN_ADDRESS_ALIAS_ADDRESS,
              code_hash: SN_ADDRESS_ALIAS_DATA_HASH,
              msg: { destroy: { alias: alias } },
            };
            await SECRET_NETWORK.executeContract(params, 200_000);
            $("#secret-network-address-alias-profile").addClass("d-none");
            document.showAlertSuccess("Alias deleted.");
          }
        } catch (err) {
          document.showAlertDanger(err);
        } finally {
          document.enableButton(e.submitter);
        }
      };

      document.aliasCreateForm.onsubmit = async (e) => {
        e.preventDefault();
        document.disableButton(e.submitter);
        $("#fungibleTokenAddress").prop("disabled", true);
        try {
          await document.connectKeplrWallet();
          if (SECRET_NETWORK.walletAddress) {
            let gas = 600_000;
            let messages = [];
            // 1. Swap to BUTT if necessary
            if (SECRET_NETWORK.addressAlias.selectedSwapPath) {
              let swapParams = SECRET_NETWORK.getSwapParams(
                SECRET_NETWORK.addressAlias.fromId,
                SN_BUTT_ID,
                SECRET_NETWORK.addressAlias.selectedSwapPath.best_from_amount,
                "340282366920938463463374607431768211455",
                "100",
                SECRET_NETWORK.addressAlias.selectedSwapPath
              );
              messages = swapParams.messages;
              gas += swapParams.gas;
            }

            // 2. Create Alias
            let alias = document.aliasCreateForm.alias.value;
            let avatarUrl = document.aliasCreateForm.avatarUrl.value;
            let handleMsg = {
              send: {
                amount: "1000000",
                recipient: SN_ADDRESS_ALIAS_ADDRESS,
                msg: Buffer.from(
                  JSON.stringify({
                    create: { alias, avatar_url: avatarUrl },
                  })
                ).toString("base64"),
              },
            };
            const createAliasMsg = new MsgExecuteContract({
              sender: SECRET_NETWORK.walletAddress,
              contract_address: SN_BUTT_ADDRESS,
              code_hash: SN_BUTT_DATA_HASH,
              msg: handleMsg,
            });
            messages.push(createAliasMsg);

            // 3. Send 99 BUTT to admin
            messages.push(
              new MsgExecuteContract({
                sender: SECRET_NETWORK.walletAddress,
                contract_address: SN_BUTT_ADDRESS,
                code_hash: SN_BUTT_DATA_HASH,
                msg: {
                  transfer: {
                    recipient: SN_B3_ADDRESS,
                    amount: "99000000",
                  },
                },
              })
            );
            gas += 175_000;
            await SECRET_NETWORK.broadcast(messages, gas);
            SECRET_NETWORK.addressAlias.showUserProfile(
              SECRET_NETWORK.walletAddress,
              alias,
              avatarUrl
            );
          }
        } catch (err) {
          document.showAlertDanger(err.msg);
        } finally {
          document.enableButton(e.submitter);
          $("#fungibleTokenAddress").prop("disabled", false);
        }
      };

      document.aliasSearchForm.onsubmit = async (e) => {
        e.preventDefault();
        document.disableButton(e.submitter);
        $("#secret-network-address-alias-profile").addClass("d-none");
        try {
          let result = await SECRET_NETWORK.addressAlias.search(
            document.aliasSearchForm.searchType.value,
            document.aliasSearchForm.searchValue.value
          );
          let alias = result["attributes"]["alias"];
          let address = result["attributes"]["address"];
          let avatarUrl = result["attributes"]["avatar_url"];
          SECRET_NETWORK.addressAlias.showUserProfile(
            address,
            alias,
            avatarUrl
          );
        } catch (err) {
          document.showAlertDanger(err, true);
        } finally {
          // Enable form
          document.enableButton(e.submitter);
        }
      };
    };

    SECRET_NETWORK.addressAlias.setFromAmounts = (amount, cryptocurrencyId) => {
      let amountWithDecimals = document.applyDecimals(
        amount,
        SECRET_NETWORK.cryptocurrencies[cryptocurrencyId].decimals
      );
      $("#price")
        .find(".ready")
        .text(
          document.humanizeStringNumberFromSmartContract(amountWithDecimals, 0)
        );
      $("#from-usd-price").text(
        document.amountToCurrency(
          amountWithDecimals,
          SECRET_NETWORK.cryptocurrencies[cryptocurrencyId].price
        )
      );
    };

    SECRET_NETWORK.addressAlias.showUserProfile = (
      address,
      alias,
      avatarUrl
    ) => {
      let explorerUrl = SECRET_NETWORK.explorerUrl(address);
      $("#secret-network-address-alias-profile .card-label").text(alias);
      $("#secret-network-address-alias-profile .card-header a").attr(
        "href",
        explorerUrl
      );
      $("#secret-network-address-alias-profile .card-header a").text(address);
      $("#secret-network-address-alias-profile .card-body img").attr("src", "");
      $("#secret-network-address-alias-profile .card-body img").attr(
        "src",
        avatarUrl
      );
      $("#secret-network-address-alias-profile").removeClass("d-none");
      if (SECRET_NETWORK.walletAddress) {
        $("form[name='aliasDeleteForm'] button").data("alias", alias);
        if (SECRET_NETWORK.walletAddress == address) {
          $("form[name='aliasDeleteForm'] button").removeClass("d-none");
        } else {
          $("form[name='aliasDeleteForm'] button").addClass("d-none");
        }
      }
      $("html, body").animate(
        {
          scrollTop: $("#secret-network-address-alias-profile").offset().top,
        },
        2000
      );
    };

    SECRET_NETWORK.addressAlias.updateAfterTokenSelect = async (event) => {
      if (
        event["currentTarget"]["dataset"]["cryptocurrencyId"] !=
        SECRET_NETWORK.addressAlias.fromId
      ) {
        SECRET_NETWORK.addressAlias.selectedSwapPath = undefined;
        SECRET_NETWORK.addressAlias.fromId =
          event["currentTarget"]["dataset"]["cryptocurrencyId"];
        document.setTokenButton(
          "#fungibleTokenAddress",
          event["currentTarget"]["dataset"]["cryptocurrencySymbol"],
          event["currentTarget"]["dataset"]["cryptocurrencyCloudinaryPublicId"]
        );
        $("#price").find(".loading").removeClass("d-none");
        $("#price").find(".ready").addClass("d-none");
        $("#create-button").prop("disabled", true);
        $("#fungibleTokenAddress").prop("disabled", true);
        try {
          while (Object.keys(SECRET_NETWORK.cryptocurrencies).length == 0) {
            await document.delay(1_000);
          }
          if (SECRET_NETWORK.addressAlias.fromId != SN_BUTT_ID) {
            let results = await SECRET_NETWORK.getSwapPaths(
              SECRET_NETWORK.addressAlias.fromId,
              SN_BUTT_ID,
              undefined,
              105_000_000
            );
            SECRET_NETWORK.addressAlias.selectedSwapPath = results[0];
            SECRET_NETWORK.addressAlias.setFromAmounts(
              SECRET_NETWORK.addressAlias.selectedSwapPath.best_from_amount,
              SECRET_NETWORK.addressAlias.selectedSwapPath.from_id
            );
          } else {
            SECRET_NETWORK.addressAlias.setFromAmounts(
              100_000_000,
              SECRET_NETWORK.addressAlias.fromId
            );
          }
        } catch (err) {
          document.showAlertDanger(err);
        } finally {
          $("#price").find(".loading").addClass("d-none");
          $("#price").find(".ready").removeClass("d-none");
          $("#create-button").prop("disabled", false);
          $("#fungibleTokenAddress").prop("disabled", false);
        }
      }
    };

    SECRET_NETWORK.addressAlias.init();
  }
});
