// === NIGHTLY ===
import { NightlyConnectAdapter } from "@nightlylabs/wallet-selector-polkadot";

// === POLKADOT ===
import { ApiPromise, WsProvider } from "@polkadot/api";
import { ContractPromise } from "@polkadot/api-contract";
import { web3Accounts } from "@polkadot/extension-dapp";
import { BN, bnToBn, hexToU8a, isHex, stringCamelCase } from "@polkadot/util";
import { decodeAddress, encodeAddress } from "@polkadot/keyring";

// === USE INKATHON ===
import {
  contractCallDryRun,
  contractQuery,
  decodeOutput,
  getBalance,
  getGasLimit,
} from "@scio-labs/use-inkathon";

export const POLKADOTJS = {
  adapter: undefined,
  maxU128: "340282366920938463463374607431768211454",
  // https://polkadot.js.org/docs/extension/usage/
  activatePolkadotjsExtension: async () => {
    if ($(".polkadotjs").length) {
      let polkadotConnectButtonSelector = ".polkadot-connect-button";
      $(polkadotConnectButtonSelector).removeClass("d-none");
      document
        .querySelectorAll(polkadotConnectButtonSelector)
        .forEach((item) => {
          item.addEventListener("click", async (evt) => {
            await POLKADOTJS.connectPolkadotjsExtension();
          });
        });
      return await POLKADOTJS.connectPolkadotjsExtension();
    }
  },
  ApiPromise,
  BN,
  connectPolkadotjsExtension: async () => {
    document.disableButton(".polkadot-connect-button");
    try {
      POLKADOTJS.adapter = await NightlyConnectAdapter.build(
        {
          appMetadata: {
            name: "btn.group",
            description: "Protocol on Aleph Zero.",
            icon: "https://docs.nightly.app/img/logo.png",
            // additionalInfo: 'Courtesy of Nightly Connect team'
          },
          network: "AlephZero",
        }
        // { initOnConnect: false, disableModal: false, disableEagerConnect: false }
      );
      await POLKADOTJS.adapter.connect();
      const accounts = await POLKADOTJS.adapter.accounts.get();
      POLKADOTJS.initAccountList(accounts);
      return { accounts };
    } catch (err) {
      document.showAlertDanger(err);
      document.enableButton(".polkadot-connect-button");
    }
  },
  ContractPromise,
  contractQuery,
  // https://github.com/scio-labs/use-inkathon/blob/6f2b5887c8df4e02e8f07744883c3e21b362a913/src/helpers/contractCall.ts#L63
  contractTx: async (
    api,
    account,
    contract,
    method,
    options = {},
    args = [],
    statusCb = undefined
  ) => {
    // Check if account has sufficient balance
    const accountAddress =
      typeof account === "string" ? account : account.address;
    const { freeBalance } = await getBalance(api, accountAddress);
    const hasZeroBalance = !freeBalance || freeBalance.isZero();
    const hasBalanceBelowPassedValue =
      options?.value && freeBalance && freeBalance.lte(bnToBn(options.value));
    if (hasZeroBalance || hasBalanceBelowPassedValue) {
      return Promise.reject({
        errorMessage: "TokenBelowMinimum",
      });
    }

    // Dry run to determine required gas and potential errors
    delete options.gasLimit;
    // Have to convert undefined to null or it defaults to 0x0000000000000...
    if (args.length > 0) {
      args = _.map(args, function (x) {
        if (x) {
          return x;
        } else {
          return null;
        }
      });
    }
    const dryResult = await contractCallDryRun(
      api,
      account,
      contract,
      method,
      options,
      args
    );
    const { isError, decodedOutput } = decodeOutput(
      dryResult,
      contract,
      method
    );
    if (isError)
      return Promise.reject({
        dryResult,
        errorMessage: decodedOutput || "Error",
      });

    // Call actual query/tx & wrap it in a promise
    // Increase gas by 10% and see if that fixes these little unknown errors
    let newRefTime = BigNumber(
      document.formatHumanizedNumberForSmartContract(
        dryResult.gasRequired.toHuman().refTime,
        0
      )
    )
      .times(1.1)
      .dp(0)
      .toString();
    let newProofSize = BigNumber(
      document.formatHumanizedNumberForSmartContract(
        dryResult.gasRequired.toHuman().proofSize,
        0
      )
    )
      .times(1.1)
      .dp(0)
      .toString();
    const gasLimit = getGasLimit(api, newRefTime, newProofSize);

    return new Promise(async (resolve, reject) => {
      try {
        const isDevelopment =
          (api.runtimeChain || "").toLowerCase() === "development"
            ? "isInBlock"
            : "isFinalized";
        const finalStatus = isDevelopment ? "isInBlock" : "isFinalized";
        const asFinalStatus = isDevelopment ? "asInBlock" : "asFinalized";

        const tx = contract.tx[stringCamelCase(method)](
          { ...options, gasLimit },
          ...args
        );

        const unsub = await tx.signAndSend(account, async (result) => {
          result.options = options;
          statusCb?.(result);

          const isFinalized = result?.status?.[finalStatus];
          if (!isFinalized) return;

          // Determine extrinsic and block info
          const extrinsicHash = result.txHash.toHex();
          const extrinsicIndex = result.txIndex;
          const blockHash = result.status[asFinalStatus].toHex();

          const errorEvent = result?.events.find(({ event }) =>
            api.events.system.ExtrinsicFailed.is(event)
          );
          if (errorEvent) {
            // Reject if `ExtrinsicFailed` event was found
            // This was from inkathon but if I reject this
            // document.showAlertDanger can't handle it right now
            // so just throw a generic message like it would
            // in inkathon code.
            // reject({
            //   dryResult,
            //   errorMessage: decodeOutput || "ExtrinsicFailed",
            //   errorEvent,
            //   extrinsicHash,
            //   extrinsicIndex,
            //   blockHash,
            // });
            reject({
              errorMessage: "Error. Please try again.",
            });
            unsub?.();
          } else {
            // Resolve succesfully otherwise
            const successEvent = result?.events.find(({ event }) =>
              api.events.system.ExtrinsicSuccess.is(event)
            );

            resolve({
              decodedOutput,
              dryResult,
              result,
              successEvent,
              extrinsicHash,
              extrinsicIndex,
              blockHash,
            });
            unsub?.();
          }
        });
        // This seems to only catch errors thrown fron the sign and send message
        // like when user cancels
      } catch (e) {
        let errorMessage = "Error";

        if (e?.message?.match(/user reject request/i)) {
          errorMessage = "UserCancelled";
        }

        const errorText = e?.toString?.();
        const rpcErrorCode =
          errorText && typeof errorText === "string"
            ? errorText.match(/RpcError: (\d+):/i)?.[1]
            : null;
        switch (rpcErrorCode) {
          case "1010":
            errorMessage = "TokenBelowMinimum";
            break;
          default:
            break;
        }

        reject({ errorMessage });
      }
    });
  },
  getBalance,
  humanizeStringNumberFromSmartContract: (number, decimals) => {
    return document.humanizeStringNumberFromSmartContract(
      document.formatHumanizedNumberForSmartContract(number, 0),
      decimals
    );
  },
  initAccountList: (accounts) => {
    $("#polkadot-account-list .list").html("");
    _.sortBy(accounts, ["name"]).forEach(function (account) {
      $("#polkadot-account-list .list").append(
        `<li class='bg-hover-light p-0' data-account-address= '${account.address}', data-account-name= '${account.name}', data-account-source= '${POLKADOTJS.adapter.selectedWallet.name}'><a href='#' class='d-flex align-items-center'><div class='h-40px w-40px text-center me-3'><img class="h-100" src='${POLKADOTJS.adapter.selectedWallet.image.default}'></div><div class="d-block"><div class="fw-bold text-gray-900">${account.name}</div><div class="fs-4 text-muted fw-semibold"><div class="lh-base">${account.address}</div></div></div>`
      );
    });
    if (accounts.length) {
      $("#change-account-link").removeClass("d-none");
    } else {
      $("#change-account-link").addClass("d-none");
    }
    // Enable clicking change button
    $("#change-account-link").on("click", function (e) {
      e.preventDefault();
      $("#polkadot-account-list").modal("show");
    });
  },
  listenForAccountSelect: function (scope) {
    $("#polkadot-account-list li").on("click", function (e) {
      e.preventDefault();
      $("#polkadot-account-list").modal("hide");
      scope.updateAfterAccountSelect(e);
    });
  },
  // https://polkadot.js.org/docs/util-crypto/examples/validate-address
  validateAddress: function (address, errorMessage = undefined) {
    try {
      encodeAddress(
        isHex(address) ? hexToU8a(address) : decodeAddress(address)
      );
      return true;
    } catch (error) {
      if (errorMessage) {
        throw errorMessage;
      } else {
        return false;
      }
    }
  },
  web3Accounts,
  WsProvider,
};
