export const SN_ADDRESS_ALIAS_ADDRESS =
  "secret19993tt7657ljrzt27dh8wm7kxfedgezyuva96w";
export const SN_ADDRESS_ALIAS_DATA_HASH =
  "D3194D7CEBE185E50C4D3CD3CF40827F58DFC48971EE330087CEFA8395FA0B6E";
export const SN_API_KEY_MANAGER_ADDRESS =
  "secret1al2aq2up4jtl89gjx4gujd830rf6thpf4eac45";
export const SN_API_KEY_MANAGER_DATA_HASH =
  "4179f6f66f5fa743aeb5c4bf6fefd1cc0a64b25ae7e23420498e3895324d353b";
export const SN_B3_ADDRESS = "secret1wgfe52tz8hthe236nh28y0qac4df9yg2qdmrpr";
export const SN_BUTT_ADDRESS = "secret1yxcexylwyxlq58umhgsjgstgcg2a0ytfy4d9lt";
export const SN_BUTT_DATA_HASH =
  "F8B27343FF08290827560A1BA358EECE600C9EA7F403B02684AD87AE7AF0F288";
export const SN_BUTT_ID = 351;
export const SN_BUTT_LODE_ADDRESS =
  "secret1l9msv9yu7mgxant4stu89p0hqugz6j2frj7ne5";
export const SN_BUTT_LODE_DATA_HASH =
  "99F94EDC0D744B35A8FBCBDC8FB71C140CFA8F3F91FAD8C35B7CC37862A4AC95";
export const SN_DEX_AGGREGATOR_ADDRESS =
  "secret1wj2nznzwemck9emsl945z3zaslsuxstyelfdmq";
export const SN_DEX_AGGREGATOR_DATA_HASH =
  "bcf6ba0026d7ea8bcb1f1099978de716276aa8663c3f99ae38aac7c6af73838f";
export const SN_LIMIT_ORDERS_ADDRESS =
  "secret1z0fgavte3rzzqqghy6sdtst8er5g2az724vywr";
export const SN_LIMIT_ORDERS_DATA_HASH =
  "8717A3BB08CEC0ECA0C3387EBA34F299E41FA1BF40880E3981168FEEC0E44248";
export const SN_MOUNT_DOOM_ADDRESS =
  "secret177e9pz4heqx3jtrxav3cqrq7jvp7uthhayk8uq";
export const SN_MOUNT_DOOM_DATA_HASH =
  "8669D5303F367DEBA976820B36A89A80B88B4F0574C690FA7209D51C6BD18A53";
export const SN_QUICK_FARM_ADDRESS =
  "secret10j4ldcd428d38uvvq4u3m4sarr9ysxpepnfzq0";
export const SN_QUICK_FARM_DATA_HASH =
  "A26691E854E01D6C92C1455D406AFBC49293CB45024130A98578C8C148F2F9B8";
export const SN_BUTT_SWBTC_FARM_ADDRESS =
  "secret1725s6smzds6h89djq9yqrtlqfepnxruc3m4fku";
export const SN_SHADE_ROUTER_ADDRESS =
  "secret1pjhdug87nxzv0esxasmeyfsucaj98pw4334wyc";
export const SN_SHADE_ROUTER_DATA_HASH =
  "448E3F6D801E453E838B7A5FBAA4DD93B84D0F1011245F0D5745366DADAF3E85";
export const SN_SSCRT_ADDRESS = "secret1k0jntykt7e4g3y88ltc60czgjuqdy4c9e8fzek";
export const SN_SSCRT_DATA_HASH =
  "AF74387E276BE8874F07BEC3A87023EE49B0E7EBE08178C49D0A49C3C98ED60E";
export const VIP_LEVELS = {
  0: {
    commission: 5,
    tradingFee: 0.1,
  },
  1: {
    commission: 4,
    tradingFee: 0.08,
  },
  2: {
    commission: 3,
    tradingFee: 0.06,
  },
  3: {
    commission: 2,
    tradingFee: 0.04,
  },
  4: {
    commission: 1,
    tradingFee: 0.2,
  },
  5: {
    commission: 0,
    tradingFee: 0,
  },
};
