// CUSTOM
import { SECRET_NETWORK } from "./helpers";

$(document).ready(function () {
  if ($("#secret-network-smart-contract-interface").length) {
    document.activateKeplr();
    let paramCount = 0;

    // === LISTENERS ===
    $("#add-new-param").on("click", function (event) {
      event.preventDefault();
      $("#params-container").append(
        '<div class="card param-container mb-5 border bg-light-secondary"><div class="card-body"><div class="row"><div class="col-8"><div class="mb-5"><label class="form-label" for="param-' +
          paramCount +
          '-key">Key</label><input autocomplete="off" class="form-control" id="param-' +
          paramCount +
          '-key"></div></div><div class="col-4"><a href="#" class="bi bi-x float-end"></a></div><div class="col-8"><div class="mb-5"><label class="form-label" for="param-' +
          paramCount +
          '-value">Value</label><input autocomplete="off" class="form-control" id="param-' +
          paramCount +
          '-value"></div></div><div class="col-4"><div class="mb-5"><label class="form-label" for="param-' +
          paramCount +
          '-type">Type</label><select class="form-select" id="param-' +
          paramCount +
          '-type"><option value="raw">raw</option><option selected value="string">text / string</option></select></div></div></div></div></div>'
      );
      $(".param-container .bi-x").on("click", function () {
        this.closest(".param-container").remove();
      });
      paramCount++;
    });

    $("#handle").on("change", function () {
      $("#block-height-input-container").addClass("d-none");
    });

    $("#query").on("change", function () {
      $("#block-height-input-container").removeClass("d-none");
    });

    document.secretNetworkSmartContractInterfaceForm.onsubmit = async (e) => {
      e.preventDefault();
      // Disable form
      document.disableButton(e.submitter);
      $("#result-container").addClass("d-none");
      try {
        // Set params
        let blockHeight =
          document.secretNetworkSmartContractInterfaceForm.blockHeight.value;
        let contractAddress =
          document.secretNetworkSmartContractInterfaceForm.contractAddress
            .value;
        let functionName =
          document.secretNetworkSmartContractInterfaceForm.functionName.value;
        let params = {};
        let last_key;
        $("#params-container input, #params-container select").each(function (
          index
        ) {
          if (index % 3 == 0) {
            last_key = this.value;
          } else if (index % 3 == 1) {
            if (last_key.length) {
              if (this.value.length) {
                params[last_key] = this.value;
              }
            }
          } else {
            if (this.value == "raw") {
              if (last_key.length) {
                if (params[last_key].length) {
                  params[last_key] = JSON.parse(params[last_key]);
                }
              }
            }
          }
        });

        // Interact with smart contract
        let result;
        if (
          document.secretNetworkSmartContractInterfaceForm.interactionType
            .value == "query"
        ) {
          result = await this.querySmartContract(
            contractAddress,
            functionName,
            params,
            blockHeight
          );
        } else {
          let msg = { [functionName]: params };
          await document.connectKeplrWallet();
          if (SECRET_NETWORK.walletAddress) {
            let params = {
              sender: SECRET_NETWORK.walletAddress,
              contract_address: contractAddress,
              msg,
            };
            result = await SECRET_NETWORK.executeContract(
              params,
              100_000,
              SECRET_NETWORK.environment
            );

            // This is from block locker to show the handle response properly
            // The issue with implementing is that result.tx.body.messages is an array so it's hard to figure out how to break it down...
            // Leave it for now until required.
            // let resultText = "";
            // result.data[0].forEach(function (x) {
            //   resultText += String.fromCharCode(x);
            // });
            // result = JSON.parse(resultText);
            // this.displayResult(result);
          }
        }

        // Display results
        $("#result-value").text(document.prettyPrintJSON(result));
        $("#result-container").removeClass("d-none");
        $("html, body").animate(
          {
            scrollTop: $("#result-container").offset().top,
          },
          2000
        );
      } catch (err) {
        document.showAlertDanger(err);
      } finally {
        // Enable form
        document.enableButton(e.submitter);
      }
    };

    this.querySmartContract = async (
      contractAddress,
      functionName,
      params,
      blockHeight,
      msgToHaveOuterHashBrackets = true,
      retryCount = 0
    ) => {
      try {
        let msg;
        if (msgToHaveOuterHashBrackets) {
          msg = { [functionName]: params };
        } else {
          msg = functionName;
        }
        let client = await SECRET_NETWORK.client();
        let codeHashResponse =
          await client.query.compute.codeHashByContractAddress({
            contract_address: contractAddress,
          });
        console.log(codeHashResponse.code_hash);
        let queryParams = {
          contract_address: contractAddress,
          code_hash: codeHashResponse.code_hash,
          query: msg,
        };
        return await SECRET_NETWORK.queryContractSmart(
          queryParams,
          "production",
          1,
          blockHeight
        );
      } catch (err) {
        if (err.includes("Invalid type") && retryCount == 0) {
          return this.querySmartContract(
            contractAddress,
            functionName,
            params,
            blockHeight,
            !msgToHaveOuterHashBrackets,
            retryCount + 1
          );
        } else {
          throw err;
        }
      }
    };
  }
});
