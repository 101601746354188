// CUSTOM
import { HELPERS } from "./../../application";
import { SN_SSCRT_ADDRESS, SN_SSCRT_DATA_HASH } from "./constants";
import { SECRET_NETWORK } from "./helpers";
import { TRANSACTIONS_TABLE } from "./../application/_transactions_table";

$(document).ready(function () {
  if ($("#secret-network-transactions").length) {
    SECRET_NETWORK.transactions.tokenAddress = SN_SSCRT_ADDRESS;
    SECRET_NETWORK.transactions.tokenDataHash = SN_SSCRT_DATA_HASH;
    SECRET_NETWORK.transactions.nft = false;

    // === LIST ===
    HELPERS.initTokenLists(["tokens-modal-list"]);
    HELPERS.listeners.listenForTokenSelect(SECRET_NETWORK.transactions);

    // === TRANSACTIONS TABLE ===
    TRANSACTIONS_TABLE.init(SECRET_NETWORK.transactions.transactionsTableId);

    // === LISTENERS ===
    $("#address").on("change", function () {
      $("#viewing-key-input").val("");
    });
    $(document).on("keplr_connected", async () => {
      $(".load-from-keplr-button").removeClass("d-none");
    });

    document
      .querySelector(".load-from-keplr-button")
      .addEventListener("click", async () => {
        document.disableButton(".load-from-keplr-button");
        try {
          await document.connectKeplrWallet(false);
          if (SECRET_NETWORK.walletAddress) {
            let tokenAddress;
            tokenAddress = SECRET_NETWORK.transactions.tokenAddress;
            if (!SECRET_NETWORK.transactions.tokenAddress) {
              tokenAddress =
                document.secretNetworkTransactionsForm.otherTokenAddress.value;
            }
            document.secretNetworkTransactionsForm.address.value =
              SECRET_NETWORK.walletAddress;
            let key = await window.keplr.getSecret20ViewingKey(
              SECRET_NETWORK.chainId(),
              tokenAddress
            );
            document.secretNetworkTransactionsForm.viewingKey.value = key;
          }
        } catch (err) {
          if (err["message"].includes("There is no matched secret20")) {
            document.showAlertInfo("No viewing key in wallet for this token.");
          } else {
            document.showAlertDanger(err);
          }
        } finally {
          document.enableButton(".load-from-keplr-button");
        }
      });

    // === FUNCTIONS ===
    SECRET_NETWORK.transactions.updateAfterTokenSelect = async (event) => {
      $("#viewing-key-input").val("");
      let cloudinaryPublicId =
        event["currentTarget"]["dataset"]["cryptocurrencyCloudinaryPublicId"];
      let symbol = event["currentTarget"]["dataset"]["cryptocurrencySymbol"];
      if (!cloudinaryPublicId) {
        cloudinaryPublicId = "external-content.duckduckgo-1_memqe7";
      }
      SECRET_NETWORK.transactions.nft =
        event["currentTarget"]["dataset"]["cryptocurrencyNft"] == "true";
      SECRET_NETWORK.transactions.tokenAddress =
        event["currentTarget"]["dataset"]["cryptocurrencyAddress"];
      SECRET_NETWORK.transactions.tokenDataHash =
        event["currentTarget"]["dataset"]["cryptocurrencyDataHash"];
      document.setTokenButton("#token-button", symbol, cloudinaryPublicId);
      if (symbol == "OTHER") {
        $(".other-field-item").removeClass("d-none");
      } else {
        $(".other-field-item").addClass("d-none");
      }
    };

    document.secretNetworkTransactionsForm.onsubmit = async (e) => {
      e.preventDefault();
      document.disableButton(e.submitter);
      let address = document.secretNetworkTransactionsForm.address.value;
      let page = parseFloat(
        document.secretNetworkTransactionsForm.page.value - 1
      );
      let pageSize = parseFloat(
        document.secretNetworkTransactionsForm.pageSize.value
      );
      let tokenAddress;
      let tokenDecimals = 0;
      let tokenDataHash;
      let tokenSymbol;
      let txs;
      let viewingKey = document.secretNetworkTransactionsForm.viewingKey.value;
      tokenAddress = SECRET_NETWORK.transactions.tokenAddress;
      tokenDataHash = SECRET_NETWORK.transactions.tokenDataHash;
      if (!tokenAddress) {
        tokenAddress =
          document.secretNetworkTransactionsForm.otherTokenAddress.value;
      }

      try {
        // Reset transactions table and balance
        TRANSACTIONS_TABLE.reset(
          SECRET_NETWORK.transactions.transactionsTableId
        );

        // Get txs
        if (SECRET_NETWORK.transactions.nft) {
          txs = await SECRET_NETWORK.tokens.transactions(
            address,
            viewingKey,
            page,
            pageSize,
            tokenAddress,
            tokenDataHash
          );
        } else {
          // Get the token info
          let tokenInfo = await SECRET_NETWORK.tokens.info(
            tokenAddress,
            tokenDataHash
          );
          tokenDecimals = tokenInfo.decimals;
          tokenSymbol = tokenInfo.symbol;
          txs = await SECRET_NETWORK.tokens.transfers(
            address,
            viewingKey,
            page,
            pageSize,
            tokenAddress,
            tokenDataHash
          );
        }

        // Format the txs for the table
        await SECRET_NETWORK.transactions.getSmartContractsInTxs(address, txs);
        let transactionsForTable;
        if (SECRET_NETWORK.transactions.nft) {
          transactionsForTable = SECRET_NETWORK.transactions.forTable(
            txs,
            tokenDecimals,
            address,
            true
          );
        } else {
          transactionsForTable = SECRET_NETWORK.transactions.forTable(
            txs,
            tokenDecimals,
            address
          );
        }

        // Get the balance for the token
        let balance;
        if (SECRET_NETWORK.transactions.nft) {
          balance = await SECRET_NETWORK.nfts.balance(
            address,
            viewingKey,
            tokenAddress,
            tokenDataHash,
            undefined,
            true
          );
        } else {
          balance = await SECRET_NETWORK.tokens.balance(
            address,
            viewingKey,
            tokenAddress,
            tokenDataHash,
            undefined,
            tokenDecimals,
            true
          );
        }
        // Display results
        TRANSACTIONS_TABLE.render(
          SECRET_NETWORK.transactions.transactionsTableId,
          balance,
          tokenSymbol,
          transactionsForTable
        );
      } catch (err) {
        let errorDisplayMessage = err;
        if (err.message) {
          errorDisplayMessage = err.message;
        }
        if (errorDisplayMessage.includes("decoding bech32 failed")) {
          errorDisplayMessage = "Token contract address is invalid.";
        }
        if (
          errorDisplayMessage.includes(
            '{"generic_err":{"msg":"canonicalize_address errored: invalid checksum"}'
          )
        ) {
          errorDisplayMessage = "Wallet address is invalid.";
        }
        document.showAlertDanger(errorDisplayMessage);
      } finally {
        document.enableButton(e.submitter);
      }
    };
    document.activateKeplr();
  }
});
