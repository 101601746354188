// CUSTOM
import {
  SN_BUTT_ADDRESS,
  SN_BUTT_DATA_HASH,
  SN_BUTT_LODE_ADDRESS,
  SN_BUTT_LODE_DATA_HASH,
} from "./constants";
import { SECRET_NETWORK } from "./helpers";

$(document).ready(function () {
  if ($("#secret-network-butt-lode").length) {
    this.admin;
    this.buttonBalance;
    this.receivableAddress;

    this.getAndSetConfig = async () => {
      try {
        let queryParams = {
          contract_address: SN_BUTT_LODE_ADDRESS,
          code_hash: SN_BUTT_LODE_DATA_HASH,
          query: { config: {} },
        };
        let result = await SECRET_NETWORK.queryContractSmart(queryParams);
        this.admin = result["admin"];
        $("#admin-table-data").text(this.admin);
        if (result["new_admin_nomination"]) {
          $("#new-admin-nomination-table-data").text(
            result["new_admin_nomination"]
          );
          $("#admin-change-allowed-from-table-data").text(
            new Date(result["admin_change_allowed_from"] * 1_000)
          );
        } else {
          $("#new-admin-nomination-table-data").text("nil");
          $("#admin-change-allowed-from-table-data").text("nil");
        }
        if (result["new_receivable_address_nomination"]) {
          $("#new-receivable-address-nomination-table-data").text(
            result["new_receivable_address_nomination"]
          );
          $("#receivable-address-change-allowed-from-table-data").text(
            new Date(result["receivable_address_change_allowed_from"] * 1_000)
          );
        } else {
          $("#new-receivable-address-nomination-table-data").text("nil");
          $("#receivable-address-change-allowed-from-table-data").text("nil");
        }
        this.receivableAddress = result["receivable_address"];
        $("input[name=receivableAddress]").first().val(this.receivableAddress);
        $("#receivable-address-table-data").text(this.receivableAddress);
        this.buttonBalance = await SECRET_NETWORK.tokens.balance(
          SN_BUTT_LODE_ADDRESS,
          "DoTheRightThing.",
          SN_BUTT_ADDRESS,
          SN_BUTT_DATA_HASH
        );
        // Display results
        $("#fungible-results-inner-container").removeClass("d-none");
        this.buttonBalance = document.humanizeStringNumberFromSmartContract(
          this.buttonBalance,
          6
        );
        $("#butt-balance-table-data").text(this.buttonBalance);
        $("#butt-amount").val(this.buttonBalance);
        document.buttLodeChangeReceivableAddressForm.receivableAddress.value =
          result["new_receivable_address_nomination"];
      } catch (err) {
        document.showAlertDanger(err);
      }
    };

    document.buttLodeChangeReceivableAddressForm.onsubmit = async (e) => {
      e.preventDefault();
      let buttonSelector = e.submitter;
      document.disableButton(buttonSelector);
      try {
        await document.connectKeplrWallet();
        if (SECRET_NETWORK.walletAddress) {
          let handleMsg = {
            change_receivable_address: {},
          };
          let params = {
            sender: SECRET_NETWORK.walletAddress,
            contract_address: SN_BUTT_LODE_ADDRESS,
            code_hash: SN_BUTT_LODE_DATA_HASH,
            msg: handleMsg,
          };
          await SECRET_NETWORK.executeContract(params, 100_000);
          document.showAlertSuccess("Receivable address changed.");
          await this.getAndSetConfig();
        }
      } catch (err) {
        document.showAlertDanger(err);
      } finally {
        document.enableButton(buttonSelector);
      }
    };

    document.buttLodeSendForm.onsubmit = async (e) => {
      e.preventDefault();
      document.disableButton(
        'form[name="buttLodeSendForm"] button[type="submit"]'
      );
      try {
        await document.connectKeplrWallet();
        if (SECRET_NETWORK.walletAddress) {
          let amount = document.buttLodeSendForm.amount.value;
          amount = amount.replace(/,/g, "");
          amount = (amount * Math.pow(10, 6)).toFixed(0);
          let handleMsg = {
            send_token: {
              amount: amount,
              token: {
                address: SN_BUTT_ADDRESS,
                contract_hash: SN_BUTT_DATA_HASH,
              },
            },
          };
          let params = {
            sender: SECRET_NETWORK.walletAddress,
            contract_address: SN_BUTT_LODE_ADDRESS,
            code_hash: SN_BUTT_LODE_DATA_HASH,
            msg: handleMsg,
          };
          await SECRET_NETWORK.executeContract(params, 100_000);
          document.showAlertSuccess("Send successful");
          document.buttLodeSendForm.amount.value = "";
        }
      } catch (err) {
        document.showAlertDanger(err);
      } finally {
        document.enableButton(
          'form[name="buttLodeSendForm"] button[type="submit"]'
        );
      }
    };

    document.buttLodeNominateReceivableAddressForm.onsubmit = async (e) => {
      e.preventDefault();
      let buttonSelector = e.submitter;
      document.disableButton(buttonSelector);
      try {
        await document.connectKeplrWallet();
        if (SECRET_NETWORK.walletAddress) {
          let handleMsg = {
            nominate_new_receivable_address: {
              address:
                document.buttLodeNominateReceivableAddressForm.receivableAddress.value.replace(
                  /\s/g,
                  ""
                ),
            },
          };
          let params = {
            sender: SECRET_NETWORK.walletAddress,
            contract_address: SN_BUTT_LODE_ADDRESS,
            code_hash: SN_BUTT_LODE_DATA_HASH,
            msg: handleMsg,
          };
          await SECRET_NETWORK.executeContract(params, 100_000);
          document.showAlertSuccess("Receivable address nominated.");
          document.buttLodeNominateReceivableAddressForm.receivableAddress.value =
            "";
          await this.getAndSetConfig();
        }
      } catch (err) {
        document.showAlertDanger(err);
      } finally {
        document.enableButton(buttonSelector);
      }
    };

    document.activateKeplr();
    this.getAndSetConfig();
  }
});
